import { BootstrapOptions, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { map, take, single } from 'rxjs/operators';
import {
  LegendItemClickEvent,
  CategoryAxis,
  Legend,
  Series,
  SeriesLabelsContentArgs,
  LineStyle,
} from '@progress/kendo-angular-charts';
import { DecimalPipe, formatDate } from '@angular/common';
import { SharedService } from '../../shared/shared.service';
import { BillingType, Roles, superadmin_graphColors, } from '../../shared/constants/enum';
import { Store, select } from '@ngrx/store';
import { getadmindashboardResponse, getadminFlatwiseConsmptionsdashboardResponse } from '../../store/dashboard/dashboard-selector';
import { getAdminDashboard, getAdminFlatwiseConsmptionsDashboard } from '../../store/dashboard/dashboard.actions';
import { colorsList, ConsumptioncolorsList, ConsumptionMinMax, monthsArray, utilitiesArray } from '../../shared/constants/templatesNames';
import { error, log } from 'console';
import { Router } from '@angular/router';
import { billSummary } from '../../shared/constants/enum';
import { meterCommunication_title } from '../../shared/constants/enum';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DashboardService } from '../../core/services/dashboard.service';
import {
  ChartComponent,
  ApexChart,
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexStroke
} from "ng-apexcharts";
import { LoaderService } from '../../core/services/loader.service';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: any;
  toolbar: any;
};
interface XAxis {
  min: number; // Timestamp in milliseconds
  max: number; // Timestamp in milliseconds
}

interface UpdateOptionsData {
  [key: string]: {
    xaxis: XAxis;
  };
}
type UtilityType = 'Water' | 'Gas' | 'Electricity';
@Component({
  selector: 'app-prepaid-admin-dashboard',
  templateUrl: './prepaid-admin-dashboard.component.html',
  styleUrl: './prepaid-admin-dashboard.component.scss',
  providers: [DecimalPipe],
})
export class PrepaidAdminDashboardComponent {
  public seriesVisible = true;
  isLoading: boolean = true;
  isdailyConsumptionChart: boolean = true
  meterCommunication_title = meterCommunication_title
  monthlyConsumptionList: any[] = []
  monthlyConsumptionchartData: any[] = [];
  colors_status = superadmin_graphColors;
  dateRange: boolean = false;
  public style: LineStyle = 'normal';
  public line: LineStyle = 'smooth';
  totalBill = 0;
  datePickerConfig: Partial<BsDatepickerConfig>;
  totalRevenue: number = 0;
  totalPrice = 0;
  @ViewChild("chart", { static: false }) chart: ChartComponent | undefined = undefined;

  public chartOptions!: Partial<ChartOptions>; // 
  currentUser: any;
  currentUserData: any;
  private flatwiseadminDashboardData$: Subscription = new Subscription();
  selectedRange: any;
  private adminFlatwiseConsumptionDashboardData$: Subscription =
    new Subscription();

  totalConsumers: number = 0;
  totalMeterStatus: number = 0;
  consumerData: any[] = [];
  residentialUnitsData: any = [];
  // billSummary: any[] = [];
  billSummary = billSummary

  projectId: any;
  projectsName: any;
  utlityType: string = '';
  consuptuinUtility = '';
  selectedUtilityConsumption: any;
  meterUtilityType: any;
  meterStatusUtility: any;
  consuptionSelectedUtilityType: any;
  topconsuptionSelectedUtilityType: any
  consuptionSelectedUtility: any;
  topconsuptionSelectedUtility: any

  consumptionminmaxvalue: any
  selectedUtilityType: any
  totalResidentialUnits: number = 0;
  currentWalletBalance: number = 0;
  totalWalletRecharge: number = 0;
  deductedWalletBalance: number = 0;
  sumOfTotalConsumption: number = 0;
  consumptionData: any[] = [];
  waterConsumptionData: any[] = [];
  gasConsumptionData: any[] = [];
  electricityConsumptionData: any[] = [];
  months = monthsArray

  communicationStatusGraphData: any[] = [];
  communicationStatusGraphData_temp: any = [];
  communicationMeterStatusGraphData: any[] = [];
  utilities = utilitiesArray;
  categories: any[] = [];
  consuptionCategories: any[] = [];
  consumptionUtlity: any[] = [];
  public selectedDateRange: { startDate: string; endDate: string } | null =
    null;
  watercategories: any[] = [];
  gascategories: any[] = [];
  electricitycategories: any[] = [];
  Water: any[] = [];
  Gas: any = [];
  Electricity: any = [];
  meterCommunicationStatus: boolean = false;
  meterCommunicationList: any[] = []
  consuptiongraphdata: any = [];
  colors = colorsList
  topConsumptionElectricity: any = [];
  topConsumptionList: any[] = [];
  topconsumptionData: any[] = [];
  topConsumptionWatercategories: any = [];
  topConsumptionGascategories: any = [];
  topConsumptionElectricitycategories: any = [];
  colorslist = ConsumptioncolorsList
  topConsumptioncategories: any = [];
  isWalletEnabled: boolean = false;
  projectName: any;
  billingType: any
  projectUtlityList: any[] = [];
  projectStartDate: any
  maxminlist = ConsumptionMinMax
  consumptionLoader: boolean = true;
  billSummaryLoader: boolean = true;
  topconsumptionLoader: boolean = true;
  monthlyConsumptionLoader: boolean = false;
  billingInitiatedOn: any

  constructor(
    private router: Router,
    private store: Store,
    private decimalPipe: DecimalPipe,
    private sharedService: SharedService,
    private dashboardService: DashboardService,
    private loaderService: LoaderService,
  ) {

    const currentUserString = localStorage.getItem('currentUser');

    this.currentUserData = JSON.parse(currentUserString ?? '');
    this.projectUtlityList = this.currentUserData?.projectUtilities;
    this.isWalletEnabled = this.currentUserData?.project?.isWalletEnabled;
    this.projectStartDate = this.currentUserData?.project?.startDate
    this.billingInitiatedOn = this.currentUserData?.project?.billingInitiatedOn
    //console.log(this.isWalletEnabled)
    const today = new Date();
    //const lastMonth = new Date();

    const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const minDate = new Date(firstDayOfCurrentMonth);
    minDate.setMonth(today.getMonth() - 1);
    //lastMonth.setMonth(today.getMonth() - 1);
    console.log(this.projectUtlityList)
    this.currentUserData = JSON.parse(currentUserString ?? '');
    if (this.currentUserData.project !== null) {
      this.projectId = this.currentUserData.project.id;
      this.projectName = this.currentUserData.project.name;
      this.billingType = this.currentUserData.project.billingType
    }


    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
        minDate: minDate, // Set minimum date to 6 months ago
        maxDate: today
      }
    );
    this.getStoreInfo();


  }


  getStoreInfo() {
    console.log("store")
    this.selectionChange('Current Month');
    this.flatwiseadminDashboardData$ = this.store.pipe(select(getadmindashboardResponse)).subscribe({
      next: (data) => {
        if (data) {
          this.currentWalletBalance =
            data.data.walletData.currentWallteBalance;
          this.totalWalletRecharge = data.data.walletData.totalWalletRecharge;
          this.deductedWalletBalance =
            data.data.walletData.deductedWalletBalance;

          this.meterCommunicationStatus = data.data.meterConfigCounts == null ? false : true;
          this.meterCommunicationList = data.data.meterConfigCounts !== null ? data.data.meterConfigCounts : []
          setTimeout(() => {
            if (Object.keys(data.data.residentialUnitData).length > 0) {
              this.getResidentialUnitData(data.data.residentialUnitData);
            }

            this.metreSttausData(this.meterCommunicationList, this.projectUtlityList[0].name);
            this.communicationStatusData(this.meterCommunicationList, this.projectUtlityList[0].name);
            if (Object.keys(data.data.ownerTenentCount).length > 0) {
              this.listOfConsumers(data.data.ownerTenentCount);
            } else {
              this.consumerData = []
            }

          }, 500);
        }
      }, error(err) {
        console.log(err);
      },
    })



  }

  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }
  selectDates(event: any) {
    //console.log(event);
    if (event == undefined) {
      this.billdate = [];

    }
  }
  billdate: any = []
  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }


  InitialluAssignedvalues() {
    this.consuptuinUtility = 'Water';
    this.selectedRange = 'Current Month';
    this.selectedUtilityConsumption = this.projectUtlityList[0].id;
    this.consuptionSelectedUtilityType = this.projectUtlityList[0]
    this.topconsuptionSelectedUtilityType = this.projectUtlityList[0]
    this.consuptionSelectedUtility = this.projectUtlityList[0].id;
    this.topconsuptionSelectedUtility = this.projectUtlityList[0].id;
    this.meterUtilityType = this.projectUtlityList[0].id;
    this.meterStatusUtility = this.projectUtlityList[0].id;
    this.consumptionminmaxvalue = this.maxminlist[0].id;
    this.selectedUtilityType = this.projectUtlityList[0]
  }



  ngOnInit(): void {
    this.InitialluAssignedvalues()
    this.getDashboardData();
  }

  getDashboardData() {
    this.store.dispatch(getAdminDashboard({ ProjectId: this.projectId }));

  }
  ngOnDestroy() {
    this.flatwiseadminDashboardData$.unsubscribe()
    this.adminFlatwiseConsumptionDashboardData$.unsubscribe(); // Or set it to [] if you prefer an empty array
  }
  refreshDashboard() {
    this.InitialluAssignedvalues()
    this.getDashboardData();
    this.selectionChange('Current Month');
  }

  getflatwiseConsuptiondata() {
    console.log("2nd apis")
    this.monthlyConsumptionLoader = true
    this.topconsumptionLoader = true;
    this.consumptionLoader = true;
    this.billSummaryLoader = true
    // this.loaderService.setLoading(true)
    let request = {
      projectId: this.projectId,
      startDate: this.selectedDateRange?.startDate,
      endDate: this.selectedDateRange?.endDate,
    };
    this.dashboardService.getAdminFaltwiseConsumptionDashboardData(request).subscribe({
      next: (response) => {
        if (response && response.statusCode == 200) {
          this.topConsumptionList = response.data.consumptionByFlat;

          console.log(this.topConsumptionList)

          //  this.topConsumptionList = response.data.rankedData;
          if (Object.keys(response.data.billSummaryData).length > 0) {
            // this.billSummaryData(response.data.billSummaryData);
            this.billSummary = this.billSummary.map((item: any) => {
              if (item.label === 'Paid Bills') {
                return {
                  ...item,
                  value: response.data.billSummaryData.paidBillsCount,
                  amount: response.data.billSummaryData.paidBillsAmount // Optionally, if you want to update the amount
                };
              } else if (item.label === 'Unpaid Bills') {
                return {
                  ...item,
                  value: response.data.billSummaryData.unpaidBillsCount,
                  amount: response.data.billSummaryData.unpaidBillsAmount // Optionally, if you want to update the amount
                };
              } else if (item.label === 'Total Bills') {
                return {
                  ...item,
                  value: response.data.billSummaryData.totalBillCount,
                  amount: response.data.billSummaryData.totlBillAmount // Optionally, if you want to update the amount
                };
              }
              return item; // In case of no matching label, return the item unchanged
            });
            if (this.billSummary.length > 0) {
              this.billSummaryLoader = false
            }
            console.log(this.billSummary)

          } else {
            this.loaderService.setLoading(false);
            this.billSummary = []
            this.billSummaryLoader = false
          }

          if (this.topConsumptionList.length > 0) {
            this.flatwiseConsumptionData(this.projectUtlityList[0].name)
            //  this.consuprtionUtilitiesGrahChange(this.projectUtlityList[0].name); // top 5 Consumptions
            this.topconsuptionSelectedUtility = this.projectUtlityList[0].id;
          } else {

            this.loaderService.setLoading(false);
            this.topconsumptionData = [];
            this.topconsumptionLoader = false;


          }
        }
        else {
          this.loaderService.setLoading(false);
          this.topconsumptionData = [];
        }
      }, error: (err) => {
        console.log(err)
        this.topconsumptionLoader = false;
      },
    })
    // this.loaderService.setLoading(true)
    this.dashboardService.getAdminConsumptionData(request).subscribe({
      next: (response) => {
        this.loaderService.setLoading(false)
        if (response && response.statusCode == 200) {
          this.consuptiongraphdata = [];
          // console.log(this.consumptionLoader)
          console.log("res=======>", response.data)
          this.consuptiongraphdata = response.data;

          this.consuptionSelectedUtility = this.projectUtlityList[0].id;
          if (this.consuptiongraphdata && this.consuptiongraphdata.length > 0) {
            this.sumOfTotalConsumption = this.consuptiongraphdata[0]?.sumOfTotalConsumption
            if (this.selectedRange == 'Last Three Months' || this.selectedRange == 'Last Six Months') {
              this.isdailyConsumptionChart = false
              this.monthlyConsumptionList = this.convertToMonthlyConsumption(this.consuptiongraphdata);
              // console.log(this.monthlyConsumptionList)
              if (this.monthlyConsumptionList.length > 0) {
                this.monthlyConsuptionChart(this.projectUtlityList[0].name);
                this.consumptionLoader = false;
              } else {
                this.monthlyConsumptionLoader = false
              }

            } else {
              console.log(this.selectedRange)
              this.isdailyConsumptionChart = true
              this.initChart();
              this.monthlyConsumptionLoader = false;
              this.loadConsumptionChart(this.projectUtlityList[0].name)
            }

          } else {
            this.isdailyConsumptionChart = true
            console.log(this.consumptionLoader)
            this.consuptiongraphdata = [];
            this.consumptionData = []
            // this.initChart()

            this.consumptionLoader = false;
            //s console.log(this.consumptionLoader)
          }
        }
      }, error: (err) => {
        console.log(err)
        this.consumptionLoader = false;

      },
    })

  }

  monthlyConsuptionChart(value: any) {
    this.monthlyConsumptionLoader = true
    this.monthlyConsumptionchartData = []
    //console.log(this.consuptionSelectedUtilityType?.name)
    const utilityType = this.monthlyConsumptionList.find((utility: any) => utility.utilityMasterName === value);
    this.monthlyConsumptionList.forEach((item: any) => {
      console.log(utilityType)
      if (utilityType) {

        // Iterate over the monthlyConsumptions array of the itemutility
        item.monthlyConsumptions.forEach((res: any, index: number) => {

          this.monthlyConsumptionchartData.push({
            category: res.monthName,
            value: res.totalConsumptionForUtility.toFixed(2),
            colorString: this.colors[index]
          });
        });
      } else {
        this.monthlyConsumptionchartData = [];
        this.monthlyConsumptionLoader = false
      }
    });
    if (this.monthlyConsumptionchartData.length > 0) {
      this.monthlyConsumptionLoader = false

    }

    console.log("Final data", this.monthlyConsumptionchartData)
  }

  convertToMonthlyConsumption(data: UtilityData[]): any[] {
    return data.map(item => {
      // Get the utility name
      const utilityName = item.utilityMasterName;

      // Get the daily consumption data
      const dailyData = item.totalConsumptionByDay;

      // Group the data by month and calculate total consumption for each month
      const monthlyData = Object.keys(dailyData).reduce((acc: { [key: string]: MonthlyConsumption }, date: string) => {
        const [year, month] = date.split('-');
        const monthKey = `${year}-${month}`; // Grouping by year and month

        // Initialize the accumulator if it doesn't exist for the month
        if (!acc[monthKey]) {
          acc[monthKey] = {
            year: parseInt(year),
            month: parseInt(month),
            monthName: new Date(`${year}-${month}`).toLocaleString('default', { month: 'long' }),
            totalConsumptionForUtility: 0
          };
        }

        // Add the daily consumption to the total for the respective month
        acc[monthKey].totalConsumptionForUtility += dailyData[date];

        return acc;
      }, {});

      // Convert the grouped data into an array of monthly consumption objects
      const monthlyConsumptions = Object.values(monthlyData);

      // Return the transformed data
      return {
        utilityMasterName: utilityName,
        monthlyConsumptions
      };
    });
  }


  goToWallet() {
    // this.router.navigate(['/wallet'], { state: { consumerData: 'data' }, });
    this.router.navigate(['/wallet']);
  }


  gotoWalletHistoryreport() {
    let request = {
      type: 'Total Wallet History',
      projectStartDate: this.projectStartDate
    }

    this.router.navigate(['/reports'], {
      state: { consumptionItem: request },
    });
  }
  goToWalletReport() {
    let request = {
      type: 'Total Wallet Recharge',
      projectStartDate: this.projectStartDate
    }

    this.router.navigate(['/reports'], {
      state: { consumptionItem: request },
    });

    // this.router.navigate(['/maintenance'], {
    //   state: { consumptionItem: request },
    // });

  }


  /* // Original date range
const dateRange = [new Date('2025-02-04T09:24:59.000Z'), new Date('2025-02-04T09:24:59.000Z')];

// Set start date to 00:00:00.000Z
const startDate = new Date(dateRange[0]);
startDate.setUTCHours(0, 0, 0, 0);  // Setting the time to 00:00:00.000Z

// Set end date to 23:59:59.999Z
const endDate = new Date(dateRange[1]);
endDate.setUTCHours(23, 59, 59, 999);  // Setting the time to 23:59:59.999Z

// Output the modified start and end dates
console.log('Start Date:', startDate.toISOString());
console.log('End Date:', endDate.toISOString());
 */
  clearDate() {
    this.filterProjectBillDates = null; // Clear the selected date
    this.selectionChange('Current Month');
    this.selectedRange = 'Current Month'
  }
  onDateRangeSelected(dateRange: (Date | undefined)[] | undefined): void {
    this.flatwiseadminDashboardData$.unsubscribe();
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      console.log(dateRange)
      // const startDate = dateRange[0];
      // const endDate = dateRange[1];


      // Set start date to 00:00:00.000Z
      const startDate = new Date(dateRange[0]);
      startDate.setHours(0, 0, 0, 0);  // Setting the time to 00:00:00.000Z

      // Set end date to 23:59:59.999Z
      const endDate = new Date(dateRange[1]);
      // endDate.setUTCHours(23, 59, 59, 999);
      endDate.setHours(23, 59, 59, 999);  // Setting the time to 23:59:59.999Z
      this.selectedDateRange = {
        startDate: formatDate(
          startDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          'en-US'
        ),
        endDate: formatDate(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US'),
      };
      console.log(this.selectedDateRange)
      this.topconsumptionData = [];
      this.topConsumptionElectricity = [];

      this.getflatwiseConsuptiondata();
    }
  }

  public updateOptionsData = {
    "Current Month": {
      xaxis: {
        /*  min: new Date("28 Jan 2013").getTime(),
         max: new Date("27 Feb 2013").getTime(), */
        min: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
        max: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime(),
      }
    },
    "Last Month": {
      xaxis: {
        min: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).getTime(),
        max: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime()
      }
    },
    "Last Three Months": {
      xaxis: {
        min: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1).getTime(),
        max: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime(),
      }
    },
    "Last Six Months": {
      xaxis: {
        min: new Date(new Date().getFullYear(), new Date().getMonth() - 5, 1).getTime(),
        max: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime(),
      }
    },
  };


  selectionChange(event: any) {
    // this.loaderService.setLoading(true);
    if (event != undefined) {
      this.topConsumptionWatercategories = [];
      this.topConsumptionElectricitycategories = [];
      this.topConsumptionGascategories = [];
      // this.consuptionSelectedUtility
      this.consuptionSelectedUtilityType = this.projectUtlityList[0];
      this.topconsuptionSelectedUtilityType = this.projectUtlityList[0]
      const selectedOption = event;
      this.dateRange = false;
      const now = new Date();
      let startDate: Date;
      let endDate: Date = now;

      switch (selectedOption) {
        case 'Current Month':
          startDate = new Date(now.getFullYear(), now.getMonth(), 1);
          endDate = now; // Current date of the current month
          endDate.setHours(23, 59, 59, 999);
          break;
        case 'Last Month':
          startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1); // First day of last month
          endDate = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of last month
          endDate.setHours(23, 59, 59, 999); //
          break;
        case 'Last Three Months':
          startDate = new Date(now.getFullYear(), now.getMonth() - 3, 1);
          endDate = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of the previous month
          endDate.setHours(23, 59, 59, 999); //
          break;
        case 'Last Six Months':
          startDate = new Date(now.getFullYear(), now.getMonth() - 6, 1);
          endDate = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of the previous month
          endDate.setHours(23, 59, 59, 999); //
          break;

        case 'Custom Range':
          this.dateRange = true;
          this.loaderService.setLoading(false);

          // Implement custom range logic here
          return;
        default:
          startDate = now;
          endDate = now;
      }

      // Format dates to UTC
      this.selectedDateRange = {
        startDate: formatDate(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US',),
        endDate: formatDate(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US',),
      };

      this.getflatwiseConsuptiondata();
      //  console.log(this.selectedDateRange);
    } else {
      this.loaderService.setLoading(false);
      this.billSummary = [];
      this.selectedDateRange = null;
      this.dateRange = false;
      this.topconsumptionData = [];
      this.consumptionData = []
    }
  }
  filterProjectBillDates: any;


  flatwiseConsumptionData(value: any) {
    this.consuptuinUtility = value;
    // console.log('Selected Utility:', this.consuptuinUtility);
    // console.log('Top Consumption List:', this.topConsumptionList);
    this.topconsumptionData = [];
    const utilityType = this.topConsumptionList.find((utility: any) => utility.utilityType === this.consuptuinUtility);
    // console.log(utilityType)

    if (utilityType) {

      if (this.consumptionminmaxvalue === 1) {
        this.topconsumptionData = [];
        // Top 5 Highest
        utilityType.top5Highest?.forEach((consumption: any, index: number) => {
          this.topconsumptionData.push({
            category: `${consumption.flatNo} (tower: ${consumption.tower})`,
            value: consumption.totalConsumption,
            colorString: this.colorslist[index]
          });
        });
        this.topconsumptionLoader = false;
        //  console.log('Top Consumption Data (Highest):', item.utilityType, this.topconsumptionData);
      } else {
        // Top 5 Lowest
        this.topconsumptionData = [];
        utilityType.top5Lowest?.forEach((consumption: any, index: number) => {
          this.topconsumptionData.push({
            category: `${consumption.flatNo} (tower: ${consumption.tower})`,
            value: consumption.totalConsumption,
            colorString: this.colorslist[index]
          });
        });
        this.topconsumptionLoader = false;
        console.log('Top Consumption Data (Lowest):', this.topconsumptionData);
      }



      //});
    } else {
      this.topconsumptionLoader = false;
      console.log('No data found for the selected utility');
    }
  }

  getColor(index: number): string {
    // console.log(index);
    const colors = ['#E96360', '#DB6FA0', '#AD8AC0', '#6480BC', '#44C8F5'];
    let color = colors[index % colors.length];
    return color; // Cycle through colors
  }
  getSeriesColors(): string[] {
    return this.topconsumptionData[0]?.data.map((_: any, index: number) =>
      this.getColor(index)
    );
  }

  public labelContent(e: SeriesLabelsContentArgs): string {
    return e.value;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // You can perform any action here if needed when the window resizes
    if (this.chart) {
      //console.log("resizes")
      this.chart?.updateOptions({

        chart: {
          width: '100%', // Ensure the chart width updates on window resize
        },
      });
    }
  }

  yaxisUnits: string = 'Units';

  initChart(): void {
    const startDate = this.selectedDateRange?.startDate ? new Date(this.selectedDateRange.startDate) : null;
    const dateDiff = startDate && this.selectedDateRange?.endDate ?
      new Date(this.selectedDateRange.endDate).getTime() - startDate.getTime() : 0;
    console.log(startDate)
    console.log(this.selectedDateRange?.endDate)
    this.yaxisUnits = this.consuptionSelectedUtilityType.name == "Gas" ? 'Units (m³)' : this.consuptionSelectedUtilityType.name == "Electricity" ? 'Units (kWh)' : 'Units (Liters)';


    // Choose the date format based on the date difference (2 months in milliseconds)
    const dateFormat = dateDiff > 2 * 24 * 60 * 60 * 1000 ? 'dd MMM' : 'dd MMM HH:mm';
    console.log(dateFormat)
    this.chartOptions = {
      series: [
        {
          name: "", // Optional: Set a name for the series if needed
          data: this.convertedData, // Ensure this is valid data
        },
      ],
      chart: {
        type: "area",
        height: 250,
        // redrawOnParentResize: true,
        toolbar: {
          show: false, // Hide the toolbar/menu options
        },
        zoom: {
          enabled: false, // Disable zoom
        },
        events: {
          mounted: (chart: any) => {
            chart.windowResizeHandler();
          },
          dataPointMouseEnter: (event, chartContext, config) => {
            chartContext.chart.showTooltip(config.dataPointIndex);

          },
          click: (event, chartContext, config) => {
            const dataPointIndex = config.dataPointIndex; // Get the data point index from config
            if (dataPointIndex !== undefined) {
              const selectedSeries = config.seriesIndex;
              // const value = this.chartOptions.series[selectedSeries].data[dataPointIndex];
              const dataPointData = this.consuptiongraphdata[dataPointIndex]; // Get the actual data for the clicked point
              this.handleDataPointClick(selectedSeries, dataPointIndex);
            }
          },
        },
      },

      dataLabels: {
        enabled: false, // Disable data labels
      },
      markers: {
        size: 0, // Hide markers
      },
      xaxis: {
        type: "datetime",
        /*  min: startDate ? startDate.getTime() : undefined, // Set to undefined if startDate is not valid
         tickAmount: 3, */
        min: startDate ? startDate.getTime() : undefined, // Set the min to startDate
        max: this.selectedDateRange?.endDate ? new Date(this.selectedDateRange.endDate).getTime() : undefined, // Set the max to endDate
        tickAmount: 3,
        title: {
          text: "Dates", // Label for the x-axis
        },
        labels: {
          format: dateFormat, // Let ApexCharts handle formatting
        }
      },
      yaxis: {
        title: {
          text: `${this.yaxisUnits}`, // Label for the y-axis
        },
        /*  labels: {
           formatter: function (value) {
             return Math.round(value).toString(); // Remove decimals from y-axis labels
           },
         }, */
        // min: 0, // Set a minimum value for the Y-Axis (you can adjust based on your data)
        //  tickAmount: 5,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy", // Display date format in tooltip
        },
        y: {
          formatter: function (value) {
            return `${value}`; // Display units in the tooltip
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    };

  }

  convertedData: any[] = []

  loadConsumptionChart(utilityType: string) {
    this.consumptionData = [];
    const utilityIndex = { "Gas": 0, "Water": 1, "Electricity": 2 }[utilityType];
    const utilityData = this.consuptiongraphdata.find((utility: any) => utility.utilityMasterName === utilityType);
    console.log(utilityData)
    if (utilityData) {
      const consumptionData = utilityData.totalConsumptionByDay;
      this.sumOfTotalConsumption = utilityData.sumOfTotalConsumption
      /*  if (utilityIndex !== undefined) {
         console.log(this.consuptiongraphdata[utilityIndex]?.totalConsumptionByDay)
         const consumptionData = this.consuptiongraphdata[utilityIndex]?.totalConsumptionByDay;
    */
      for (const date in consumptionData) {
        // this.sumOfTotalConsumption = this.consuptiongraphdata[0].sumOfTotalConsumption
        if (consumptionData.hasOwnProperty(date)) {
          const value = consumptionData[date];
          this.consumptionData.push({ category: date, value: value });
          // console.log(`category: "${date}", value: ${value}`);
        }
      }

      const convertedData = this.consumptionData.map(item => [
        new Date(item.category).getTime(),
        item.value
      ]);

      console.log('Converted Data:', this.chartOptions.series);
      //   console.log(this.chartOptions)
      if (this.chartOptions.series && this.chartOptions.series[0].data) {
        this.chartOptions.series[0].data = [];
        //this.chartOptions.series[0].data = this.chartdata; // Update chart data
        console.log("santosh test", convertedData)

        this.chartOptions.series[0].data = convertedData;
        this.convertedData = convertedData;
        console.log("santosh test", this.convertedData.length)
        /*  this.convertedData = this.convertedData.filter(
           (value, index, self) =>
             index === self.findIndex((v) => v[0] === value[0] && v[1] === value[1])
         ); */
        this.consumptionLoader = false;
        this.initChart();
      }

      // this.updateOptions(this.selectedRange)
      this.chart?.updateOptions(this.chartOptions, false, true, true); // Refresh chart
    } else {
      console.log(`Utility type "${utilityType}" is not recognized.`);
    }

    setTimeout(() => {
      this.consumptionLoader = false;
    }, 200)
  }

  public updateOptions(option: keyof UpdateOptionsData): void {
    const optionData = this.updateOptionsData[option as keyof typeof this.updateOptionsData];
    if (optionData) {
      this.chart?.updateOptions(optionData, false, true, true);
    } else {
      console.log(`Option for '${option}' is undefined.`);
      // Handle undefined option case
    }
  }


  billSummaryData(data: any) {
    //console.log(data);
    this.totalPrice;
    this.billSummary = [
      {
        label: 'Paid Bills',
        value: data.paidBillsCount,
        labelAmount: 'Paid Bills Amount',
        amount: data.paidBillsAmount,
        img: '../../../assets/images/Bill Paid ICON 03.svg',
        background: '#DAFFF2'
      },
      {
        label: 'Unpaid Bills',
        value: data.unpaidBillsCount,
        labelAmount: 'Unpaid Bills Amount',
        amount: data.unpaidBillsAmount,
        img: '../../../assets/images/Bill UnPaid ICON 02.svg',
        background: '#FFE5E5'
      },
      {
        label: 'Total Bills',
        value: data.totalBillCount,
        labelAmount: 'Total Bills Amount',
        amount: data.totlBillAmount,
        img: '../../../assets/images/Total Bill Icon 02.svg',
        background: '#FFEFCF'
      }
    ]
    if (this.billSummary.length > 0) {
      this.billSummaryLoader = false
    }

  }
  /*   calculateTotals() {
      this.totalBill = this.billSummary.reduce(
        (sum: any, project: { bill: any }) => sum + project.bill,
        0
      );
      this.totalRevenue = this.billSummary.reduce(
        (sum: any, project: { revenue: any }) => sum + project.revenue,
        0
      );
    } */

  metreSttausData(data: any, type: any) {
    let filterdate = data?.deviceCounts.filter((item: any) => {
      return item.utilityName.toUpperCase() === type.toUpperCase()
    })

    if (filterdate.length > 0) {
      this.totalMeterStatus = filterdate[0].totalCount;
      this.communicationMeterStatusGraphData = [
        { name: 'Active', value: filterdate[0].activeCount, color: '#6ED972' },
        { name: 'Inactive', value: filterdate[0].inActiveCount, color: '#F9AF58' },
      ];
    } else {
      this.communicationMeterStatusGraphData = [];
    }

    /* data?.deviceCounts.forEach((item: any) => {
      if (item.utilityName.toUpperCase() === type.toUpperCase()) {
        console.log(item);
        
      }
    }) */

  }
  communicationStatusData(data: any, type: any) {
    //console.log(data);
    // console.log(type)
    let commCount = 0;
    let actualNonCommCount = 0;
    let nonCommInactiveCount = 0;
    let nonCommMCBOffCount = 0;

    let filterdate = data?.deviceCounts.filter((item: any) => {
      // console.log(item)
      return item.utilityName.toUpperCase() === type.toUpperCase()
    })

    if (filterdate.length > 0) {
      this.communicationStatusGraphData = []
      commCount = filterdate[0].commCount,
        actualNonCommCount = filterdate[0].actualNonCommuCount,
        nonCommInactiveCount = filterdate[0].nonCommInActiveCount,
        nonCommMCBOffCount = filterdate[0].nonCommMCBOffCount

      let tempdata = [
        {
          name: "Communicating",
          data: [commCount],
          color: this.colors_status[1],
          stack: "a",
          comment: 'Meters actively communicating as of today'
        },
        {
          name: "Non Communicating",
          data: [actualNonCommCount],
          color: this.colors_status[2],
          stack: "b",
          comment: 'Meters not communicating as of today'
        },
        {
          name: "Inactive",
          data: [nonCommInactiveCount],
          color: this.colors_status[0],
          stack: "b",
          comment: 'Meters that have never been activated since installation'
        },
        {
          name: "MCB Off",
          data: [nonCommMCBOffCount],
          color: this.colors_status[3],
          stack: "b",
          comment: 'The MCB is turned off manually'
        }
      ];

      let tempData = [
        {
          "category": "Communicating",
          "value": commCount,
          "colorString": this.colors_status[1],
          comment: 'Meters actively communicating as of today'
        },
        {
          "category": "Non Communicating",
          "value": actualNonCommCount,
          "colorString": this.colors_status[2],
          comment: 'Meters not communicating as of today'
        },
        {
          "category": "Inactive",
          "value": nonCommInactiveCount,
          "colorString": this.colors_status[0],
          comment: 'Meters that have never been activated since installation'
        },
        {
          "category": "MCB Off",
          "value": nonCommMCBOffCount,
          "colorString": this.colors_status[3],
          comment: 'The MCB is turned off manually'
        }
      ]



      tempData.map((item: any) => {
        // Check if type is 'Electricity'
        if (type === 'Electricity' || type === 'TriSource') {
          this.communicationStatusGraphData.push(item);
        } else if (type !== 'Water' || type !== 'Gas') {
          // For any other type except 'Water' and 'Gas', push all items except 'MCB Off'
          if (item.category !== "MCB Off") {
            this.communicationStatusGraphData.push(item);
          }
        }
      })

      if (this.communicationStatusGraphData.length > 0) {
        this.meterCommunicationStatus = true
      }

      console.log(this.communicationStatusGraphData);
    } else {
      this.communicationStatusGraphData = []
      this.meterCommunicationStatus = false
    }



    /*  this.communicationStatusGraphData = {
       commCount: commCount,
       nonCommCount: nonCommCount,
       nonCommInActiveCount: nonCommInActiveCount,
       nonCommMCBOffCount: nonCommMCBOffCount,
     }; */
    console.log("Non Communicating", this.communicationStatusGraphData);

    this.communicationStatusGraphData_temp = [
      {
        color: '#6ED972 ',
        name: `Non Communicating `,
      },
      {
        color: '#F9AF58',
        name: `Communicating`,
      },
    ];


  }

  get chartHeight(): number {
    return this.communicationStatusGraphData.length === 3 ? 243 : 224;
  }
  MeterStatusUtilityChange(event: any) {
    console.log(event)
    if (event === undefined) {
      this.meterStatusUtility = '';
      this.totalMeterStatus = 0
      this.communicationMeterStatusGraphData = []
    } else {
      this.meterStatusUtility = event.id;
      this.metreSttausData(this.meterCommunicationList, event.name)
    }




  }
  selectedmeterCommunicationutility: any
  utilityMeterChange(event: any) {
    this.meterUtilityType = event.id;
    this.selectedmeterCommunicationutility = event.name
    console.log(event)
    if (event !== undefined) {
      this.communicationStatusData(this.meterCommunicationList, event.name)
    } else {
      this.communicationStatusGraphData = []
      this.meterCommunicationStatus = false
    }


  }
  getResidentialUnitData(data: any) {
    console.log(data)
    this.totalResidentialUnits = data.totalUnits;
    this.residentialUnitsData = [
      { name: 'Occupied units', value: data.occupiedUnits, color: '#6ED972' },
      {
        name: 'Unoccupied units',
        value: data.unoccupiedUnits,
        color: '#F9AF58',
      },
    ];
    console.log(this.residentialUnitsData)

  }

  listOfConsumers(data: any) {
    console.log(data);
    this.totalConsumers = data.ownerCount + data.tenantCount;
    this.consumerData = [
      { name: 'Owner', value: data.ownerCount, color: '#6ED972' },
      { name: 'Tenant', value: data.tenantCount, color: '#F9AF58' },
    ];
  }


  getClassBasedOnValue(): boolean {
    if (this.currentUserData?.currentUserRoleDetails?.role === Roles.ADMIN) {
      if (typeof this.currentUserData.project.billingType === 'object') {
        return this.currentUserData.project.billingType.name === BillingType.PREPAID;
      } else if (typeof this.currentUserData.project.billingType === 'string') {
        return this.currentUserData.project.billingType === BillingType.PREPAID;
      }
      // console.log(this.currentUserData.project.billingType === BillingType.PREPAID)
      //return this.currentUserData.project.billingType === BillingType.PREPAID;
    }

    return false; // Return false if the role is not ADMIN or no match
  }



  getFormattedNumber(value: number): any {
    return this.decimalPipe.transform(value, '1.0-0');
  }




  onResidentialUnitsClick(event: any) {

    this.router.navigate(['/residentialUnit'],)
    const dataItem = event.dataItem; // Get the clicked data item
    const category = dataItem.name;
    const value = dataItem.value;
    console.log(dataItem)
    console.log(`Clicked on: ${category} with value: ${value}`);
    let residentialUnit = dataItem.name
    let request = {
      residentialUnitStatus: residentialUnit,
      type: 'dashboard'
    }
    localStorage.removeItem('filter');
    this.router.navigate(['/residentialUnit'], {
      state: { residentialUnitRequest: request },
    });


  }
  onConsumersClick(event: any) {

    const dataItem = event.dataItem; // Get the clicked data item
    const category = dataItem.name;
    const value = dataItem.value;
    console.log(dataItem)
    //console.log(`Clicked on: ${category} with value: ${value}`);
    let residentialUnitStatus = dataItem.name;
    let request = {
      residentialUnitStatus: residentialUnitStatus,
      type: 'dashboard'
    }

    localStorage.removeItem('filter');
    this.router.navigate(['/residentialUnit'], {
      state: { residentialUnitRequest: request },
    });


  }

  top5ConsumptionClick(event: any): void {
    const clickedCategory = event.point.category;
    const clickedDataItem = this.topconsumptionData.find(item => item.category === clickedCategory);

    console.log('clickedCategory:', clickedCategory);
    console.log('clickedDataItem:', clickedDataItem); // Check if category exists

    // Check if the event contains a data point
    if (event && clickedDataItem) {
      // console.log("clickedData", clickedDataItem);

      // Check the category format
      // console.log('Category:', clickedDataItem.category); // See the full category

      // Regular expression to capture the flat number and tower
      const regex = /^([A-Za-z]-\d+)\s*\(tower:\s*(.*?)\)$/;

      // Perform the match operation on category
      const match = clickedDataItem.category.match(regex);
      let FlatNo;

      if (match) {
        // match[1] is the flat number with the letter prefix (e.g., "C-2003")
        // match[2] is the tower part (e.g., "C")
        FlatNo = `${match[1]} ${match[2].trim()}`; // Combine the flat number and tower
        //console.log("Extracted FlatNo:", FlatNo);
      } else {
        console.log("No match found");
      }

      console.log('Clicked Data Point FlatNo:', this.topconsuptionSelectedUtility?.name);
      // Prepare the request object
      let request = {
        value: clickedDataItem.value,
        UtilityType: this.topconsuptionSelectedUtilityType?.name,
        flatNo: FlatNo,
        date: this.selectedDateRange,
        flag: false,
        type: 'top5Consumption'
      };

      // Navigate to the '/reports' page with the request object as state
      this.router.navigate(['/reports'], {
        state: { consumptionItem: request },
      });

      // this.router.navigate(['/maintenance'], {
      //   state: { consumptionItem: request },
      // });
    } else {
      console.warn('No data point clicked.');
    }
  }



  onMeterStatusClick(event: any) {
    this.loaderService.setLoading(true);
    //  console.log(event)
    const seriesName = event.series.name
    // console.log(event.series)
    let selectedutlity: any
    // console.log(this.meterStatusUtility)
    // console.log(this.projectUtlityList)
    this.projectUtlityList.forEach((item: any) => {
      if (item.id == this.meterStatusUtility) {
        selectedutlity = item.name
      }
    })


    // console.log(selectedutlity)
    const dataItem = event.dataItem; // Get the clicked data item
    const category = dataItem.name;
    const value = dataItem.value;

    console.log(dataItem.name)
    // console.log(`Clicked on: ${category} with value: ${value}`);
    // let meterStatus = dataItem.name == 'Turn On' ? true : false;
    let meterStatus = dataItem.name == 'Active' ? 'Active' : 'Inactive';
    console.log(meterStatus)
    let request = {
      meterStatus: meterStatus,
      type: 'dashboard',
      utility: selectedutlity
    }
    this.router.navigate(['/meters'], {
      state: { meterRequest: request },
    });
  }

  onMeterCommunicationStatusClick(event: any) {
    // Get the index of the clicked series
    const seriesIndex = event.series.index; // or event.seriesIndex depending on your Kendo version
    console.log("event.series.index---------->", event.category)
    // Access the complete series data using the index
    //const clickedSeries = this.communicationStatusGraphData[seriesIndex];
    let request = {
      value: event.category,
      type: 'Meter',
      utility: this.meterUtilityType

    }
    if (event.category == 'MCB Off') {
      return
    } else {
      this.router.navigate(['/reports'], {
        state: { consumptionItem: request },
      });

      // this.router.navigate(['/maintenance'], {
      //   state: { consumptionItem: request },
      // });


    }

  }


  billStatus(value: any) {

    console.log(this.selectedRange)
    console.log(this.selectedDateRange)

    console.log(value)
    let request = {
      billStatus: value.label,
      datarange: this.selectedDateRange,
    }

    this.router.navigate(['/bills'], {
      state: { request: request },
    });

  }

  handleDataPointClick(seriesIndex: number, dataPointIndex: number): void {
    // console.log('Clicked Series Index:', seriesIndex);
    //onsole.log('Clicked Data Point Index:', dataPointIndex);

    // Check if series and data exist
    if (
      this.chartOptions.series &&
      this.chartOptions.series[seriesIndex] &&
      this.chartOptions.series[seriesIndex].data &&
      this.chartOptions.series[seriesIndex].data[dataPointIndex] !== undefined
    ) {
      // Get the selected data point
      const selectedData = this.chartOptions.series[seriesIndex].data[dataPointIndex];

      console.log('Selected Data Point:', selectedData);
      let request = {
        value: selectedData,
        UtilityType: this.selectedUtilityType?.name,
        flag: true,
        type: 'Consumption'
        // date: this.selectedDateRange
      }

      this.router.navigate(['/reports'], {
        state: { consumptionItem: request },
      });

      // this.router.navigate(['/maintenance']);
      // Ensure selectedData is valid

    } else {
      console.log('Data point not found in series.');
    }
  }


  public onDataPointSelection(event: any): void {
    console.log(event)
    const dataPointIndex = event[0]?.dataPointIndex;
    const seriesIndex = event[0]?.seriesIndex;

    // Check if series and data exist
    if (
      this.chartOptions.series &&
      this.chartOptions.series[seriesIndex] &&
      this.chartOptions.series[seriesIndex].data &&
      this.chartOptions.series[seriesIndex].data[dataPointIndex] !== undefined
    ) {
      const selectedData = this.chartOptions.series[seriesIndex].data[dataPointIndex];
      // console.log('Selected Data Point:', selectedData);

      // Perform additional actions here if needed
    } else {
      console.warn('Selected data point is undefined or does not exist.');
    }
  }

  maxMinValucChange(value: any) {
    if (value !== undefined) {
      console.log(value);
      this.consumptionminmaxvalue = value.id;
      this.flatwiseConsumptionData(this.topconsuptionSelectedUtilityType.name)
      // this.consuprtionUtilitiesGrahChange(this.topconsuptionSelectedUtilityType.name);
    } else {
      this.topconsumptionData = []
    }
  }

  consuprtionUtilitiesChange(value: any) {
    console.log(value);
    this.topconsumptionData = [];
    this.consuptionSelectedUtility = value.id;
    this.selectedUtilityType = value;
    this.consuptionSelectedUtilityType = value;
    //    this.consuprtionUtilitiesGrahChange(value.name);// top 5 consumption
    this.loadConsumptionChart(value.name);
  }
  utilitiesChangeConsumption(value: any) {//Consumption chart 
    //  console.log(this.selectedRange)
    this.consuptionSelectedUtility = value.id;
    this.consuptionSelectedUtilityType = value; //bottom utility name Change
    this.yaxisUnits = this.consuptionSelectedUtilityType.name == "Gas" ? 'Units (m³)' : this.consuptionSelectedUtilityType.name == "Electricity" ? 'Units (kWh)' : 'Units (Liters)';

    if (this.selectedRange == 'Last Three Months' || this.selectedRange == 'Last Six Months') {
      this.monthlyConsuptionChart(value.name)
    } else {
      this.loadConsumptionChart(value.name);
    }

  }

  utilitiesChange(value: any) {
    this.topconsuptionSelectedUtility = value.id;
    this.flatwiseConsumptionData(value.name)
    // this.consuprtionUtilitiesGrahChange(value.name);/// top 5 consumption
    this.topconsuptionSelectedUtilityType = value;////bottom utility name Change
    console.log(this.topconsuptionSelectedUtilityType)
  }


}

interface DailyConsumption {
  [date: string]: number;  // The date is a string and the consumption is a number
}

interface MonthlyConsumption {
  year: number;
  month: number;
  monthName: string;
  totalConsumptionForUtility: number;
}

interface UtilityData {
  utilityMasterName: string;
  totalConsumptionByDay: DailyConsumption;
}
