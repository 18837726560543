import { Component, ViewChild } from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { walletData } from './wallet-data';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CurrencyPipe } from '@angular/common';

import { Store, select } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { Router } from '@angular/router';
import {
  process,
  State,
  toODataString,
  SortDescriptor,
} from '@progress/kendo-data-query';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';

import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
  getAllWalletResponse,
  getWalletResponse,
} from '../../../store/Wallet/wallet-selector';
import {
  getAllWalletlist,
  getWalletlist,
  resetState,
} from '../../../store/Wallet/wallet.action';

import { table_pageSize } from '../../../shared/constants/templatesNames';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import _ from 'lodash';
import { SweetAlertIcon } from '../../../shared/constants/enum';
@Component({
  selector: 'app-rp-wallet',
  templateUrl: './rp-wallet.component.html',
  styleUrl: './rp-wallet.component.scss',
})
export class RpWalletComponent {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  public selectableSettings!: SelectableSettings;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  selectValue!: string[];

  public gridData: unknown[] = walletData;
  public gridView!: unknown[];

  // file upload

  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint

  public pageSize = 10;
  public skip = 0;
  totalwalletCount: number = 0;
  currentPage: number = 1;
  walletList: any[] = [];
  public sort: SortDescriptor[] = [];
  public walletgridData: GridDataResult = { data: [], total: 0 };
  public electric_city_gridData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  breadCrumbItems: Array<{}> | undefined;
  public walletState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  currentUser: any;
  projectId: any;
  mySelectionWallet: any = [];
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: any = '';
  allWalletList: any[] = [];
  constructor(
    private modalService: BsModalService,
    private store: Store,
    private sharedService: SharedService,
    private router: Router,
    private PdfexportService: PdfexportService,
    private currencyPipe: CurrencyPipe
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.isFilter = localStorage.getItem('filter');

    this.projectId = this.currentUser?.project.id;
    this.getStoreInfo();
    this.breadCrumbItems = [
      {
        label: 'Wallet Details',
      },
    ];
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }
  private exportSubscription: Subscription = new Subscription();
  getStoreInfo() {
    this.store.dispatch(resetState());

    let walletsList$ = this.store.pipe(select(getWalletResponse));
    this.exportSubscription = this.store
      .pipe(select(getAllWalletResponse))
      .subscribe((res: any) => {
        if (res.items.length > 0) {
          if (res) {
            this.allWalletList = [...res.items];
            const transformedData = this.allWalletList.map((item: any) => ({
              'Residential Unit': item?.residentialUnits?.map((item: { flatNo: any; }) => item.flatNo).join(', '),
              'Consumer': item.consumer,
              'Phone Number': item.phoneNo,
              "Previous Balance(₹)": item.previousBalance,
              'Current Balance(₹)': this.currencyPipe.transform(item.currentBalance, 'INR', 'symbol', '1.2-2'),
              'Last Recharge Amount(₹)': item.lastRechargeAmount,
              // 'Last Recharge Amount': this.currencyPipe.transform(item.lastRechargeAmount, 'INR', 'symbol', '1.2-2'),
              //  "Previous Day Balance": this.currencyPipe.transform(item.previousBalance, 'INR', 'symbol', '1.2-2'),

            }));
            this.PdfexportService.downloadExcel(transformedData, 'WalletList');
            this.loadMterConfig();
          }
        } else {
          this.sharedService.showMessageDialog(
            'No data to display',
            '',
            SweetAlertIcon.ERROR
          );
        }
      });

    walletsList$.subscribe((res: any) => {
      if (res) {
        console.log(res)
        this.walletList = [...res.items];
        this.pageSize = res.pageSize;
        this.totalwalletCount = res.totalCount;
      }
      this.loadMterConfig();
      // console.log('wallets list', res);
    });
  }
  ngOnDestroy() {
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }
  private loadMterConfig(): void {
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.walletList, state);
    console.log(processedData)
    console.log(state)
    this.walletgridData = {
      data: processedData.data,
      total: this.totalwalletCount,
    };

    this.walletgridData.data = this.walletgridData?.data.map((project) => {
      // console.log(new Date(project.currentDate));
      return {
        ...project,

        multiflats: project?.residentialUnits?.map((item: { flatNo: any; }) => item?.flatNo).join(', '),
        // 'lastRecharge_Amount': project.lastRechargeAmount != '' ? this.currencyPipe.transform(project.lastRechargeAmount, 'INR', 'symbol', '1.2-2') : '-',
        // 'current_Balance': project.lastRechargeAmount != '' ? this.currencyPipe.transform(project.currentBalance, 'INR', 'symbol', '1.2-2') : '-',

      };
    });

  }

  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  navigateToWalletView(dataItem: any): void {
    // console.log('dataItem-->', dataItem);
    this.sharedService.setWalletDetailsById(dataItem);
    this.router.navigate(['/wallet/walletDetails'])
    //const serializedData = JSON.stringify(dataItem);
    /*  {
      state: { walletViewDetails: _.cloneDeep(dataItem) },
    }); */
  }
  isFilter: any
  ngOnInit(): void {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    const savedFilter = this.sharedService.getFilters('wallet');
    if (this.isFilter == 'true' && savedFilter) {
      // this.filter = this.sharedService.getFilter();
      this.walletState.filter = savedFilter;
      //console.log(this.filter)
      // console.log(this.walletState)
      this.queryString = this.sharedService.createQuery(this.walletState);

    }
    if (this.isFilter == 'true') {

    }
    this.getAllWallets();
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.walletState.sort = sort;
    //console.log(this.walletState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.walletState.sort
    );
    this.orderByQuery = orderByQuery;
    this.getAllWallets();
  }

  public onWalletFilterChange(filter: CompositeFilterDescriptor): void {
    this.walletState.filter = filter;
    console.log(this.walletState);
    this.skip = 0
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this.sharedService.setFilters('wallet', filter);
    // this.sharedService.setFilter(filter);
    this.currentPage = 1
    const queryString = this.sharedService.createQuery(this.walletState);
    this.queryString = this.sharedService.createQuery(this.walletState);
    this.getAllWallets();
    // console.log(queryString);
  }

  public onValueChange(ev: string): void {
    // console.log(ev)
    // console.log(ev.length)
    // console.log(this.searchedValue)

    if (ev.length == 0) {
      this.searchModifiedData = ''
      this.walletConfigPageChange({ skip: 0, take: this.pageSize })
      this.getAllWallets()
    }
  }

  onSearchWallets() {
    if (this.searchedValue.length > 0) {
      this.onMeterConfigFilter(this.searchedValue)
    }
  }

  getAllWallets(): void {
    this.store.dispatch(
      getWalletlist({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        projectId: this.projectId,
      })
    );
  }

  getTotalWallets(): void {
    this.store.dispatch(
      getAllWalletlist({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
        projectId: this.projectId,
      })
    );
  }

  //grid related

  public isMasterRowSelected = (rowArg: any) => {
    return this.mySelectionWallet.some((row: any) => row.id === rowArg.id);
  };

  private createQuery(state: State): string {
    // Implement this method to create a query string from the state object
    // This should convert the state to your API's query parameters
    // Example implementation
    const queryStr = `${toODataString(state)}&$count=true`;
    console.log(queryStr);

    let query = `skip=${state.skip}&take=${state.take}`;

    if (state.filter) {
      const filters = state.filter.filters
        .map((f) => {
          const field = (f as any).field;
          const value = (f as any).value;
          return `${field}=${value}`;
        })
        .join('&');
      query += `&${filters}`;
    }

    return query;
  }

  protected walletConfigPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.mySelectionWallet = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.store.dispatch(
      getWalletlist({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchModifiedData != '' ? this.searchModifiedData : this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        projectId: this.projectId,
      })
    );
    this.loadMterConfig();
  }

  public selectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionWallet.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionWallet.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isMasterRowSelected(row.dataItem)) {
        this.mySelectionWallet.push(row.dataItem);
      }
    });

    console.log(this.mySelectionWallet);
  }
  searchModifiedData: string = ''
  public onMeterConfigFilter(value: any): void {
    this.searchModifiedData = this.searchedValue
    const trimmedValue = this.searchModifiedData.replace(/[₹,]/g, '');

    if (trimmedValue) {
      // Extract the numeric part from the match
      const numericValue = trimmedValue;
      // Format the numeric value as required (e.g., "5.67 KWh ,L..")
      this.searchModifiedData = numericValue;
      console.log(this.searchModifiedData)
    } else {
      // If the input doesn't match any special patterns, keep it as is
      this.searchModifiedData = this.searchedValue;
      console.log(this.searchModifiedData)
    }

    this.walletConfigPageChange({ skip: 0, take: this.pageSize })
    this.store.dispatch(
      getWalletlist({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchModifiedData,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        projectId: this.projectId,
      })
    );
  }
  exportAll() {
    this.getTotalWallets();
  }
  public onWalletPdfExport(type: string) {
    if (this.walletgridData.data.length > 0) {
      if (type === 'Excel') {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      } else {
        this.sharedService.showMessageDialog(
          'No data to display',
          '',
          SweetAlertIcon.ERROR
        );
      }
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadMterConfig();
  }
}
