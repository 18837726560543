<div class="container-fluid">
    <div class="row">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
    </div>






    <div class="row">
        <div class="col-lg-12">
            <div class="card">

                <div class="card-body">
                    <ng-stepper #cdkStepper [linear]="true" class="wizard" (selectionChange)="tabChange($event)">
                        <!-- Loop through steps to create dynamic tabs -->
                        <cdk-step *ngFor="let step of flatwWiseMetersdeatils; let i = index" [optional]="false">
                            <ng-template cdkStepLabel>
                                <span class="number">{{i+1}} </span>
                                <span>{{ step.flatNo }}</span>
                            </ng-template>

                            <!-- Content for each step -->
                            <div *ngIf="cdkStepper.selectedIndex === i">
                                <section class="">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="card-body">
                                                        @if(selectedflatwWiseMetersdeatils.length==0){ <div
                                                            class="text-center">

                                                            <img src="../../../assets/images/nodata.jpg" alt=""
                                                                height="250">
                                                            <h4 class="mt-5">No Data Found</h4>
                                                        </div>}
                                                        @else{
                                                        <div *ngIf="selectedflatwWiseMetersdeatils.length>0">
                                                            <div class="row"
                                                                *ngFor="let meterDetails of selectedflatwWiseMetersdeatils; let i = index">
                                                                <div class="col-lg-12">

                                                                    <div class="row">
                                                                        <div class="col-lg-3  mb-3">
                                                                            <div class="mb-3">
                                                                                <label class="form-label">Title</label>
                                                                                <p>{{meterDetails?.title?meterDetails.title:'-'}}
                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-3  mb-3">
                                                                            <div class="mb-3">
                                                                                <label class="form-label">First Name
                                                                                </label>
                                                                                <p>{{meterDetails?.firstName?meterDetails.firstName:'-'}}
                                                                                </p>

                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-3  mb-3">
                                                                            <div class="mb-3">
                                                                                <label class="form-label"> Last
                                                                                    Name</label>
                                                                                <p>{{meterDetails?.lastName?meterDetails.lastName:'-'}}
                                                                                </p>

                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-3  mb-3">
                                                                            <div class="mb-3">
                                                                                <label class="form-label">Phone Number
                                                                                    Primary </label>
                                                                                <p>{{meterDetails?.primaryPhoneno?meterDetails.primaryPhoneno:'--'}}
                                                                                </p>

                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-3  mb-3">
                                                                            <div class="mb-3">
                                                                                <label class="form-label">Phone Number
                                                                                    Secondary </label>
                                                                                <p>{{meterDetails?.secondaryPhoneno?meterDetails.secondaryPhoneno:'-'}}
                                                                                </p>

                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-3  mb-3">
                                                                            <div class="mb-3">
                                                                                <label class="form-label">Email -
                                                                                    Primary</label>
                                                                                <p>{{meterDetails?.primaryEmail?meterDetails.primaryEmail:'-'}}
                                                                                </p>

                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-3  mb-3">
                                                                            <div class="mb-3">
                                                                                <label class="form-label"> Email -
                                                                                    Secondary
                                                                                </label>
                                                                                <p>{{meterDetails?.secondaryEmail?meterDetails.secondaryEmail:'-'}}
                                                                                </p>

                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-3  mb-3">
                                                                            <div class="mb-3">
                                                                                <label class="form-label"> Occupied
                                                                                    Residential Unit </label>
                                                                                <p>{{meterDetails?.currentOccupiedPerson?meterDetails.currentOccupiedPerson:'-'}}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-3  mb-3">
                                                                            <div class="mb-3">
                                                                                <label class="form-label">Occupied Date
                                                                                </label>
                                                                                <p>{{meterDetails?.moveInDate!=null?
                                                                                    (meterDetails.moveInDate
                                                                                    |date: 'dd MMM yyyy hh:mm a' )

                                                                                    :'' }}
                                                                                </p>


                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-3  mb-3">
                                                                            <div class="mb-3">
                                                                                <label class="form-label">C/O </label>
                                                                                <p>{{meterDetails?.coName?meterDetails.coName:'--'}}
                                                                                </p>

                                                                            </div>
                                                                        </div>
                                                                        <div lass="row cursor-move mb-3"
                                                                            *ngIf="meterDetails.isInvoiceRequired">
                                                                            <p class=""><b>Rearrange the
                                                                                    below Information as
                                                                                    desired by dragging them.</b> </p>
                                                                        </div>
                                                                        <kendo-sortable #sortable [navigable]="true"
                                                                            *ngIf="meterDetails.isInvoiceRequired"
                                                                            [animation]="true"
                                                                            (dragOver)="onDragOver($event)"
                                                                            (navigate)="onNavigate($event)"
                                                                            class="row cursor-move mb-3"
                                                                            itemClass="col-lg-3"
                                                                            [kendoSortableBinding]="meterDetails?.modifiedInputFields">
                                                                            <ng-template let-inputFields="item">
                                                                                <label> {{inputFields.label}} </label>
                                                                                <input class="form-control" disabled
                                                                                    name="{{inputFields.name}}" reado
                                                                                    [(ngModel)]="inputFields.value">

                                                                            </ng-template>
                                                                        </kendo-sortable>
                                                                        <div *ngIf="meterDetails.isInvoiceRequired">
                                                                            <button type="button"
                                                                                class="btn btn-primary mb-3 float-end"
                                                                                [disabled]="!isButtonEnabled"
                                                                                (click)="updateMeters(meterDetails)">Update
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <accordion [closeOthers]="false"
                                                                    class="accordion custom-accordian"
                                                                    [isAnimated]="true">
                                                                    <accordion-group *ngIf="meterDetails?.gas.length>0"
                                                                        [isOpen]="isAllOpen" [heading]="gas"
                                                                        class="accordion-item mb-3 panel-open" #item1>
                                                                        <span accordion-heading
                                                                            class="float-end fs-5 d-flex align-items-center">

                                                                            <i class="mdi font-size-24"
                                                                                [ngClass]="!item1?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                                                        </span>

                                                                        <div class="row mb-3"
                                                                            *ngFor="let gas of meterDetails?.gas">
                                                                            <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Device SR
                                                                                    No</label>
                                                                                <p>{{gas.umdsrno?gas.meterSrNo:'-'}}</p>

                                                                            </div>
                                                                            <div class="col-lg-3 mb-3">
                                                                                <label class="form-label">Type</label>
                                                                                <p>{{gas.type?gas.type:'-'}}</p>
                                                                            </div>
                                                                            <div class="col-lg-3 mb-3">
                                                                                <label class="form-label"> Installed
                                                                                    Location</label>
                                                                                <p>{{gas.installedLocation?gas.installedLocation:'-'}}
                                                                                </p>

                                                                            </div>
                                                                            <div class="col-lg-3  mb-3">
                                                                                <label class="form-label">Initial
                                                                                    Reading</label>
                                                                                <p>{{gas.initialReading !=undefined
                                                                                    ?gas.initialReading:'-'}}</p>

                                                                            </div>
                                                                            <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Meter
                                                                                    Installation
                                                                                    Date</label>
                                                                                <p>{{gas.meterInstallationDate!=null?
                                                                                    (gas.meterInstallationDate
                                                                                    |date: 'dd MMM yyyy hh:mm a') :'' }}


                                                                                </p>
                                                                            </div>
                                                                            <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Meter
                                                                                    Status</label>
                                                                                <p>{{gas.meterStatus?gas.meterStatus:'-'}}
                                                                                </p>
                                                                            </div>
                                                                            <hr>
                                                                        </div>


                                                                    </accordion-group>

                                                                    <accordion-group
                                                                        *ngIf="meterDetails?.water.length>0"
                                                                        [isOpen]="isAllOpen" [heading]="water"
                                                                        class="accordion-item mb-3 panel-open" #item2>
                                                                        <span accordion-heading
                                                                            class="float-end fs-5 d-flex align-items-center">

                                                                            <i class="mdi font-size-24"
                                                                                [ngClass]="!item2?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                                                        </span>

                                                                        <div class="row mb-3"
                                                                            *ngFor="let Water  of meterDetails?.water">
                                                                            <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Device SR
                                                                                    No</label>
                                                                                <p>{{Water.umdsrno?Water.meterSrNo:'-'}}
                                                                                </p>

                                                                            </div>
                                                                            <div class="col-lg-3 mb-3">
                                                                                <label class="form-label">Type</label>
                                                                                <p>{{Water.type?Water.type:'-'}}</p>


                                                                            </div>
                                                                            <div class="col-lg-3 mb-3">
                                                                                <label class="form-label"> Installed
                                                                                    Location</label>
                                                                                <p>{{Water.installedLocation?Water.installedLocation:'-'}}
                                                                                </p>

                                                                            </div>
                                                                            <div class="col-lg-3  mb-3">
                                                                                <label class="form-label">Initial
                                                                                    Reading</label>
                                                                                <p>{{Water.initialReading
                                                                                    !=undefined?Water.initialReading:'-'}}
                                                                                </p>

                                                                            </div>
                                                                            <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Meter
                                                                                    Installation
                                                                                    Date</label>

                                                                                <p>{{Water.meterInstallationDate!=null?
                                                                                    (Water.meterInstallationDate
                                                                                    |date: 'dd MMM yyyy hh:mm a' ) :''
                                                                                    }}


                                                                                </p>

                                                                            </div>

                                                                            <!-- <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Meter
                                                                                    Status</label>
                                                                                <p>{{Water.meterStatus?Water.meterStatus:'-'}}
                                                                                </p>
                                                                            </div> -->
                                                                            <hr>
                                                                        </div>
                                                                    </accordion-group>

                                                                    <accordion-group
                                                                        *ngIf="meterDetails?.electricity.length>0"
                                                                        [isOpen]="isAllOpen" [heading]="electricity"
                                                                        class="accordion-item mb-3 panel-open" #item3>
                                                                        <span accordion-heading
                                                                            class="float-end fs-5 d-flex align-items-center">

                                                                            <i class="mdi font-size-24"
                                                                                [ngClass]="!item3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                                                        </span>

                                                                        <div class="row mb-3"
                                                                            *ngFor="let meter of meterDetails?.electricity">
                                                                            <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Device SR
                                                                                    No</label>
                                                                                <p>{{meter.umdsrno?meter.meterSrNo:'-'}}
                                                                                </p>

                                                                            </div>
                                                                            <div class="col-lg-3 mb-3">
                                                                                <label class="form-label">Type</label>
                                                                                <p>{{meter.type?meter.type:'-'}}</p>


                                                                            </div>
                                                                            <div class="col-lg-3 mb-3">
                                                                                <label class="form-label"> Installed
                                                                                    Location</label>
                                                                                <p>{{meter.installedLocation?meter.installedLocation:'-'}}
                                                                                </p>

                                                                            </div>

                                                                            <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Meter
                                                                                    Installation
                                                                                    Date</label>

                                                                                <p>{{meter.meterInstallationDate!=null?
                                                                                    (meter.meterInstallationDate
                                                                                    |date: 'dd MMM yyyy hh:mm a')

                                                                                    :'' }}
                                                                                </p>

                                                                            </div>
                                                                            <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Meter Initial
                                                                                    Reading
                                                                                    EB KWH</label>
                                                                                <p>{{meter.meterInitialReadingEBKWH
                                                                                    !=undefined
                                                                                    ?
                                                                                    meter.meterInitialReadingEBKWH:'-'}}
                                                                                </p>

                                                                            </div>
                                                                            <div class="col-lg-3 mb-3">
                                                                                <label class="form-label">Meter Initial
                                                                                    Reading
                                                                                    DG KWH </label>
                                                                                <p>{{meter.meterInitialReadingDGKWH
                                                                                    !=undefined
                                                                                    ?meter.meterInitialReadingDGKWH:'-'}}
                                                                                </p>

                                                                            </div>
                                                                            <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Meter
                                                                                    Status</label>
                                                                                <p>{{meter.meterStatus?meter.meterStatus:'-'}}
                                                                                </p>
                                                                            </div>
                                                                            <hr>
                                                                        </div>

                                                                    </accordion-group>
                                                                    <!-- Trisource -->
                                                                    <accordion-group
                                                                        *ngIf="meterDetails?.triSource.length>0"
                                                                        [isOpen]="isAllOpen" [heading]="trisource"
                                                                        class="accordion-item mb-3 panel-open" #item4>
                                                                        <span accordion-heading
                                                                            class="float-end fs-5 d-flex align-items-center">

                                                                            <i class="mdi font-size-24"
                                                                                [ngClass]="!item4?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                                                        </span>

                                                                        <div class="row mb-3"
                                                                            *ngFor="let tri of meterDetails?.triSource">
                                                                            <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Device SR
                                                                                    No</label>
                                                                                <p>{{tri.meterSrNo?tri.meterSrNo:'-'}}
                                                                                </p>

                                                                            </div>
                                                                            <div class="col-lg-3 mb-3">
                                                                                <label class="form-label">Type</label>
                                                                                <p>{{tri.type?tri.type:'-'}}</p>


                                                                            </div>
                                                                            <div class="col-lg-3 mb-3">
                                                                                <label class="form-label"> Installed
                                                                                    Location</label>
                                                                                <p>{{tri.installedLocation?tri.installedLocation:'-'}}
                                                                                </p>

                                                                            </div>

                                                                            <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Meter
                                                                                    Installation
                                                                                    Date</label>

                                                                                <p>{{tri.meterInstallationDate!=null?
                                                                                    (tri.meterInstallationDate
                                                                                    |date: 'dd MMM yyyy hh:mm a')

                                                                                    :'' }}
                                                                                </p>

                                                                            </div>
                                                                            <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Meter Initial
                                                                                    Reading
                                                                                    EB KWH</label>
                                                                                <p>{{tri.meterInitialReadingEBKWH
                                                                                    !=undefined
                                                                                    ?
                                                                                    tri.meterInitialReadingEBKWH:'-'}}
                                                                                </p>

                                                                            </div>
                                                                            <div class="col-lg-3 mb-3">
                                                                                <label class="form-label">Meter Initial
                                                                                    Reading
                                                                                    DG KWH </label>
                                                                                <p>{{tri.meterInitialReadingDGKWH
                                                                                    !=undefined
                                                                                    ?tri.meterInitialReadingDGKWH:'-'}}
                                                                                </p>

                                                                            </div>
                                                                            <div class="col-lg-3 mb-3">
                                                                                <label class="form-label">Meter Initial
                                                                                    Reading
                                                                                    Solar KWH </label>
                                                                                <p>{{tri.meterInitialReadingSolar
                                                                                    !=undefined
                                                                                    ?tri.meterInitialReadingSolar:'-'}}
                                                                                </p>

                                                                            </div>
                                                                            <div class=" col-lg-3 mb-3">
                                                                                <label class="form-label">Meter
                                                                                    Status</label>
                                                                                <p>{{tri.meterStatus?tri.meterStatus:'-'}}
                                                                                </p>
                                                                            </div>
                                                                            <hr>
                                                                        </div>

                                                                    </accordion-group>


                                                                </accordion>
                                                            </div>

                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </cdk-step>
                    </ng-stepper>
                </div>
            </div>
        </div>
    </div>