<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-start justify-content-between">
                <app-rp-breadcrumb class="breadcrumb m-0 mb-0 font-size-14 flex-grow-1"
                    [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <!-- <div class="d-flex flex-grow-1">
                        <div class="mb-3 me-4 flex-grow-1">
                            <label for="example-date-input">Select Date</label>
                            <input class="form-control" type="date" [(ngModel)]="selectedDate" id="example-date-input"
                                (ngModelChange)="updateFilteredFeedbacks()">
                        </div>
                        <div class="my-4 app-search flex-grow-1">
                            <div class="position-relative">
                                <input [(ngModel)]="searchText" type="text" class="form-control"
                                    placeholder="Search Name, residential unit" (ngModelChange)="updateFilteredFeedbacks()">
                                <span class="bx bx-search-alt"></span>
                            </div>
                        </div>
                    </div> -->
            </div>
        </div>

        <div class="col-lg-12">
            <!-- <form> -->
            <div class="row">
                <!-- <div class=" col-lg-4 my-4 app-search flex-grow-1">
                        <div class="position-relative">
                            <label class="form-label">Search</label>
                            <input [(ngModel)]="searchText" type="text" class="form-control"
                                placeholder="Search Name, residential unit" >
                            <span class="bx bx-search-alt"></span>
                        </div>
                    </div> -->


                <div class="col-lg-4">
                    <div class="mb-3">
                        <label class="form-label">Select Date</label>
                        <!-- <input style="cursor: text;" class="form-control" type="date" [(ngModel)]="selectedDate"
                                id="example-date-input"> -->

                        <div class="position-relative">
                            <input type="text" class="form-control date"
                                [value]="selectedDate ? (formatDateRange(selectedDate)) : ''" placeholder="Select Date"
                                name="daterange" autocomplete="off" bsDaterangepicker [bsConfig]="datePickerConfig"
                                [(bsValue)]="selectedDate" />
                            <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
                            <!-- Clear icon -->
                            <span class="bx bx-x inputIcon clear-icon" *ngIf="selectedDate.length>0"
                                (click)="clearDate()"></span>
                        </div>


                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="mb-3">
                        <label class="form-label">Search</label>
                        <div class="search_icon-box d-flex">
                            <kendo-textbox [style.height.px]="35" [clearButton]="true" (valueChange)="onValueChange($event)" [(ngModel)]="searchText"
                                placeholder="Search Name, Residential unit and Messages">
                            </kendo-textbox>

                            <!-- <button class="btn btn-primary btn-md border-left-none" > -->
                            <!--  <i class="btn  btn-md border-left-none fa fa-search" style="position: relative; right: 38px;"></i> -->
                            <!-- </button> -->
                        </div>

                        <!-- <div class="input-group">
                                <input style="cursor: text;" [(ngModel)]="searchText" type="text" class="form-control"
                                    placeholder="Search Name, residential unit">
                                <span class="input-group-text">
                                    <i class="bx bx-search-alt"></i>
                                </span>
                            </div> -->
                    </div>
                </div>



                <div class="col-lg-4 d-flex align-items-center sm-btn">
                    <button class="btn btn-primary btn-md mt-2" (click)="updateFilteredFeedbacks('filter')"> Apply
                    </button>
                    <button class="btn btn-outline-primary btn-md mt-2 ms-2" (click)="updateFilteredFeedbacks('reset')">
                        Reset
                    </button>

                </div>

            </div>

            <!-- </form> -->
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card" [id]="contentId">
                <div class="card-body">
                    <div *ngIf="pagedFeedbacks.length < 1&&loadeedData" class="text-center">
                        <img src="../../../assets/images/nodata.jpg" alt="" height="250">
                        <h4 class="mt-5">No feedback available yet</h4>
                    </div>
                    <div class="mb-4 border-bottom" *ngFor="let item of pagedFeedbacks">
                        <div class="d-flex">
                            <div class="flex-shrink-0 align-self-center me-3">
                                <img class="rounded-circle avatar-xs"
                                    [src]="item.imageUrl ? item.imageUrl : '../../../assets/images/users/user-dummy-img.jpg'" />
                                <!-- <img alt="" class="rounded-circle avatar-xs" [src]="item.imageUrl"> -->
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                                <h5 class="text-truncate font-size-14 mb-1">
                                    {{item.name}} ({{item.email}})
                                </h5>
                                <p class="text-truncate mb-0 text-blue" *ngIf="item.residentialUnit">Residential Unit:
                                    {{item.residentialUnit}}</p>
                                <p class="text-truncate mb-0 text-blue" *ngIf="item.projectName">Project Name:
                                    {{item.projectName}}</p>
                            </div>
                            <!-- <div class="font-size-11 text-blue">{{item.dateTime | date:'short'}}</div> -->
                            <div class="font-size-11 text-blue" *ngIf="item.dateTime">{{ item.dateTime | date:
                                'd MMM yyyy' }}</div>

                        </div>
                        <div class="ms-5 mt-1" *ngIf="item.description">{{item.description}}</div>
                    </div>
                    <hr>
                </div>
                <kendo-datapager [attr.aria-controls]="contentId" [style.width.%]="100" [pageSize]="pageSize"
                    [skip]="skip" [total]="total" [pageSizeValues]="pageSizes" (pageChange)="onPageChange($event)">
                </kendo-datapager>
            </div>
        </div>
    </div>
</div>