import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  Input,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  getUserProfile,
  resetProfileState,
  updateUserProfile,
} from '../../store/Authentication/authentication.actions';
import { Store, select } from '@ngrx/store';
import {
  getUpdatedUserProfileResponse,
  getUserProfileFailure,
  userDetails,
} from '../../store/Authentication/authentication-selector';
import { LoaderService } from '../../core/services/loader.service';
import { UserProfile } from '../../shared/modals/common';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Validations } from '../../shared/constants/validations';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
@Component({
  selector: 'app-rp-support',
  templateUrl: './rp-support.component.html',
  styleUrl: './rp-support.component.scss',
})

export class RpSupportComponent {
  
  @Input() userData: any;
  constructor(
    private router: Router,
    private store: Store,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private sharedService: SharedService
  ) {



  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userData'] && changes['userData'].currentValue) {
      console.log('Updated userData:', this.userData);
    }
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit - userData:', this.userData);
  }

  openPhone(phone: string): void {
    window.open(`tel:${phone}`, '_self');
  }

  openWhatsApp(phone: string): void {
    window.open(`https://wa.me/${phone}`, '_blank');
  }

  openEmail(email: string): void {
    window.open(`mailto:${email}`, '_blank');
  }

  openInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  closePopUp() {
    const profileData = {
      firstName: 'John',
      lastName: 'Doe',
      email: 'john@example.com',
      mobileNumber: '1234567890',
      secondMobileNumber: '0987654321',
      secondaryCountryCode: ''
    };

    // this.profileSaved.emit(profileData);
    this.modalService.hide();
  }



  ngOnInit() {

 
  }

}
