import { Component, ViewChild } from '@angular/core';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';

import { Store, select } from '@ngrx/store';
import { CompositeFilterDescriptor, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { LoaderService } from '../../../core/services/loader.service';
import { SharedService } from '../../../shared/shared.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

import { table_pageSize } from '../../../shared/constants/templatesNames';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { Subscription } from 'rxjs';
import { checkStatussettlement, getsettlement, getsettlementExport, resetsettlementExportState, retrysettlement, getSettlementReset } from '../../../store/customer/consumer.action';
import { getMeterOnOffControlsResponse, getsettlementExportResponse, getsettlementResponse, } from '../../../store/customer/consumer-selector';
import Swal from 'sweetalert2';
import { BillsService } from '../../../core/services/bills.service';
import { formatDate } from '@angular/common';
import _ from 'lodash';
import { resetHistoryState } from '../../../store/Wallet/wallet.action';


@Component({
  selector: 'app-rp-payments',
  templateUrl: './rp-payments.component.html',
  styleUrl: './rp-payments.component.scss',

})
export class RpPaymentsComponent {

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public sort: SortDescriptor[] = [];
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: any = '';
  public pageSize = 10;
  public skip = 0;
  settlementList: any[] = []
  totalUserCount: number = 0;
  currentPage: number = 1;
  exportData: any[] = [];
  breadCrumbItems: Array<{}> | undefined;
  private exportSubscription: Subscription = new Subscription();
  private retySettlementSubscription: Subscription = new Subscription();
  private getsettlementSubscription: Subscription = new Subscription();
  public settlementData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public pageSizes = table_pageSize.pageSizes;
  public selectableSettings!: SelectableSettings;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  public filterMode: FilterableSettings = 'row';
  public previousNext = true;
  public settlmentState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public loading: boolean = true;
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private store: Store,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService,
    private notificationService: NotificationService,
    private billsService: BillsService

  ) {
    this.breadCrumbItems = [{ label: 'payments' }];
    this.geStoreInfo();
  }

  ngOnInit(): void {
    this.loaderService.setLoading(true)
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    this.getSettlmentApi()

  }

  getSettlmentApi() {
    this.store.dispatch(
      getsettlement({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery

      })
    );
  }

  geStoreInfo() {

    this.store.dispatch(resetsettlementExportState());

    this.getsettlementSubscription = this.store.pipe(select(getsettlementResponse)).subscribe({
      next: (res) => {
        //console.log(res);
        if (res) {
          this.settlementList = [...res.items];
          this.pageSize = res.pageSize;
          this.totalUserCount = res.totalCount;
          //console.log(this.settlementList)
          this.loadGridTable()
        }
      },
    })

    this.exportSubscription = this.store.pipe(select(getsettlementExportResponse)).subscribe({
      next: (res) => {
        if (res) {
          // console.log(res)
          this.exportData = [];
          if (res.items.length > 0) {
            this.exportData = [...res.items];
            const transformedData = this.exportData?.map((item: any) => ({
              'Project Name': item.projectName,
              'Flat No': item.residentialUnit,
              'Consumer Name': item.consumerName,
              'Account Id': item.accountId,
              'Payment Id': item.paymentId,
              'Amount': item.amount,
              'Payment Date': formatDate(item.paymentDate, 'dd MMM yyyy hh:mm a', 'en-US'),
              'Payment Status': item.paymentStatus,
              'Order Id': item.orderId,
              'Transfer Id': item.transferId,
              'Transfer Date': formatDate(item.transferDate, 'dd MMM yyyy hh:mm a', 'en-US'),
              'Settlment Id': item.settlementId,
              'Settlement Date': formatDate(item.settlementDate, 'dd MMM yyyy hh:mm a', 'en-US'),
              'Settlement Status': item.settlementStatus,
            }));
            this.PdfexportService.downloadExcel(
              transformedData,
              'Payments'
            );
          } else {
            this.sharedService.showMessageDialog(
              'No data to display',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }

      },
    })




  }

  loadGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.settlementList, state);
    this.settlementData = {
      data: this.settlementList,
      total: this.totalUserCount,
    };

    this.settlementData.data = this.settlementData.data.map((item: any) => {
      return {
        ...item,
        'payment_Date': item?.paymentDate != null ? this.sharedService.dateFormateChnage(item?.paymentDate) : '-',
        'transfer_Date': item?.transferDate != null ? this.sharedService.dateFormateChnage(item?.transferDate) : '-',
        'settlement_Date': item?.settlementDate != null ? this.sharedService.dateFormateChnage(item?.settlementDate) : '-',
      }
    })
    // this.loading = false;
    this.loaderService.setLoading(false);
  }

  ngOnDestroy() {
    this.store.dispatch(getSettlementReset());
    this.exportSubscription.unsubscribe();
    this.getsettlementSubscription.unsubscribe()

  }

  exportAll() {
    this.store.dispatch(getsettlementExport({
      pageIndex: 1,
      pageSize: undefined,
      searchTerm: '',
      filter: '',
      orderBy: '',
    })
    );

  }

  checkStaus(dataItem: any) {
    console.log(dataItem.transferId)
    let request = {
      transferId: dataItem.transferId
    }
    this.store.dispatch(checkStatussettlement(request));

  }


  retrySettlment(dataItem: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You want to retry this!",
        icon: 'warning',
        confirmButtonText: 'Yes, retry it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        console.log(result.value);
        if (result.value) {
          let request = {
            "projectId": dataItem.projectId,
            "paymentId": dataItem.paymentId,
            "amount": dataItem.amount,
            "currency": "INR"
          }
          this.store.dispatch(retrysettlement({ settlementData: request }));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          /* swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          ); */
        }
      });
  }
  createTransfer(dataItem: any) {
    this.loaderService.setLoading(true);
    let request = {
      "projectId": dataItem.projectId,
      "paymentId": dataItem.paymentId,
      "amount": dataItem.amount,
      "currency": "string"
    }
    this.billsService.cretaeTransfer(request).subscribe({
      next: (res) => {
        this.loaderService.setLoading(false);
        if (res && res.statusCode === 201) {
          this.sharedService.showMessageDialog('Success', res.message, SweetAlertIcon.SUCCESS);
        }
      }, error: (err) => {
        this.loaderService.setLoading(false);
        this.sharedService.showMessageDialog(err.error.message, '', SweetAlertIcon.ERROR);
      },
    })
  }


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();
      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  public customFilterState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  customDateFiltersQueryEq: any[] = [];
  customDateFiltersQueryNEQ: any[] = [];


  async onsetellmentFilterChange(filter: CompositeFilterDescriptor) {
    this.settlmentState.filter = filter;
    this.skip = 0;
    console.log(filter)
    // console.log(this.billHistoryState);
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this.sharedService.setFilter(filter);


    this.customFilterState = {
      skip: 0,
      take: 10,
      filter: {
        logic: 'and',
        filters: [],
      },
    };
    this.customFilterState = _.cloneDeep(this.settlmentState);
    var filterOperators = await this.sharedService.togetFilterOperators(this.settlmentState);
    console.log("temp-------------->", filterOperators)
    const iscustomdates = filterOperators?.filter((filter: any) => {
      return filter.value instanceof Date;
    });

    let isEq: any;
    let isNeq: any;
    this.customDateFiltersQueryEq = [];
    this.customDateFiltersQueryNEQ = [];

    if (iscustomdates && iscustomdates.length > 0) {
      isEq = iscustomdates.filter((filter: any) => (filter.operator === "eq"));
      isNeq = iscustomdates.filter((filter: any) => (filter.operator === "neq"))

      if (isEq.length > 0) {
        this.customDateFiltersQueryEq = this.sharedService.customizeFilterQueryEQ(isEq);
        console.log("gasQueryString", this.customDateFiltersQueryEq)
      } if (isNeq?.length > 0) {
        isNeq.forEach((item: any) => {
          this.customDateFiltersQueryNEQ = this.sharedService.customizeFilterQueryNEQ(isNeq);
          console.log("gasQueryString", this.customDateFiltersQueryNEQ)
        })

      }

    } else {
      isNeq = undefined;
      isEq = undefined;
      this.customDateFiltersQueryEq = [];
      this.customDateFiltersQueryNEQ = [];
    }
    if (this.customDateFiltersQueryEq.length > 0 || this.customDateFiltersQueryNEQ.length > 0) {
      let customFilterQueryString = await this.sharedService.newmodifyFilterJson(this.customFilterState, this.customDateFiltersQueryEq, this.customDateFiltersQueryNEQ);
      this.queryString = this.sharedService.dateFiltercreateQuery(customFilterQueryString);
    } else {
      this.queryString = this.sharedService.createQuery(this.customFilterState);
    }


    const queryString = this.sharedService.createQuery(this.settlmentState);
    // this.queryString = this.sharedService.createQuery(this.settlmentState);
    this.getSettlmentApi()
    // console.log(queryString);
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.settlmentState.sort = sort;
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.settlmentState.sort
    );

    this.orderByQuery = orderByQuery;
    this.getSettlmentApi()

  }

  protected settlmentPageChange({ skip, take }: PageChangeEvent): void {
    // console.log('>>>', skip, take);
    this.selectedRowdata = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    //  console.log('currentpage', Math.floor(skip / take) + 1);
    this.getSettlmentApi();
    this.loadGridTable();
  }



  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  onSelect(event: any) {
    // console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdata.push(element.dataItem);
        //  console.log(' selected Values : ', this.selectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdata.push(element.dataItem);
        //   console.log('deselectedRowdata Values : ', this.deselectedRowdata);
      });

      if (this.deselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdata = filteredArray;
      }
    }
  }



  protected settlmentStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadGridTable();
  }


  public onValueChange(ev: string): void {
    if (ev.length == 0) {
      this.settlmentPageChange({ skip: 0, take: this.pageSize })
      this.getSettlmentApi()
    }
  }
  onSearchPayments(): void {
    if (this.searchedValue.length > 0) {
      this.getSettlmentApi()
    }
  }

  public onFilter(value: Event): void {
    this.settlmentPageChange({ skip: 0, take: this.pageSize })
    this.getSettlmentApi()
  }
  onExport(type: string) {

    if (type === 'Excel') {
      if (this.settlementData.data.length > 0) {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      }
      else {
        this.sharedService.showMessageDialog(
          'No data to display',
          '',
          SweetAlertIcon.ERROR
        );
      }
    }
  }





}
