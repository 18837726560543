import { SupportRoutingModule } from './rp-support-routing.module';
import { PagerModule } from '@progress/kendo-angular-pager';
import { SharedModule } from '../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { NgSelectModule } from '@ng-select/ng-select';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RpSupportComponent } from './rp-support.component';

@NgModule({
  declarations: [RpSupportComponent,],
  imports: [
    SharedModule,
    CommonModule,
    NgSelectModule,
    DropDownsModule,
    SupportRoutingModule,
    PagerModule,
    UploadsModule,
    LabelModule,
    BsDatepickerModule,
    AccordionModule.forRoot(),
  ],
})
export class SupportModule { }
