<div class="container-fluid">

  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <span class="breadcrumb m-0">
          @if(cdkStepper.selectedIndex === 1){<button class="btn btn-primary d-flex align-items-center"
            (click)="exportAll()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>}
          @if(cdkStepper.selectedIndex === 2){<button class="btn btn-primary d-flex align-items-center"
            (click)="umdExportAll()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>}

        </span>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title mb-4"></h4> -->
          <div>
            <ng-stepper #cdkStepper [linear]="true" class="wizard" (selectionChange)="tabChange($event)">

              <cdk-step [optional]="false" [ariaDisabled]="true">
                <ng-template cdkStepLabel>
                  <span class="number">1</span>
                  <span>Residential Unit Details</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 0){

                <section class="">
                  <!-- <h4 class="card-title mb-4 text-decoration-underline">Occupants History</h4> -->
                  <form action="" class="form-horizontal" [formGroup]="residentialUnitForm">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label for="">Flat /House# <span class="required">*</span></label>
                          <input type="text" class="form-control" id="flat_house" formControlName="flat_house"
                            name="flat_house">
                          @if(submitted && residentioalControls['flat_house'].errors){
                          <div class="invalid-feedback">
                            @if(residentioalControls['flat_house'].errors['required']){
                            <div>{{prifileValidations.residentialUnit.flat_House}}</div>}
                            @if(residentioalControls['flat_house'].errors['pattern']){
                            <div>{{prifileValidations.residentialUnit.patten}}</div>}
                          </div>}

                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">

                          <label for="basicpill-lastname-input">Floor <span class="required">*</span></label>
                          <input type="text" class="form-control" [disabled]="true" id="floor" formControlName="floor"
                            name="floor">
                          @if(submitted && residentioalControls['floor'].errors){
                          <div class="invalid-feedback">
                            @if(residentioalControls['floor'].errors['required']){
                            <div>{{prifileValidations.residentialUnit.floor}}</div>}
                            @if(residentioalControls['floor'].errors['pattern']){
                            <div>{{prifileValidations.residentialUnit.patten}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Tower <span class="required">*</span></label>
                          <input type="text" class="form-control" id="tower" formControlName="tower" name="tower">
                          @if((submitted || residentioalControls['tower'].touched) &&
                          residentioalControls['tower'].errors){
                          <div class="invalid-feedback">
                            @if(residentioalControls['tower'].errors['required']){
                            <div>{{prifileValidations.residentialUnit.tower}}</div>}
                            @if(residentioalControls['tower'].errors['pattern']){
                            <div>{{prifileValidations.residentialUnit.patten}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Block <span class="required">*</span></label>
                          <input type="text" class="form-control" id="block" formControlName="block" name="block">
                          @if(submitted && residentioalControls['block'].errors){
                          <div class="invalid-feedback">
                            @if(residentioalControls['block'].errors['required']){
                            <div>{{prifileValidations.residentialUnit.block}}</div>}
                            @if(residentioalControls['block'].errors['pattern']){
                            <div>{{prifileValidations.residentialUnit.patten}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label>Owner Name </label>
                          <ng-select [items]="ownerList" formControlName="owner_name" id="owner_name" name="owner_name"
                            (change)="valueChnage_Owner($event)" [multiple]="false" bindLabel="name" bindValue="id"
                            [placeholder]="'Select...'" [clearable]="false"></ng-select>
                          <!-- @if(residentioalControls && residentioalControls['owner_name'].errors){
                          <div class="invalid-feedback">
                            @if(residentioalControls['owner_name'].errors['required']){
                            <div>{{prifileValidations.residentialUnit.owner_name}}</div>}
                          </div>} -->
                        </div>

                      </div>

                    </div>

                    <div class="d-flex justify-content-between my-3 align-items-center">
                      <div class="d-flex ">

                        @if( updated_residenatilUnit?.occupant && updated_residenatilUnit?.occupant !=='' ){<h4
                          class="card-title mb-4 text-decoration-underline text-primary cursor-pointer"
                          (click)="openOccupant(template)">Update Occupant</h4>}
                        @else {
                        <h4 class="card-title mb-4 text-decoration-underline text-primary cursor-pointer"
                          (click)="openOccupant(template)">Add Occupant</h4>
                        }

                        <!-- @if(updated_residenatilUnit?.id && updated_residenatilUnit.occupant!='' ) {<h4
                          class="card-title mb-2 ms-3 text-decoration-underline text-primary cursor-pointer"
                          (click)="removedOccupant(removeOccupaint)">Remove Occupant</h4>} -->
                      </div>

                      <button type="button" class="btn btn-primary btn-sm" (click)="openUmd(addUmd)">Add
                        UMD</button>

                    </div>

                    @if(isOcupantEnable==true){
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label for="">Occupant Name</label>
                          <input type="text" class="form-control" id="Occupant_Name" formControlName="Occupant_Name"
                            name="Occupant_Name">
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label for="b">Move In Date</label>
                          <input type="text" class="form-control date" placeholder="Select Date" name="singleDate"
                            autocomplete="off" bsDatepicker [bsConfig]="datePickerConfig"
                            formControlName="date_registered" (keydown)="preventManualInput($event)" />

                          <!-- <input class="form-control" type="date" formControlName="date_registered"> -->
                          <!-- <kendo-datepicker calendarType="infinite" [format]="'dd/MM/yyyy'" [(value)]="fromDate"
                            formControlName="date_registered"></kendo-datepicker> -->
                        </div>
                      </div>
                      <!--  <div class="col-lg-3">
                        <div class="mb-3">
                          <label>Owner Name <span class="required">*</span></label>
                          <ng-select [items]="ownerList" formControlName="owner_name" id="owner_name" name="owner_name"
                            (change)="valueChnage_Owner($event)" [multiple]="false" bindLabel="name" bindValue="id"
                            [placeholder]="'Select...'"></ng-select>
                          @if(residentioalControls && residentioalControls['owner_name'].errors){
                          <div class="invalid-feedback">
                            @if(residentioalControls['owner_name'].errors['required']){
                            <div>{{prifileValidations.residentialUnit.owner_name}}</div>}
                          </div>}
                        </div>
                       
                      </div> -->
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label for="">Current Occupied Person </label>
                          <input type="text" class="form-control" id="currentOccupiedPerson"
                            formControlName="currentOccupiedPerson" name="currentOccupiedPerson">

                        </div>
                      </div>

                    </div>
                    }

                    <!-- accordion Start -->
                    <div>
                      <accordion [closeOthers]="false" class="accordion custom-accordian" [isAnimated]="true">

                        @if(gas_array.length>0){
                        <accordion-group [isOpen]="isGasOpen" [heading]="lengthOfgas"
                          class="accordion-item mb-3 panel-open" #group>
                          <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                            <i class="mdi font-size-24"
                              [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                          </span>
                          <div class="" formArrayName="gas_array">
                            <div *ngFor="let gas of gas_array.controls; let empIndex=index">
                              <div [formGroupName]="empIndex">

                                <!--  <i class="mdi mdi-trash-can d-block font-size-16"></i> -->
                                <i (click)="removie_gas(empIndex)" style="float: right"
                                  class="fas fa-trash-alt  cursor-pointer text-danger">
                                </i>

                                <div class="row">
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="">UMD SR No</label>
                                      <input type="text" class="form-control" name="Umd_sR_no" id="Umd_sR_no"
                                        formControlName='Umd_sR_no' [disabled]="true">

                                    </div>
                                  </div>

                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Type</label>
                                      <input class="form-control" type="text" name="type" id="type"
                                        formControlName='type'>
                                    </div>
                                  </div>
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Installed Location <span
                                          class="required">*</span></label>
                                      <input type="text" class="form-control" name="installed_Location"
                                        id="installed_Location" formControlName='installed_Location'>
                                      @if(gas.get('installed_Location')?.invalid && submitted){
                                      @if(gas.get('installed_Location')?.errors?.['required']){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.InstalledLocation}}</p>
                                      }
                                      @if(gas.get('installed_Location')?.errors?.['pattern']) {
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.OnlyAlphanumeric_patten }}
                                        } }
                                    </div>
                                  </div>

                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Meter Installation Date<span
                                          class="required">*</span> </label>
                                      <input type="text" class="form-control date" placeholder="Select Date"
                                        name="singleDate" autocomplete="off" bsDatepicker
                                        [bsConfig]="maxdatePickerConfig" formControlName="meterInstallation_Date"
                                        (keydown)="preventManualInput($event)" />



                                      <!-- <input type="text" class="form-control date"
                                        formControlName="meterInstallation_Date" placeholder="Select Date" readonly
                                        name="singleDate" autocomplete="off" (bsValueChange)="Selectdates($event)"
                                        (keydown)="preventManualInput($event)" bsDatepicker [bsConfig]="{
                                          isAnimated: true, 
                                          dateInputFormat: 'DD/MM/YYYY',
                                          maxDate: todayDate,  
                                          showWeekNumbers: false,
                                               
                                  }" /> -->
                                      <!-- <input class="form-control" type="date" formControlName="meterInstallation_Date"
                                        format="'dd/MM/yyyy'" [max]="maxDate"> -->
                                      <!-- <kendo-datepicker calendarType="infinite" [format]="'dd/MM/yyyy'"
                                        formControlName="meterInstallation_Date"></kendo-datepicker> -->
                                      @if(gas.get('meterInstallation_Date')?.invalid && submitted
                                      ){
                                      @if(gas.get('meterInstallation_Date')?.errors){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.Meter_Installation_Date}}</p>
                                      } }
                                    </div>
                                  </div>

                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Initial Reading<span
                                          class="required">*</span>
                                      </label>
                                      <input type="text" class="form-control" name="initialReading" id="initialReading"
                                        formControlName='initialReading'>
                                      @if(gas.get('initialReading')?.invalid &&submitted
                                      ){
                                      @if(gas.get('initialReading')?.errors?.['required']){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.InitialReading}}</p>
                                      }
                                      @if(gas.get('initialReading')?.errors?.['pattern']) {
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.OnlyNumberDecimal_patten }}
                                        }
                                        }
                                    </div>
                                  </div>

                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Meter Initial Reading Date<span
                                          class="required">*</span> </label>
                                      <input type="text" class="form-control date" placeholder="Select Date"
                                        name="singleDate" autocomplete="off" bsDatepicker
                                        [bsConfig]="maxdatePickerConfig" formControlName="meter_InitialReadingDate"
                                        (keydown)="preventManualInput($event)" />
                                      <!--  <input class="form-control" type="date" formControlName="meter_InitialReadingDate"
                                        format="'dd/MM/yyyy'"> -->
                                      <!-- <kendo-datepicker calendarType="infinite" [format]="'dd/MM/yyyy'"
                                        formControlName="meter_InitialReadingDate"></kendo-datepicker> -->
                                      @if(gas.get('meter_InitialReadingDate')?.invalid && submitted
                                      ){
                                      @if(gas.get('meter_InitialReadingDate')?.errors){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.MeterinitialReadingDate }}</p>
                                      }


                                      }
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <hr>
                            </div>
                          </div>


                        </accordion-group>
                        }
                        @if(water_array.length > 0){
                        <accordion-group [isOpen]="isWaterOpen" [heading]="lengthOFwater"
                          class="accordion-item mb-3 panel-open" #md>
                          <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                            <i class="mdi font-size-24"
                              [ngClass]="!md?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                          </span>
                          <div class="" formArrayName="water_array">
                            <div *ngFor="let water of water_array.controls; let empIndex=index">
                              <div [formGroupName]="empIndex">
                                <i (click)="removie_water(empIndex)" style="float: right"
                                  class="fas fa-trash-alt cursor-pointer text-danger">
                                </i>

                                <div class="row">
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="">UMD SR No</label>
                                      <input type="text" class="form-control" name="Umd_sR_no" id="Umd_sR_no"
                                        formControlName='Umd_sR_no'>
                                    </div>
                                  </div>

                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Type</label>
                                      <input class="form-control" type="text" name="type" id="type"
                                        formControlName='type'>
                                    </div>
                                  </div>
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Installed Location<span
                                          class="required">*</span></label>
                                      <input type="text" class="form-control" name="installed_Location"
                                        id="installed_Location" formControlName='installed_Location'>
                                      @if(water.get('installed_Location')?.invalid && submitted
                                      ){
                                      @if(water.get('installed_Location')?.errors?.['required']){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.InstalledLocation}}</p>
                                      }
                                      @if(water.get('installed_Location')?.errors?.['pattern']) {
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.OnlyAlphanumeric_patten }}
                                        }}
                                    </div>
                                  </div>

                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Meter Installation Date <span
                                          class="required">*</span></label>
                                      <!--  <input class="form-control" type="date" formControlName="meterInstallation_Date"
                                        format="'dd/MM/yyyy'"> -->
                                      <input type="text" class="form-control date" placeholder="Select Date"
                                        name="singleDate" autocomplete="off" bsDatepicker
                                        [bsConfig]="maxdatePickerConfig" formControlName="meterInstallation_Date"
                                        (keydown)="preventManualInput($event)" />

                                      <!-- <kendo-datepicker calendarType="infinite" [format]="'dd/MM/yyyy'"
                                        formControlName="meterInstallation_Date"></kendo-datepicker> -->
                                      @if(water.get('meterInstallation_Date')?.invalid && submitted
                                      ){
                                      @if(water.get('meterInstallation_Date')?.errors){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.Meter_Installation_Date}}</p>
                                      } }

                                    </div>
                                  </div>

                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Initial Reading <span
                                          class="required">*</span></label>
                                      <input type="text" class="form-control" name="initialReading" id="initialReading"
                                        formControlName='initialReading'>
                                      @if(water.get('initialReading')?.invalid && submitted
                                      ){
                                      @if(water.get('initialReading')?.errors?.['required']){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.InitialReading}}</p>
                                      }
                                      @if(water.get('initialReading')?.errors?.['pattern']) {
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.OnlyNumberDecimal_patten }}
                                        }}
                                    </div>
                                  </div>
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Meter Initial Reading Date <span
                                          class="required">*</span></label>
                                      <!-- <input class="form-control" type="date" formControlName="meter_InitialReadingDate"
                                        format="'dd/MM/yyyy'"> -->
                                      <input type="text" class="form-control date" placeholder="Select Date"
                                        name="singleDate" autocomplete="off" bsDatepicker
                                        [bsConfig]="maxdatePickerConfig" formControlName="meter_InitialReadingDate"
                                        (keydown)="preventManualInput($event)" />

                                      <!-- <kendo-datepicker calendarType="infinite" [format]="'dd/MM/yyyy'"
                                        formControlName="meter_InitialReadingDate"></kendo-datepicker> -->
                                      @if(water.get('meter_InitialReadingDate')?.invalid && submitted
                                      ){
                                      @if(water.get('meter_InitialReadingDate')?.errors){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.MeterinitialReadingDate}}</p>
                                      } }
                                    </div>
                                  </div>

                                </div>
                                <hr>
                              </div>
                            </div>
                          </div>



                        </accordion-group>
                        }
                        @if(electricity_array.length>0){
                        <accordion-group [isOpen]="isEleOpen" [heading]="lengthOfelectricity"
                          class="accordion-item mb-3 panel-open" #item3>
                          <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                            <i class="fas font-size-24"
                              [ngClass]="!item3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                          </span>

                          <div class="" formArrayName="electricity_array">
                            <div *ngFor="let ele of electricity_array.controls; let empIndex=index">
                              <div [formGroupName]="empIndex">
                                <i (click)="removie_Ele(empIndex)" style="float: right"
                                  class="fas fa-trash-alt  cursor-pointer text-danger">
                                </i>

                                <div class="row">
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="">UMD SR No</label>
                                      <input type="text" class="form-control" name="Umd_sR_no" id="Umd_sR_no"
                                        formControlName='Umd_sR_no'>
                                    </div>
                                  </div>

                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Type</label>
                                      <input class="form-control" type="text" name="type" id="type"
                                        formControlName='type'>
                                    </div>
                                  </div>
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Installed Location<span
                                          class="required">*</span></label>
                                      <input type="text" class="form-control" name="installed_Location"
                                        id="installed_Location" formControlName='installed_Location'>
                                      @if(ele.get('installed_Location')?.invalid && submitted
                                      ){
                                      @if(ele.get('installed_Location')?.errors?.['required']){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.InstalledLocation}}</p>
                                      }
                                      @if(ele.get('installed_Location')?.errors?.['pattern']) {
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.OnlyAlphanumeric_patten }}
                                        } }
                                    </div>
                                  </div>

                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Meter Installation Date <span
                                          class="required">*</span></label>
                                      <!--  <input class="form-control" type="date" formControlName="meterInstallation_Date"
                                        format="'dd/MM/yyyy'"> -->
                                      <input type="text" class="form-control date" placeholder="Select Date"
                                        name="singleDate" autocomplete="off" bsDatepicker
                                        [bsConfig]="maxdatePickerConfig" formControlName="meterInstallation_Date"
                                        (keydown)="preventManualInput($event)" />

                                      <!-- <kendo-datepicker calendarType="infinite" [format]="'dd/MM/yyyy'"
                                        formControlName="meterInstallation_Date"></kendo-datepicker> -->
                                      @if(ele.get('meterInstallation_Date')?.invalid && submitted
                                      ){
                                      @if(ele.get('meterInstallation_Date')?.errors){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.Meter_Installation_Date}}</p>
                                      } }
                                    </div>
                                  </div>

                                  <!-- <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Initial Reading <span
                                          class="required">*</span></label>
                                      <input type="text" class="form-control" name="initialReading" id="initialReading"
                                        formControlName='initialReading'>
                                      @if(ele.get('initialReading')?.invalid && submitted
                                      ){
                                      @if(ele.get('initialReading')?.errors?.['required']){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.InitialReading}}</p>
                                      } @if(ele.get('initialReading')?.errors?.['pattern']) {
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.OnlyNumberDecimal_patten }}
                                        }}
                                    </div>
                                  </div> -->
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Meter Initial Reading Date <span
                                          class="required">*</span></label>
                                      <!-- <input class="form-control" type="date" formControlName="meter_InitialReadingDate"
                                        format="'dd/MM/yyyy'"> -->

                                      <input type="text" class="form-control date" placeholder="Select Date"
                                        name="singleDate" autocomplete="off" bsDatepicker
                                        [bsConfig]="maxdatePickerConfig" formControlName="meter_InitialReadingDate"
                                        (keydown)="preventManualInput($event)" />

                                      @if(ele.get('meter_InitialReadingDate')?.invalid && submitted
                                      ){
                                      @if(ele.get('meter_InitialReadingDate')?.errors){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.MeterinitialReadingDate}}</p>
                                      }
                                      }
                                    </div>
                                  </div>
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Meter Initial Reading EB (kWh) <span
                                          class="required">*</span></label>
                                      <input type="text" class="form-control" name="Meter_Initial_Reading_EB_KWH"
                                        id="Meter_Initial_Reading_EB_KWH"
                                        formControlName='Meter_Initial_Reading_EB_KWH'>
                                      @if(ele.get('Meter_Initial_Reading_EB_KWH')?.invalid && submitted
                                      ){
                                      @if(ele.get('Meter_Initial_Reading_EB_KWH')?.errors?.['required']){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.Meter_Initial_Reading_EB_KWH}}</p>
                                      }
                                      @if(ele.get('Meter_Initial_Reading_EB_KWH')?.errors?.['pattern']) {
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.OnlyNumberDecimal_patten }}
                                        }}
                                    </div>
                                  </div>
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Meter Initial Reading DG (kWh) <span
                                          class="required">*</span></label>
                                      <input type="text" class="form-control" name="Meter_Initial_Reading_DG_KWH"
                                        id="Meter_Initial_Reading_DG_KWH"
                                        formControlName='Meter_Initial_Reading_DG_KWH'>
                                      @if(ele.get('Meter_Initial_Reading_DG_KWH')?.invalid && submitted
                                      ){
                                      @if(ele.get('Meter_Initial_Reading_DG_KWH')?.errors?.['required']){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.Meter_Initial_Reading_DG_KWH}}</p>
                                      }
                                      @if(ele.get('Meter_Initial_Reading_DG_KWH')?.errors?.['pattern']) {
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.OnlyNumberDecimal_patten }}
                                        }}


                                    </div>
                                  </div>

                                </div>
                              </div>
                              <hr>
                            </div>
                          </div>

                        </accordion-group>
                        }
                        @if(triSource_array.length > 0){
                        <accordion-group [isOpen]="isTriOpen" [heading]="lengthOfelTriSource"
                          class="accordion-item mb-3 panel-open" #item4>
                          <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                            <i class="fas font-size-24"
                              [ngClass]="!item4?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                          </span>
                          <div class="" formArrayName="triSource_array">
                            <div *ngFor="let tri of triSource_array.controls; let empIndex=index">
                              <div [formGroupName]="empIndex">
                                <i (click)="removie_Ele(empIndex)" style="float: right"
                                  class="fas fa-trash-alt  cursor-pointer text-danger">
                                </i>

                                <div class="row">
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="">UMD SR No</label>
                                      <input type="text" class="form-control" name="Umd_sR_no" id="Umd_sR_no"
                                        formControlName='Umd_sR_no'>
                                    </div>
                                  </div>

                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Type</label>
                                      <input class="form-control" type="text" name="type" id="type"
                                        formControlName='type'>
                                    </div>
                                  </div>
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Installed Location<span
                                          class="required">*</span></label>
                                      <input type="text" class="form-control" name="installed_Location"
                                        id="installed_Location" formControlName='installed_Location'>
                                      @if(tri.get('installed_Location')?.invalid && submitted
                                      ){
                                      @if(tri.get('installed_Location')?.errors?.['required']){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.InstalledLocation}}</p>
                                      }
                                      @if(tri.get('installed_Location')?.errors?.['pattern']) {
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.OnlyAlphanumeric_patten }}
                                        } }
                                    </div>
                                  </div>

                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Meter Installation Date <span
                                          class="required">*</span></label>

                                      <input type="text" class="form-control date" placeholder="Select Date"
                                        name="singleDate" autocomplete="off" bsDatepicker
                                        [bsConfig]="maxdatePickerConfig" formControlName="meterInstallation_Date"
                                        (keydown)="preventManualInput($event)" />


                                      @if(tri.get('meterInstallation_Date')?.invalid && submitted
                                      ){
                                      @if(tri.get('meterInstallation_Date')?.errors){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.Meter_Installation_Date}}</p>
                                      } }
                                    </div>
                                  </div>


                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Meter Initial Reading Date <span
                                          class="required">*</span></label>


                                      <input type="text" class="form-control date" placeholder="Select Date"
                                        name="singleDate" autocomplete="off" bsDatepicker
                                        [bsConfig]="maxdatePickerConfig" formControlName="meter_InitialReadingDate"
                                        (keydown)="preventManualInput($event)" />

                                      @if(tri.get('meter_InitialReadingDate')?.invalid && submitted
                                      ){
                                      @if(tri.get('meter_InitialReadingDate')?.errors){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.MeterinitialReadingDate}}</p>
                                      }
                                      }
                                    </div>
                                  </div>
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Meter Initial Reading EB (kWh) <span
                                          class="required">*</span></label>
                                      <input type="text" class="form-control" name="Meter_Initial_Reading_EB_KWH"
                                        id="Meter_Initial_Reading_EB_KWH"
                                        formControlName='Meter_Initial_Reading_EB_KWH'>
                                      @if(tri.get('Meter_Initial_Reading_EB_KWH')?.invalid && submitted
                                      ){
                                      @if(tri.get('Meter_Initial_Reading_EB_KWH')?.errors?.['required']){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.Meter_Initial_Reading_EB_KWH}}</p>
                                      }
                                      @if(tri.get('Meter_Initial_Reading_EB_KWH')?.errors?.['pattern']) {
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.OnlyNumberDecimal_patten }}
                                        }}
                                    </div>
                                  </div>
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Meter Initial Reading DG (kWh) <span
                                          class="required">*</span></label>
                                      <input type="text" class="form-control" name="Meter_Initial_Reading_DG_KWH"
                                        id="Meter_Initial_Reading_DG_KWH"
                                        formControlName='Meter_Initial_Reading_DG_KWH'>
                                      @if(tri.get('Meter_Initial_Reading_DG_KWH')?.invalid && submitted
                                      ){
                                      @if(tri.get('Meter_Initial_Reading_DG_KWH')?.errors?.['required']){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.Meter_Initial_Reading_DG_KWH}}</p>
                                      }
                                      @if(tri.get('Meter_Initial_Reading_DG_KWH')?.errors?.['pattern']) {
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.OnlyNumberDecimal_patten }}
                                        }}
                                    </div>
                                  </div>

                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="basicpill-lastname-input">Meter Initial Reading Solar (kWh) <span
                                          class="required">*</span></label>
                                      <input type="text" class="form-control" name="Meter_Initial_Reading_Solar_KWH"
                                        id="Meter_Initial_Reading_Solar_KWH"
                                        formControlName='Meter_Initial_Reading_Solar_KWH'>
                                      @if(tri.get('Meter_Initial_Reading_Solar_KWH')?.invalid && submitted
                                      ){
                                      @if(tri.get('Meter_Initial_Reading_Solar_KWH')?.errors?.['required']){
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.Meter_Initial_Reading_Solar_KWH}}</p>
                                      }
                                      @if(tri.get('Meter_Initial_Reading_Solar_KWH')?.errors?.['pattern']) {
                                      <p class="invalid-feedback">
                                        {{prifileValidations.residentialUnit.OnlyNumberDecimal_patten }}
                                        }}
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <hr>
                            </div>
                          </div>
                        </accordion-group>
                        }

                      </accordion>
                    </div>
                  </form>
                </section>

                <ul class="list-inline wizard d-flex justify-content-end mt-3">
                  <li class="previous list-inline-item"><button class="btn btn-outline-primary"
                      [routerLink]="'/residentialUnit'">Cancel</button></li>
                  <!-- <li class="next list-inline-item"><button class="btn btn-primary"
                      (click)="saveResidentionalUnit('Create')">Save</button> -->
                  @if(updated_residenatilUnit?.id) {<li class="next list-inline-item"><button class="btn btn-primary"
                      (click)="saveResidentionalUnit('Update')">update</button>
                  </li>}
                  @else { <li class="next list-inline-item"><button class="btn btn-primary"
                      (click)="saveResidentionalUnit('Create')">Save</button>
                  </li>}
                </ul>
                }
              </cdk-step>
              @if(isCreate=='edit'){
              <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">2.</span>
                  <span>Occupants History</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 1){
                <section class="">
                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>
                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">
                    <kendo-grid [data]="residentialunitgridData" [reorderable]="true" filterable="menu"
                      [selectable]="selectableSettings" scrollable="scrollable" [columnMenu]="{ filter: true }"
                      [resizable]="true" [pageable]="{
                      buttonCount: buttonCount,
                      info: info,
                      type: type,
                      pageSizes: pageSizes,
                      previousNext: previousNext
                    }" [filter]="occupationState.filter" [skip]="skip" [sort]="occupationState.sort"
                      [pageSize]="umdhistorypageSize" [sortable]="true" [resizable]="true"
                      (filterChange)="onOccupaintFilterChange($event)" (pageChange)="OccupationHistory($event)"
                      (dataStateChange)="residentialStateChange($event)" (selectionChange)="onSelect($event)"
                      (sortChange)="onresidentialSortChange($event)">

                      <ng-template kendoGridToolbarTemplate>
                        <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [clearButton]="true"
                            (valueChange)="occupaintValueChange($event)" [style.height.px]="35" [style.height.px]="35"
                            (keydown.enter)="onSearchResidentialUnits()" [(ngModel)]="searchedOccupant"
                            placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary border-left-none" (click)="OccupantsHistoryonFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <!--  <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                          ></kendo-textbox>
                        <button class="btn btn-primary border-left-none" (click)="onFilter($event)">
                          <i class="fa fa-search"></i>
                        </button> -->
                        <kendo-grid-spacer></kendo-grid-spacer>

                        <!--  <button class="btn btn-primary d-flex align-items-center" (click)="onExport('Excel')">
                          <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                        </button> -->

                        <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                          (click)="onExport('Excel')">
                          <span class="fas fa-file-excel"></span> &nbsp; Export to Excel
                        </button>

                      </ng-template>
                      <!-- Pdf   start-->
                      <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                        <kendo-grid
                          [kendoGridBinding]="selectedRowdata.length>0 ? selectedRowdata:exportdata.length>0?exportdata:residentialunitgridData.data"
                          #pdfGrid>

                          <kendo-grid-column field="name" title="Occupant Name"></kendo-grid-column>
                          <kendo-grid-column field="'moveIn_Date" title="Move In Date">

                          </kendo-grid-column>

                          <kendo-grid-column field="vacate_Date" title="Vacate Date">

                          </kendo-grid-column>
                          <!-- kendo-grid-pdf fileName="residentialUnitpdf" [allPages]="true" paperSize="A4"
                            [landscape]="true"></kendo-grid-pdf> -->
                          <kendo-grid-excel fileName="OccupoiantHistory.xlsx"></kendo-grid-excel>
                        </kendo-grid>
                      </div>
                      <!-- Pdf Edrt-->

                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                        [width]="40"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="name" title="Occupant Name"></kendo-grid-column>
                      <kendo-grid-column field="moveInDate" title="Move In Date" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.moveInDate | date: 'dd MMM yyyy'}}
                        </ng-template></kendo-grid-column>
                      <kendo-grid-column field="vacateDate" title="Vacate Date" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.vacateDate | date: 'dd MMM yyyy'}}
                        </ng-template>
                      </kendo-grid-column>

                    </kendo-grid>
                  </div>
                </section>
                }
              </cdk-step>
              <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">3.</span>
                  <span>Meter History</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 2){
                <section class="">
                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>
                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="umdshowTooltip($event)">
                    <kendo-grid [data]="residentialunitUmdHistorygridData" [reorderable]="true" filterable="menu"
                      [selectable]="selectableSettings" [columnMenu]="{ filter: true }" [resizable]="true" [pageable]="{
                        buttonCount: buttonCount,
                        info: info,
                        type: type,
                        pageSizes: pageSizes,
                        previousNext: previousNext
                      }" [filter]="umdState.filter" [skip]="skip" [sort]="umdState.sort" [pageSize]="pageSize"
                      [sortable]="true" [resizable]="true" (filterChange)="umdFilterChange($event)"
                      (pageChange)="umdPageChange($event)" (dataStateChange)="umdStateChange($event)"
                      (selectionChange)="onSelect($event)" (sortChange)="onSortChange($event)">

                      <ng-template kendoGridToolbarTemplate>
                        <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [clearButton]="true"
                            (valueChange)="meterValueChange($event)" [style.height.px]="35" [(ngModel)]="searchedUmd"
                            placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary border-left-none" (click)="umdonFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                          (valueChange)="umdonFilter($event)"></kendo-textbox> -->
                        <kendo-grid-spacer></kendo-grid-spacer>

                        <!-- <button kendoGridAddCommand class="btn btn-light"
                          [routerLink]="'/residentialUnit/createResidentialUnit'">Add Meters </button> -->

                        <!--  <button class="btn btn-primary d-flex align-items-center" (click)="umdonExport('Excel')">
                          <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                        </button> -->
                        <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                          (click)="umdonExport('Excel')">
                          <span class="fas fa-file-excel"></span> &nbsp; Export to Excel
                        </button>
                      </ng-template>

                      <!-- Pdf   start-->
                      <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                        <kendo-grid
                          [kendoGridBinding]="umdselectedRowdata.length>0 ? umdselectedRowdata:umdexportdata.length>0?umdexportdata:residentialunitUmdHistorygridData.data"
                          #umdpdfGrid>
                          <kendo-grid-column field="umdsrno" title="Device Sr.No"></kendo-grid-column>
                          <kendo-grid-column field="macAddress" title="Mac Address"></kendo-grid-column>
                          <kendo-grid-column field="type" title="Utility"></kendo-grid-column>
                          <kendo-grid-column field="installedLocation" title="Installed Location"></kendo-grid-column>
                          <kendo-grid-column field="meterInstallation_Date" title="Meter Installed Date" filter="date">

                          </kendo-grid-column>
                          <kendo-grid-column field="initialReading" title="Initial Reading"></kendo-grid-column>
                          <kendo-grid-column field="initialReadingEBKWH"
                            title=" EB Meter Initial reading KWH"></kendo-grid-column>
                          <kendo-grid-column field="initialReadingDGKWH"
                            title="DG Meter Initial reading  KWH"></kendo-grid-column>
                          <kendo-grid-column field="initialReading_Date" title="Initial Reading date">
                          </kendo-grid-column>
                          <kendo-grid-column field="updatedOn" title="updated date">
                          </kendo-grid-column>
                          <kendo-grid-column field="action" title="Action"></kendo-grid-column>
                          <kendo-grid-excel fileName="MeterHistory.xlsx"></kendo-grid-excel>
                        </kendo-grid>
                      </div>
                      <!-- Pdf Edrt-->

                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                        [width]="40"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="umdsrno" title="Device Sr.No"></kendo-grid-column>
                      <kendo-grid-column field="macAddress" title="Mac Address"></kendo-grid-column>
                      <kendo-grid-column field="type" title="Type"></kendo-grid-column>
                      <kendo-grid-column field="installedLocation" title="Installed Location"></kendo-grid-column>
                      <kendo-grid-column field="meterInstallationDate" [hidden]="true" filter="date"
                        title="Meter Installed Date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.meterInstallationDate | date: 'dd MMM yyyy hh:mm a'}}
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="initialReading" title="Initial Reading"
                        filter="numeric"></kendo-grid-column>
                      <kendo-grid-column field="initialReadingEBKWH" title="EB Initial reading  KWH"
                        filter="numeric"></kendo-grid-column>
                      <kendo-grid-column field="initialReadingDGKWH" title="DG Initial reading  KWH"
                        filter="numeric"></kendo-grid-column>
                      <kendo-grid-column field="initialReadingDate" [hidden]="true" filter="date"
                        title="Initial Reading date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.initialReadingDate | date: 'dd MMM yyyy hh:mm a'}}
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="modifiedOn" [hidden]="true" filter="date" title="updated date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.modifiedOn | date: 'dd MMM yyyy hh:mm a'}}
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-command-column title="" [columnMenu]="false" [width]="90">
                        <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.action}}</ng-template>
                      </kendo-grid-command-column>

                    </kendo-grid>

                  </div>
                </section>
                }
              </cdk-step>
              }
            </ng-stepper>
          </div>

        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>

</div>


<!-- Add Occupants modal -->
<ng-template #template>
  <div class="modal-header ">
    <h5 class="modal-title">Add Occupants</h5>
    <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-body">
    <form class="row" [formGroup]="ocupantForm">
      <div class="col-lg-6">
        <div class="mb-3">
          <label>Select Occupant <span class="required">*</span></label>
          <ng-select [items]="ownerList" formControlName="ocupantName" id="ocupantName" name="ocupantName"
            (change)="valueChange_Occupant($event)" [multiple]="false" bindLabel="name" bindValue="id"
            [placeholder]="'Select...'" [clearable]="false"></ng-select>
          <!--  <ng-select [items]="ocupanyList" formControlName="ocupantName" id="ocupantName" name="ocupantName"
            (change)="valueChange_Occupant($event)" [multiple]="false" bindLabel="name" bindValue="id"
            [placeholder]="'Select...'"></ng-select> -->
          @if(occupantSubmitted && occpantControls['ocupantName'].errors){
          <div class="invalid-feedback">
            @if(occpantControls['ocupantName'].errors['required']){
            <div>{{prifileValidations.residentialUnit.occupantDropdown}}</div>}
          </div>}
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-3">
          <label>Move In Date <span class="required">*</span></label>
          <input type="text" class="form-control date" formControlName="move_In_Date" placeholder="Select Date"
            name="singleDate" autocomplete="off" (bsValueChange)="onDateRangeSelected($event)" bsDatepicker [bsConfig]="{
              isAnimated: true, 
              dateInputFormat: 'DD/MM/YYYY',
              minDate: minDate, 
              maxDate:today,
              showWeekNumbers: false,
            }" />
          @if(occupantSubmitted && occpantControls['move_In_Date'].errors){
          <div class="invalid-feedback">
            @if(occpantControls['move_In_Date'].errors['required']){
            <div>{{prifileValidations.residentialUnit.move_in_date}}</div>}

          </div>}

          <!-- <input type="text" class="form-control date" placeholder="Select Date" name="singleDate" autocomplete="off"
            bsDatepicker [bsConfig]="datePickerConfig" [(bsValue)]="filterProjectBillDates"
            formControlName="move_In_Date" /> -->

          <!-- <input class="form-control" type="date" [attr.min]="minDate" [attr.max]="today"
            formControlName="move_In_Date"> -->



        </div>
      </div>



    </form>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="saveOcupant()"
      [disabled]="ocupantbuttonDisabled">Save</button>
  </div>
  <!-- </div>
    </div>
  </div> -->
</ng-template>
<!-- Add Occupants modal End  -->




<ng-template #removeOccupaint>
  <div class="modal-header ">
    <h5 class="modal-title">Remove Occupants</h5>
    <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
      (click)="removeModalRef.hide()"></button>
  </div>
  <div class="modal-body">
    <div class="col-lg-6">
      <div class="mb-3">
        <label>Vacant Date</label>
        <input class="form-control" type="date" [(ngModel)]="occupaintvacantdate" (change)="vacantdateChange($event)">
        <!--  @if(occupantSubmitted && occpantControls['Vacant_Date'].errors){
        <div class="invalid-feedback">
          @if(occpantControls['Vacant_Date'].errors['required']){
          <div>{{prifileValidations.residentialUnit.VacateDate}}</div>}
        </div>} -->

        @if(occupaintvacantdate){<span class="invalid-feedback">{{residenatilUnitByidData?.occupant}} vacated
          {{occupaintvacantdate|date}}</span>}
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="removeOcupant()"
      [disabled]="!occupaintvacantdate">Save</button>
  </div>

</ng-template>


<ng-template #addUmd>
  <div class="modal-header ">
    <h5 class="modal-title">Add UMD</h5>
    <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
      (click)="modelRef_1.hide()"></button>
  </div>
  <div class="modal-body">

    <form class="form-horizontal" [formGroup]="umdForm">
      <div class="mb-3">
        <label> Utility Type<span class="required">*</span></label>
        <ng-select [items]="utilityTypeList" formControlName="utitlityType" [(ngModel)]="selectedUtilityType"
          (change)="utilityChange($event)" [multiple]="false" [placeholder]="'Select...'"></ng-select>
        @if(umdSubmit && umdFormControls['utitlityType'].errors){
        <div class="invalid-feedback">
          @if(umdFormControls['utitlityType'].errors['required']){
          <div>{{prifileValidations.residentialUnit.utilityType}}</div>}
        </div>}
      </div>
      <div class="mb-3">
        <label>Utility Meter<span class="required">*</span></label>
        <ng-select [items]="uitilityMetersList" formControlName="utitlityMeters" (change)="utilityMeterChange($event)"
          [(ngModel)]="selectedvlaue" [multiple]="true" bindLabel="macAddress" bindValue="id"
          [placeholder]="'Select...'"></ng-select>
        @if(umdSubmit && umdFormControls['utitlityMeters'].errors){
        <div class="invalid-feedback">
          @if(umdFormControls['utitlityMeters'].errors['required']){
          <div>{{prifileValidations.residentialUnit.utilitymeters}}</div>}
        </div>}
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="saveUmd()">Save</button>
  </div>

</ng-template>



<!-- Add UMD modal End  -->