<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>

                </span>

            </div>
        </div>
    </div>

    <!--  <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <div class="page-title-right">
                    <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                </div>
            </div>
        </div>
    </div>
 -->
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ng-template #template let-anchor>
                        <span>{{ anchor.nativeElement.innerText }}</span>
                    </ng-template>

                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                        (mouseover)="showTooltip($event)">
                        <kendo-grid [data]="walletgridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                            [selectable]="selectableSettings" [pageable]="{
                                                                                    buttonCount: buttonCount,
                                                                                    info: info,
                                                                                    type: type,
                                                                                    pageSizes: pageSizes,
                                                                                    previousNext: previousNext
                                                                                  }" [reorderable]="true"
                            filterable="menu" [filter]="walletState.filter" [sort]="walletState.sort"
                            [columnMenu]="{ filter: true }" (filterChange)="onWalletFilterChange($event)"
                            (sortChange)="onSortChange($event)" [resizable]="true" scrollable="scrollable"
                            (pageChange)="walletConfigPageChange($event)" (selectionChange)="selectionChanged($event)"
                            (dataStateChange)="dataStateChange($event)">

                            <ng-template kendoGridToolbarTemplate>
                                <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                    [(ngModel)]="searchedValue"
                                    (valueChange)="onMeterConfigFilter($event)"></kendo-textbox> -->
                                <div class="search_icon">
                                    <kendo-textbox [style.width.px]="250" [clearButton]="true"
                                        (valueChange)="onValueChange($event)" (keydown.enter)="onSearchWallets()"
                                        [style.height.px]="35" [(ngModel)]="searchedValue"
                                        placeholder="Search in all columns...">
                                    </kendo-textbox>

                                    <button class="btn btn-primary btn-md border-left-none"
                                        (click)="onMeterConfigFilter($event)">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                                <kendo-grid-spacer></kendo-grid-spacer>
                                <div class="sm-device d-flex justify-content-between">

                                    <!-- <button kendoGridAddCommand class="btn btn-light me-2"
                                    (click)="onWalletPdfExport('Pdf')">
                                    <span class="fas fa-file-pdf "> </span> &nbsp; Export to PDF</button> -->
                                    <button class="btn btn-outline-primary btn-sm" (click)="onWalletPdfExport('Excel')">
                                        <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                    </button>
                                </div>

                            </ng-template>
                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                                class="no-tooltip"></kendo-grid-checkbox-column>
                            <kendo-grid-column field="consumer" title="Consumer" [width]="110">
                                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column">
                                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter">
                                    </kendo-grid-string-filter-menu>
                                    <small style="color:brown">Search is case-sensitive.</small>
                                </ng-template>

                            </kendo-grid-column>
                            <!-- <kendo-grid-column field="residentialUnit" title="Residential Unit"
                                [width]="80"></kendo-grid-column> -->
                            <kendo-grid-column field="currentBalance" title="Current Balance" filter="numeric"
                                [filterable]="true" [width]="130">

                                <!-- Define filter settings in the column definition -->
                                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                                    let-filterService="filterService">
                                    <kendo-grid-numeric-filter-menu [filter]="filter" [column]="column"
                                        [filterService]="filterService" [operators]="[
                                { text: 'Equal to', value: 'eq' },
                                { text: 'Greater than or equal to', value: 'gte' },
                                { text: 'Less than or equal to', value: 'lte' }
                              ]">
                                    </kendo-grid-numeric-filter-menu>
                                </ng-template>

                                <!-- Template to display the current balance with currency formatting -->
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ dataItem.currentBalance | currency: 'INR':'symbol':'1.2-2' }}
                                </ng-template>

                            </kendo-grid-column>



                            <kendo-grid-column field="lastRechargeAmount" title="Last Recharge Amount" filter="numeric"
                                [filterable]="true" [width]="130">

                                <!-- Define filter settings in the column definition -->
                                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                                    let-filterService="filterService">
                                    <kendo-grid-numeric-filter-menu [filter]="filter" [column]="column"
                                        [filterService]="filterService" [operators]="[
                                { text: 'Equal to', value: 'eq' },
                                { text: 'Greater than or equal to', value: 'gte' },
                                { text: 'Less than or equal to', value: 'lte' }
                              ]">
                                    </kendo-grid-numeric-filter-menu>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ dataItem.lastRechargeAmount | currency: 'INR':'symbol':'1.2-2' }}
                                </ng-template>
                            </kendo-grid-column>


                            <kendo-grid-column field="previousBalance" title="Previous Balance" filter="numeric"
                                [filterable]="true" [width]="130">

                                <!-- Define filter settings in the column definition -->
                                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                                    let-filterService="filterService">
                                    <kendo-grid-numeric-filter-menu [filter]="filter" [column]="column"
                                        [filterService]="filterService" [operators]="[
                            { text: 'Equal to', value: 'eq' },
                            { text: 'Greater than or equal to', value: 'gte' },
                            { text: 'Less than or equal to', value: 'lte' }
                          ]">
                                    </kendo-grid-numeric-filter-menu>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ dataItem.previousBalance | currency: 'INR':'symbol':'1.2-2' }}
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column field="phoneNo" title="Phone Number" [width]="130">
                            </kendo-grid-column>


                            <kendo-grid-command-column title="" [columnMenu]="false" [width]="80">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <!-- Wallet View Icon (Clickable) -->
                                    <span kendoTooltip title="Go to Wallet View"
                                        (click)="navigateToWalletView(dataItem)" style="cursor: pointer;">
                                        <i class="fas fa-info-circle text-success"></i>
                                    </span>
                                </ng-template>
                            </kendo-grid-command-column>
                            <ng-template kendoGridDetailTemplate let-dataItem="dataItem">
                                <app-multiflats-expended
                                    [flatsArry]="dataItem.residentialUnits"></app-multiflats-expended>
                            </ng-template>

                        </kendo-grid>
                    </div>
                    <!--master download buttons start-->

                    <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                        <kendo-grid
                            [kendoGridBinding]="mySelectionWallet.length>0 ? mySelectionWallet:walletgridData.data"
                            #pdfGrid>
                            <kendo-grid-column field="multiflats" title="Residential Unit"></kendo-grid-column>
                            <kendo-grid-column field="consumer" title="Consumer"></kendo-grid-column>
                            <kendo-grid-column field="phoneNo" title="Phone Number" [width]="130">
                            </kendo-grid-column>
                            <kendo-grid-column field="previousBalance" title="Previous Balance (₹)" [width]="130">
                            </kendo-grid-column>
                            <kendo-grid-column field="lastRechargeAmount"
                                title="Last Recharge Amount(₹)"></kendo-grid-column>
                            <kendo-grid-column field="currentBalance" title="Current Balance (₹)"></kendo-grid-column>
                            <kendo-grid-pdf fileName="WalletList.pdf" [allPages]="true" paperSize="A4"
                                [landscape]="true"></kendo-grid-pdf>
                            <kendo-grid-excel fileName="WalletList.xlsx"></kendo-grid-excel>
                        </kendo-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>