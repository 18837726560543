<div class="container-fluid">

    <div class="row">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <!-- <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-0 font-size-18"> Consumption History </h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item active"><a href="javascript: void(0);"
                                [routerLink]="'/consumptionHistory'"> Consumption History</a>
                        </li>

                        <li class="breadcrumb-item"> Consumption History
                        </li>

                    </ol>
                </div>
            </div>
        </div> -->
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">

                    <div>
                        <accordion [closeOthers]="false" class="accordion custom-accordian" [isAnimated]="true">
                            <accordion-group *ngIf="consumption?.utility" [isOpen]="isAllOpen" heading="Meter Readings"
                                class="accordion-item mb-3 panel-open" #item1>
                                <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                    <i class="mdi font-size-24"
                                        [ngClass]="!item1?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                </span>

                                <div class="row">
                                    <div class="col-lg-3 mb-3">
                                        <label class="form-label">Initial Reading Date</label>
                                        <p>
                                            {{ consumption?.previousReadingDate ? (consumption?.previousReadingDate |
                                            date: 'dd MMM yyyy hh:mm a') : '-' }}
                                        </p>
                                    </div>
                                    <div class="col-lg-3 mb-3">
                                        <label class="form-label">Final Reading Date</label>
                                        <p>
                                            {{ consumption?.finalReadingDate ? (consumption?.finalReadingDate | date:
                                            'dd MMM yyyy hh:mm a') : '-' }}
                                        </p>
                                    </div>
                                    <div class="col-lg-3 mb-3">
                                        <label class="form-label">Utility</label>
                                        <p>{{consumption?.utility ? consumption?.utility : '-'}}</p>
                                    </div>
                                    <div class="col-lg-3 mb-3">
                                        <label class="form-label">Meter Number</label>
                                        <p>{{consumption?.macAddress != '' ? consumption?.macAddress : '-'}}</p>
                                    </div>

                                    <div class="col-lg-3 mb-3"
                                        *ngIf="consumption?.utility == 'Water' || consumption?.utility =='Gas'">
                                        <label class="form-label">Initial Reading</label>

                                        <p>{{consumption?.initialMeterReading!= null ?
                                            consumption?.utility == 'Water' ? consumption?.initialMeterReading +" L":
                                            consumption?.utility == 'Gas' ? consumption?.initialMeterReading +" m³"
                                            :'':'-'
                                            }}</p>:
                                    </div>
                                    <div class="col-lg-3 mb-3" *ngIf="consumption?.utility == 'TriSource'">
                                        <label class="form-label">Initial Reading Solar</label>
                                        <p>{{consumption?.initialMeterReadingSolar != undefined ?
                                            consumption?.initialMeterReadingSolar +' kWh' :
                                            '-'}}</p>
                                    </div>
                                    <div class="col-lg-3 mb-3"
                                        *ngIf="consumption?.utility == 'Electricity' || consumption?.utility == 'TriSource'">
                                        <label class="form-label">Initial Reading EB</label>
                                        <p>{{consumption?.initialMeterReadingEB != undefined ?
                                            consumption?.initialMeterReadingEB +' kWh' :
                                            '-'}}</p>
                                    </div>


                                    <div class="col-lg-3 mb-3"
                                        *ngIf="consumption?.utility == 'Electricity' || consumption?.utility == 'TriSource'">
                                        <label class="form-label">Initial Reading DG</label>
                                        <p>{{consumption?.initialMeterReadingDG != undefined ?
                                            consumption?.initialMeterReadingDG +'
                                            kWh' :
                                            '-'}}</p>
                                    </div>







                                    <div class="col-lg-3 mb-3"
                                        *ngIf="consumption?.utility == 'Electricity' || consumption?.utility == 'TriSource'">
                                        <label class="form-label">Consumption Units EB</label>
                                        <p>{{consumption?.consumptionUnitsEB
                                            != null? consumption?.consumptionUnitsEB

                                            +' kWh'
                                            : '-'}}
                                        </p>
                                    </div>

                                    <div class="col-lg-3 mb-3"
                                        *ngIf="consumption?.utility == 'Electricity' || consumption?.utility == 'TriSource'">
                                        <label class="form-label">Consumption Units DG</label>
                                        <p>{{consumption?.consumptionUnitsDG
                                            != null? consumption?.consumptionUnitsDG

                                            +' kWh'
                                            : '-'}}
                                        </p>
                                    </div>
                                    <div class="col-lg-3 mb-3" *ngIf="consumption?.utility == 'TriSource'">
                                        <label class="form-label">Consumption Units Solar</label>
                                        <p>{{consumption?.consumptionUnitsSolar
                                            != null? consumption?.consumptionUnitsSolar

                                            +' kWh'
                                            : '-'}}
                                        </p>
                                    </div>
                                    <!-- <div class="col-lg-3 mb-3"
                                        *ngIf="consumption?.utility == 'Electricity' || consumption?.utility == 'TriSource'">
                                        <label class="form-label"></label>
                                        <p>
                                        </p>
                                    </div> -->
                                    <div class="col-lg-3 mb-3" *ngIf="consumption?.utility == 'TriSource'">
                                        <label class="form-label">Final Reading Solar</label>
                                        <p>{{consumption?.finalMeterReadingSolar != undefined?
                                            consumption?.finalMeterReadingSolar
                                            +' kWh'
                                            : '-'}}
                                        </p>
                                    </div>
                                    <div class="col-lg-3 mb-3"
                                        *ngIf="consumption?.utility == 'Electricity' || consumption?.utility == 'TriSource'">
                                        <label class="form-label">Final Reading EB</label>
                                        <p>{{consumption?.finalMeterReadingEB != undefined ?
                                            consumption?.finalMeterReadingEB
                                            +' kWh'
                                            : '-'}}
                                        </p>
                                    </div>

                                    <div class="col-lg-3 mb-3"
                                        *ngIf="consumption?.utility == 'Electricity' || consumption?.utility == 'TriSource'">
                                        <label class="form-label">Final Reading DG</label>
                                        <p>{{consumption?.finalMeterReadingDG != undefined ?
                                            consumption?.finalMeterReadingDG +'
                                            kWh' :
                                            '-'}}</p>
                                    </div>

                                    <div class="col-lg-3 mb-3"
                                        *ngIf="consumption?.utility == 'Gas' || consumption?.utility == 'Water'">
                                        <label class="form-label">Final Reading</label>

                                        <p>{{consumption?.finalMeterReading!= null ?
                                            consumption?.utility == 'Water' ? consumption?.finalMeterReading +" L":
                                            consumption?.utility == 'Gas' ? consumption?.finalMeterReading +" m³" :
                                            consumption?.utility == 'Electricity' ? consumption?.finalMeterReading +'
                                            kWh': consumption?.utility == 'TriSource' ? consumption?.finalMeterReading
                                            +'
                                            kWh':'':'-'

                                            }}</p>
                                    </div>
                                    <div class="col-lg-3 mb-3" *ngIf="consumption?.utility == 'Electricity'">
                                        <label class="form-label">Final Reading DG</label>
                                        <p>{{consumption?.finalMeterReadingDG != undefined?
                                            consumption?.finalMeterReadingDG
                                            +' kWh'
                                            : '-'}}
                                        </p>
                                    </div>


                                    <div class="col-lg-3 mb-3"
                                        *ngIf="consumption?.utility == 'Water' || consumption?.utility =='Gas'">
                                        <label class="form-label">Consumption
                                        </label>
                                        <p>{{consumption?.consumptionUnits!= undefined ?
                                            consumption?.utility == 'Water' ? consumption?.consumptionUnits +" L" +" /"
                                            + consumption?.consumptionInCubicLtr +' m³':
                                            consumption?.utility == 'Gas' ? consumption?.consumptionUnits +" m³" :
                                            '':
                                            '-'}}</p>
                                    </div>

                                    <!-- <div class="col-lg-3 mb-3" *ngIf="consumption?.utility == 'Electricity'">
                                        <label class="form-label">Consumption Units DG</label>
                                        <p>{{consumption?.consumptionUnitsDG != undefined?
                                            consumption?.consumptionUnitsDG
                                            +' kWh'
                                            : '-'}}
                                        </p>
                                    </div> -->

                                    <!-- Conditionally Render Fields Based on Utility Type -->
                                    <!-- <div class="col-lg-3 mb-3"
                                        *ngIf="consumption?.utility == 'Water' || consumption?.utility == 'Gas'">
                                        <label class="form-label">Final Reading</label>
                                        <p>{{consumption?.finalMeterReading ? consumption?.finalMeterReading : '-'}}</p>
                                    </div>

                                    <div class="col-lg-3 mb-3"
                                        *ngIf="consumption?.isEB && consumption?.utility == 'Electricity'">
                                        <label class="form-label">Initial Reading EB</label>
                                        <p>{{consumption?.initialMeterReadingEB ? consumption?.initialMeterReadingEB :
                                            '-'}}</p>
                                    </div>
                                    <div class="col-lg-3 mb-3"
                                        *ngIf="!consumption?.isEB && consumption?.utility == 'Electricity'">
                                        <label class="form-label">Initial Reading DG</label>
                                        <p>{{consumption?.initialMeterReadingDG ? consumption?.initialMeterReadingDG :
                                            '-'}}</p>
                                    </div>
                                    <div class="col-lg-3 mb-3"
                                        *ngIf="consumption?.isEB && consumption?.utility == 'Electricity'">
                                        <label class="form-label">Final Reading EB</label>
                                        <p>{{consumption?.finalMeterReadingEB ? consumption?.finalMeterReadingEB : '-'}}
                                        </p>
                                    </div>
                                    <div class="col-lg-3 mb-3"
                                        *ngIf="!consumption?.isEB && consumption?.utility == 'Electricity'">
                                        <label class="form-label">Final Reading DG</label>
                                        <p>{{consumption?.finalMeterReadingDG ? consumption?.finalMeterReadingDG : '-'}}
                                        </p>
                                    </div> -->
                                </div>


                            </accordion-group>
                            <accordion-group *ngIf="consumption?.otherCharges?.length>0" [isOpen]="isotherChargesOpen"
                                heading="General Charges" class="accordion-item mb-3 panel-open" #item2>
                                <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                    <i class="mdi font-size-24"
                                        [ngClass]="!item2?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                </span>

                                <div class="row">

                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Amount</th>
                                                <th>GST (%)</th>
                                                <th>GST Amount</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of consumption?.otherCharges">
                                                <td>{{item.name?item.name:'N/A'}}</td>
                                                <td>{{item?.amount?item?.amount:'N/A'}}</td>
                                                <td>{{item.gst ? item.gst : 'N/A'}}</td>
                                                <td>{{item.gstAmt?item.gstAmt:'N/A'}}</td>
                                                <td>{{item.total?item.gstAmt:'N/A'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <!-- <div class="mb-3 col-lg-4" *ngFor="let col of consumption?.otherCharges">
                                        <label for="">{{col.name}}</label>
                                        <p>{{ col.total| currency: 'INR':'symbol':'1.2-2' }} </p>

                                    </div> -->
                                </div>

                            </accordion-group>

                            <accordion-group *ngIf="consumption?.otherCharges?.length==0 &&consumption?.serviceName"
                                [isOpen]="isotherChargesOpen" heading="Other Charges"
                                class="accordion-item mb-3 panel-open" #item2>
                                <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                    <i class="mdi font-size-24"
                                        [ngClass]="!item2?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                </span>

                                <div class="row">

                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Amount</th>
                                                <th>GST (%)</th>
                                                <th>GST Amount</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{consumption?.serviceName??'N/A'}}</td>
                                                <td>{{consumption?.amount??'N/A'}}</td>
                                                <td>{{consumption?.gst ??'N/A'}}</td>
                                                <td>{{consumption?.gstAmount??'N/A'}}</td>
                                                <td>{{consumption?.totalAmount??'N/A'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </accordion-group>

                            <accordion-group [isOpen]="isAllOpen" heading="Payment Information"
                                class="accordion-item mb-3 panel-open" #item3>
                                <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                    <i class="mdi font-size-24"
                                        [ngClass]="!item3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                                </span>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-3 mb-3"
                                                    *ngIf="consumption?.utility == 'Gas' || consumption?.utility == 'Water' ">
                                                    <div class="mb-3">
                                                        <label class="form-label">Unit Rate</label>
                                                        <p>{{consumption?.unitRate !=undefined?(consumption?.unitRate
                                                            |currency:'INR':true):'N/A'}}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-3  mb-3"
                                                    *ngIf="consumption?.utility == 'Electricity'">
                                                    <div class="mb-3">
                                                        <label class="form-label">Unit Rate EB</label>
                                                        <p>{{consumption?.unitRateEB
                                                            !=undefined?(consumption?.unitRateEB
                                                            |currency:'INR':true):'0'}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 mb-3"
                                                    *ngIf="consumption?.utility == 'Electricity'">
                                                    <div class="mb-3">
                                                        <label class="form-label">Unit Rate DG</label>
                                                        <p>{{consumption?.unitRateDG != undefined?
                                                            (consumption?.unitRateDG
                                                            |currency:'INR':true):'0'}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <!--  <div class="col-lg-3  mb-3"
                                                    *ngIf="consumption?.utility == 'Electricity'">
                                                    <div class="mb-3">
                                                        <label class="form-label">Amount EB</label>
                                                        <p>{{consumption?.billAmountEB !=undefined
                                                            ?(consumption?.billAmountEB
                                                            |currency:'INR':true):'0'}}
                                                        </p>
                                                    </div>
                                                </div> -->

                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Amount Deducted</label>
                                                        <p>{{consumption?.amountDeducted!=undefined?
                                                            (consumption?.amountDeducted
                                                            |currency:'INR':true):'0'}}</p>

                                                    </div>
                                                </div>
                                                <div class="col-lg-3  mb-3">
                                                    <div class="mb-3">
                                                        <label class="form-label">Available Balance </label>
                                                        <p>{{consumption?.availableBalance!=undefined?
                                                            (consumption?.availableBalance
                                                            |currency:'INR':true):'0'}}</p>

                                                    </div>
                                                </div>

                                                <!-- <div class="col-lg-3 mb-3"
                                                    *ngIf="consumption?.utility == 'Electricity'">
                                                    <div class="mb-3">
                                                        <label class="form-label">Amount DG</label>
                                                        <p>{{consumption?.billAmountDG
                                                            !=undefined?(consumption?.billAmountDG
                                                            |currency:'INR':true):'0'}}
                                                        </p>
                                                    </div>
                                                </div> -->





                                            </div>

                                        </form>
                                    </div>
                                </div>

                            </accordion-group>



                        </accordion>

                    </div>

                    <div *ngIf="(consumption?.utility=='Gas')&&(consumption?.initialMeterReading == undefined || consumption?.initialMeterReading == null || consumption?.initialMeterReading == '') && (consumption?.finalMeterReading == undefined || consumption?.finalMeterReading == null || consumption?.finalMeterReading == '')"
                        class="note">
                        <strong>Note:</strong>Gas Meter is Not Communicating
                    </div>

                    <div *ngIf="(consumption?.utility=='Electricity')&&(consumption?.initialMeterReadingDG == undefined || consumption?.initialMeterReadingDG == null || consumption?.initialMeterReadingDG == '') && (consumption?.finalMeterReadingDG == undefined || consumption?.finalMeterReadingDG == null || consumption?.finalMeterReadingDG == '')&&(consumption?.initialMeterReadingEB == undefined || consumption?.initialMeterReadingEB == null || consumption?.initialMeterReadingEB == '') && (consumption?.finalMeterReadingEB == undefined || consumption?.finalMeterReadingEB == null || consumption?.finalMeterReadingEB == '')"
                        class="note">
                        <strong>Note:</strong>Electricity Meter is Not Communicating
                    </div>


                </div>
            </div>
        </div>
    </div>





</div>