import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { GetAllGasRateData, GetElectricityRateData, GetGasRateData, GetWaterRateData } from '../../../store/Utilities/utilities.actions';
import { getAllGasRateDataResponse, getElectricityRateDataResponse, getGasRateDataResponse, getWaterRateDataResponse } from '../../../store/Utilities/utilities.selector';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { error } from 'console';
import { PdfexportService } from '../../../core/services/pdfexport.service';

import { eleGridCoumns, gasGridCoumns, SweetAlertIcon, UtilityType, wterGridCoumns } from '../../../shared/constants/enum';
import { SharedService } from '../../../shared/shared.service';
import { UmdService } from '../../../core/services/umd.services';
import { LoaderService } from '../../../core/services/loader.service';
@Component({
  selector: 'app-rp-histoty-unit-rate',
  templateUrl: './rp-histoty-unit-rate.component.html',
  styleUrl: './rp-histoty-unit-rate.component.scss'
})
export class RpHistotyUnitRateComponent {
  breadCrumbItems: Array<{}> | undefined;
  public gasPageSize = 10;
  public waterPageSize = 10;
  public electricPageSize = 10;
  public skip = 0;
  public waterskip = 0;
  public electricskip = 0;
  public evskip = 0;
  totalGasCount: number = 0;
  totalWaterCount: number = 0;
  totalElectricCount: number = 0;
  currentPage: number = 1;
  waterCurrentPage: number = 1;
  electricCurrentPage: number = 1;
  evCurrentPage: number = 1;
  projectId: any;
  currentUser: any;
  currentprojectUtilities: any[] = [];
  gasDataList: any[] = [];
  waterDataList: any[] = [];
  electricDataList: any[] = [];

  gasSearchedValue: any;
  gasGridCoumns = gasGridCoumns;
  waterGriCdoumns = wterGridCoumns
  eleGridCoumns = eleGridCoumns
  gridColumns: any[] = []

  selectedUtility: string = '';


  constructor(private store: Store, private cd: ChangeDetectorRef, private umdService: UmdService, private loaderService: LoaderService,
    private PdfexportService: PdfexportService, private sharedService: SharedService,) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.currentprojectUtilities = this.currentUser.projectUtilities;
    this.projectId = this.currentUser?.project.id;
    this.selectedUtility = this.currentprojectUtilities[0]?.name

    this.getStoreData();
  }

  ngOnInit(): void {
    this.trigerApiByUtility(this.currentprojectUtilities[0]?.name);
    this.breadCrumbItems = [{ label: 'Unit Rate History' }];
  }
  utilityType = UtilityType;

  trigerApiByUtility(utility: any) {
    this.gridColumns = []
    console.log(this.selectedUtility)
    const utilityMasterId = this.currentprojectUtilities.find((u: any) => u?.name === utility)?.id;
    //  console.log(this.selectedUtility)
    //  console.log(utilityMasterId)
    switch (utility) {
      case this.utilityType.GAS:
        this.gridColumns = this.gasGridCoumns;
        console.log("Gas-------->", this.selectedUtility)
        this.store.dispatch(
          GetGasRateData({
            pageIndex: 1,
            pageSize: this.gasPageSize,
            searchTerm: '',
            filter: '',
            orderBy: '',
            projectId: this.projectId,
            utilityMasterId: utilityMasterId,
          })
        );
        break;

      // Add other cases here as needed
      case this.utilityType.ELECTRICITY:
        this.gridColumns = this.eleGridCoumns;
        console.log("Ele-------->", this.selectedUtility)
        this.store.dispatch(
          GetElectricityRateData({
            pageIndex: 1,
            pageSize: this.electricPageSize,
            searchTerm: '',
            filter: '',
            orderBy: '',
            projectId: this.projectId,
            utilityMasterId: utilityMasterId,
          })
        );
        break;

      case this.utilityType.WATER:
        this.gridColumns = this.waterGriCdoumns;
        console.log("Water-------->", this.selectedUtility)
        this.store.dispatch(
          GetWaterRateData({
            pageIndex: 1,
            pageSize: this.waterPageSize,
            searchTerm: '',
            filter: '',
            orderBy: '',
            projectId: this.projectId,
            utilityMasterId: utilityMasterId,
          })
        );
        break;

      case this.utilityType.TriSource:
        this.gridColumns = this.eleGridCoumns;
        console.log("tri-------->", this.selectedUtility)
        this.store.dispatch(
          GetElectricityRateData({
            pageIndex: 1,
            pageSize: this.electricPageSize,
            searchTerm: '',
            filter: '',
            orderBy: '',
            projectId: this.projectId,
            utilityMasterId: utilityMasterId,
          })
        );
        break;
      default:
        break
    }

  }
  selectedTabIdex: number = 0
  onStepChange(event: any) {
    this.selectedTabIdex = event.selectedIndex
    console.log(event.selectedIndex);
    //let index = this.currentprojectUtilities.findIndex((u: any) => u?.name === this.selectedUtility);
    //console.log(index)
    console.log(this.currentprojectUtilities[event.selectedIndex].name)
    this.selectedUtility = this.currentprojectUtilities[event.selectedIndex].name;
    console.log('selectedUtilityt', this.selectedUtility)
    this.trigerApiByUtility(this.currentprojectUtilities[event.selectedIndex].name)
    this.cd.detectChanges();
  }

  private getGasRateDataResponse$: Subscription = new Subscription();
  private getElectricityRateDataResponse$: Subscription = new Subscription();
  private getWaterRateDataResponse$: Subscription = new Subscription();
  private exportSubscription: Subscription = new Subscription();
  allMeterconfigList: any = [];
  ngOnDestroy() {
    this.getGasRateDataResponse$.unsubscribe()
    this.getElectricityRateDataResponse$.unsubscribe()
    this.getWaterRateDataResponse$.unsubscribe()
  }

  getStoreData() {

    this.getWaterRateDataResponse$ = this.store.pipe(select(getWaterRateDataResponse)).subscribe({

      next: (res: any) => {
        if (res) {
          this.gasDataList = [];
          this.gasDataList = [...res.items]
          this.gasPageSize = res.pageSize;
          this.totalGasCount = res.totalCount;
        }

      }, error: (error) => {
        console.error('Error occurred:', error);
      }

    })

    this.getElectricityRateDataResponse$ = this.store.pipe(select(getElectricityRateDataResponse)).subscribe({

      next: (res: any) => {
        if (res) {
          this.gasDataList = [];
          this.gasDataList = [...res.items]
          this.gasPageSize = res.pageSize;
          this.totalGasCount = res.totalCount;
        }

      }, error: (error) => {
        console.error('Error occurred:', error);
      }

    })


    this.getGasRateDataResponse$ = this.store.pipe(select(getGasRateDataResponse)).subscribe({
      next: (res: any) => {
        if (res) {
          // Mapping the gas data list and formatting dates
          this.gasDataList = res.items.map((project: { fromDate: string | number | Date; toDate: string | number | Date; }) => {
            const fromFormatted = new Date(project.fromDate).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric'
            });

            const toFormatted = project.toDate ? new Date(project.toDate).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric'
            }) : 'Till now';

            return {
              ...project,
              toFormatted,
              from_and_toDate: project.toDate
                ? `${fromFormatted} - ${toFormatted}`
                : `${fromFormatted} - Till now`
            };
          });

          // Set other properties after processing the response
          this.gasPageSize = res.pageSize;
          this.totalGasCount = res.totalCount;

          console.log('MetersConfiguration', res);
        }
      },
      error: (err) => {
        // Optionally handle loading state here (e.g. hide a loading indicator)
        console.error('Error occurred:', err); // Log any errors
      }
    });
  }

  exportAll() {
    // console.log(this.currentprojectUtilities[this.selectedTabIdex].name)
    this.selectedUtility = this.currentprojectUtilities[this.selectedTabIdex].name;
    console.log('selectedUtilityt', this.selectedUtility)
    this.exprtExceltrigerApiByUtility(this.currentprojectUtilities[this.selectedTabIdex].name)
    //this.cd.detectChanges();

  }

  exprtExceltrigerApiByUtility(utility: any) {

    this.loaderService.setLoading(true)
    this.gridColumns = []
    console.log(this.selectedUtility)
    const utilityMasterId = this.currentprojectUtilities.find((u: any) => u?.name === utility)?.id;

    let request = {
      projectId: this.projectId,
      utilityMasterId: utilityMasterId,
      pageSize: undefined,
    }
    this.umdService.getutilitiesRateHistoryExportExcel(request).subscribe({
      next: (res) => {
        console.log(res)
        console.log(this.gasDataList);
        this.loaderService.setLoading(false);

        this.allMeterconfigList = [];
        if (res.data.items.length > 0) {
          this.allMeterconfigList = [...res.data.items];

          const transformedData = this.allMeterconfigList
            .map((item: any) => {
              const dataObj: any = {};
              // dataObj['utilityName'] = item.utilityName;
              if (
                item.utilityName !== null &&
                item.utilityName !== undefined
              ) {
                dataObj['Utility Name'] = item.utilityName;
              }
              if (
                item.slabName !== null &&
                item.slabName !== undefined &&
                item.slabName !== ''
              ) {
                dataObj['Slab Name'] = item.slabName;
              }

              if (item.maxValue !== null && item.maxValue !== undefined) {
                dataObj['Max Value'] = item.maxValue;
              }
              if (item.minValue !== null && item.minValue !== undefined) {
                dataObj['Min Value'] = item.minValue;
              }

              if (item.gasValue !== null && item.gasValue !== undefined && item.utilityName == 'Gas') {
                dataObj['Gas Value'] = item.gasValue;
              }

              if (item.unitLPGValue !== null && item.unitLPGValue !== undefined && item.utilityName == 'Gas') {
                dataObj['1 KG/ LPG'] = item.unitLPGValue;
              }

              if (item.fromDate && item.toDate) {
                // Both dates are present, format and combine them
                const fromDate = new Date(item.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
                const toDate = new Date(item.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
                dataObj['From Date and To Date'] = `${fromDate} - ${toDate}`;
              } else if (item.fromDate) {
                // Only fromDate is available, format and set it
                const fromDate = new Date(item.fromDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
                dataObj['From Date and To Date'] = `${fromDate} - Till now`;
              } else if (item.toDate) {
                // Only toDate is available, format and set it
                const toDate = new Date(item.toDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
                dataObj['From Date and To Date'] = `N/A - ${toDate}`;
              } else {
                // Both dates are missing
                dataObj['From Date and To Date'] = 'N/A - Till now';
              }
              if (item.unitPrice !== null && item.unitPrice !== undefined) {
                dataObj['Slab Per Unit Price'] = item.unitPrice;
              }

              // Return only if 'Project Name' exists
              return Object.keys(dataObj).length > 0 ? dataObj : null;
            })
            .filter((item: any) => item !== null); // Filter out any null entries

          this.allMeterconfigList = [];
          console.log(transformedData)
          // Check if there's data to export after filtering null values
          if (transformedData.length > 0) {
            this.PdfexportService.downloadExcel(
              transformedData,
              transformedData[0]['Utility Name']
            );
            this.loaderService.setLoading(false)
          } else {
            this.loaderService.setLoading(false)
            this.sharedService.showMessageDialog(
              'No valid data to export',
              '',
              SweetAlertIcon.INFO
            );
          }
        } else {
          this.loaderService.setLoading(false)
          this.sharedService.showMessageDialog(
            'No data to export',
            '',
            SweetAlertIcon.INFO
          );
        }
      }, error: (err) => {

        this.loaderService.setLoading(false)

      },
    })
  }













}
