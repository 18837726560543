import { Component } from '@angular/core';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../core/services/loader.service';

@Component({
  selector: 'app-rp-bills-invoice-view',
  templateUrl: './rp-bills-invoice-view.component.html',
  styleUrl: './rp-bills-invoice-view.component.scss'
})
export class RpBillsInvoiceViewComponent {
  billList: any[] = [];
  isAllOpen: boolean = true;
  breadCrumbItems: Array<{}> | undefined;
  totalAmountwithoutpenalty: number = 0;
  billGenerationType: any;
  currentUser: any
  constructor(public sharedService: SharedService, private loaderService: LoaderService,) {
    this.breadCrumbItems = [{ label: 'Invoice History', url: 'billInvoices', active: true }, { label: 'view Invoice bills', }];
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');

    this.billGenerationType = this.currentUser?.project.billGenerationType;
    if (history.state.billHostorydeatils != undefined && history.state.billHostorydeatils !== null) {
      this.billList.push(history.state.billHostorydeatils);
      console.log(this.billList)

      var gasbillAmount = 0;
      var waterbillAmount = 0;
      var elebillAmount = 0;


      this.billList.forEach((bill: any) => {

        const totalGeneralCharge = Number(bill.totalGeneralCharge) || 0;

        bill.gas.forEach((gas: any, index: number) => {
          gasbillAmount = Number(gas.invoiceAmount) || 0;

        })
        bill.water.forEach((water: any, index: number) => {
          waterbillAmount = Number(water?.invoiceAmount) || 0;

        })
        bill.electricity.forEach((ele: any, index: number) => {
          elebillAmount = Number(ele?.invoiceAmount) || 0;

        })



        this.totalAmountwithoutpenalty = totalGeneralCharge + gasbillAmount + waterbillAmount + elebillAmount;
        console.log(this.totalAmountwithoutpenalty)
      })


      // this.sharedService.setbilldeatilsById(this.billsDataById)
    }
  }

  DownloadInvoice(event: Event,) {
    event.stopPropagation();
    let temp = {
      id: this.billList[0].invoiceNo,
      isInvoice: true
    }

    this.sharedService.downloadPaymentReceipt(temp).subscribe({
      next: (blob: Blob) => {
        //  const contentDisposition = blob.type === 'application/pdf' ? `attachment; filename="${}"` : null;
        let filename = 'Invoice.pdf'; // Default filename

        // If content disposition is available, extract filename
        /* s */
        if (blob) {
          this.loaderService.setLoading(false);
          console.log('Binary data received:', blob);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

        } else {
          this.loaderService.setLoading(false);
          console.error('Blob is null');
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.error('Error occurred:', err);
      },
    });


  }

}
