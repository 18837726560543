import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/shared.service';
import { CurrencyPipe } from '@angular/common';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store, compose, select } from '@ngrx/store';
import { LoaderService } from '../../../core/services/loader.service';
import { Validations } from '../../../shared/constants/validations';
import { createBills, getBillReadingsByMonthAdhocList, getBillReadingsByMonthList, getBillReadingsInfoList, getResidentiallist, resetBillReadingsInfoState, resetbillsInfoState, resetbillsState } from '../../../store/Bills/bills.action';
import { getBillReadingsByMonthAdhocResponse, getBillReadingsByMonthResponse, getBillReadingsInfoResponse, getresidentialResponse } from '../../../store/Bills/bills-selector';
import { Subscription, take } from 'rxjs';
import { log } from 'console';
import Swal from 'sweetalert2';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { esDoLocale } from 'ngx-bootstrap/chronos';
@Component({
  selector: 'app-rp-create-bill',
  templateUrl: './rp-create-bill.component.html',
  styleUrl: './rp-create-bill.component.scss'
})
export class RpCreateBillComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig> | undefined;
  hidden!: boolean;
  billgeneratetype!: string;
  billsForm!: UntypedFormGroup;
  submitted: boolean = false;
  selectValue!: string[];
  configData: any;
  utilityList: any[] = [];
  currentUserData: any;
  projectId: any;
  isGasOpen: boolean = false;
  isWaterOpen: boolean = false;
  isEleOpen: boolean = false;
  prifileValidations = Validations;
  selectedUtilityType: any[] = [];
  selectedMeterNos: any[] = [];

  selectedUtilityType_ind: any;
  selectedMeterNos_ind: any
  billsData: any
  breadCrumbItems: Array<{}> | undefined;
  lengthOfgas = 'Gas';
  lengthOFwater = 'Water';
  lengthOfelectricity = 'Electricity';
  ismultiDropDown: boolean = false;
  currentMonth!: number;
  currentYear!: number;
  utilityTypeList: any[] = [];
  utilityMetersList: any[] = [];
  selectedConsumerId: any;
  selectedResidentialunit: any;
  selectedresidentilaUnitId: any;
  billformonthList: any = [] = []
  otherCharge: any[] = [];
  isdisabled: boolean = true;
  residentialUnitList: any = {}
  today: Date;
  previouesToday: Date
  currentUser: any;
  minDate: any;
  projectbillDate: any
  selectedDate: any;
  constructor(private currencyPipe: CurrencyPipe, private sharedService: SharedService, private formBuilder: UntypedFormBuilder, private loaderService: LoaderService,
    private router: Router,
    private store: Store) {
    this.datePickerConfig = {
      containerClass: 'theme-default', // Optional: Customize the theme
      rangeSeparator: ' to ',
      // minDate: this.getMinDate(),
      // maxDate: this.getcurentdateDisabled(),
      showWeekNumbers: false,
      // other configurations as needed
    };
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectbillDate = this.currentUser?.project.billDate;

    let givenDate = new Date();

    // Subtract one day
    let previousDate = new Date(givenDate);
    previousDate.setDate(givenDate.getDate() - 1);

    // Format the result to 'YYYY-MM-DD' format
    let vacateDate = previousDate.toISOString().split('T')[0];

    //console.log(vacateDate); // Outputs: 2024-08-22


    if (history.state.billsData != undefined && history.state.billsData !== null) {
      this.billsData = history.state.billsData;
      console.log(this.billsData);
      if (this.billsData.billType == 'adhoc') {
        this.breadCrumbItems = [{ label: 'Bills', url: 'bills', active: true }, { label: 'Create  Adhoc Bill', }];
      } else {
        this.breadCrumbItems = [{ label: 'Bills', url: 'bills', active: true }, { label: 'Create Manual  Bill', }];
      }

    }
    this.previouesToday = new Date();
    this.today = new Date(); // Get today's date
    this.today.setDate(this.today.getDate() - 1);
    // this.minDate = new Date(this.today.getFullYear(), this.today.getMonth() - 6, 1);
    this.datePickerConfig = Object.assign({}, {
      dateInputFormat: 'DD/MM/YYYY', // Set to dd/MM/yyyy format



    });


    this.billsForm = this.formBuilder.group({
      ResidentialUnit: [, [Validators.required]],
      consumer: ['', [Validators.required]],
      utilityType: [, [Validators.required]],
      MeterNumber: [, [Validators.required]],
      selectBillForMonth: [],
      //SelectDatesForBillGaneration: [],
      gas_array: this.formBuilder.array([]),
      water_array: this.formBuilder.array([]),
      electricity_array: this.formBuilder.array([]),
      totalAmount: ['', [Validators.required]],
      // billStatus: ['', [Validators.required]],
      LastMonthBillGeneratedDate: [],
      //  billGeneratedDate: ['', [Validators.required]],
      //  startdate: ['',],
      endDate: [''],
      // billedDays: ['', [Validators.required]],

    })
    this.getAllConfigData()
  }
  add_water_Row(meter: any, index: number): void {

    let row = this.formBuilder.group({
      unitRateWater: [{ value: this.currencyFormated(meter.unitRate), disabled: true }],
      // unitRateWater: [{ value: meter.unitRate, disabled: true }],
      type: [{ value: 'Water', disabled: true }], //'water'],
      MeterNo: [{ value: meter.deviceSRNo, disabled: true }],
      macAddress: [{ values: meter.macAddress, disabled: true }],
      previousMeterReadingWater: [{ value: meter.previousMeterReading, disabled: true }],
      currentMeterReadinWater: [{ value: meter.currentMeterReading, disabled: true }],
      noOfUnitsConsumedWater: [{ value: meter.noofUnitsConsumed, disabled: true }],
      billAmount: [{ value: this.currencyFormated(meter.billAmount), disabled: true }],
      //billAmount: [{ value: meter.billAmount, disabled: true }], //meter.billAmount],
      ///  totalAmount: [{ value: meter.totalAmount, disabled: true }], //meter.totalAmount],
      utilityMasterId: [{ value: meter.utilityMasterId, disabled: true }], //meter.utilityMasterId],
      otherCharges: [meter.otherCharges],
      utilityServiceCharges: [meter.utilityServiceCharges],
      totalServiceCharge: [meter.totalServiceCharge]

    });
    this.water_array.push(row);
    if (this.water_array.length > 0) {
      this.isWaterOpen = true
    }
    this.lengthOFwater = this.water_array.length > 0 ? `Water ( ${this.water_array.length})` : 'Water';

  }

  add_gas_Row(meter: any, index: number): void {
    let row = this.formBuilder.group({
      UnitRateGas: [{ value: this.currencyFormated(meter.unitRate), disabled: true }],
      type: [{ value: 'Gas', disabled: true }], //'Gas'],
      macAddress: [{ value: meter.deviceSRNo, disabled: true }], //meter.macAddress],
      previousMeterReadingGas: [{ value: meter.previousMeterReading, disabled: true }],
      componenurrentMeterReadingGas: [{ value: meter.currentMeterReading, disabled: true }],
      noOfUnitsConsumedGas: [{ value: meter.noofUnitsConsumed, disabled: true }],
      billAmount: [{ value: this.currencyFormated(meter.billAmount), disabled: true }],
      // billAmount: [{ value: meter.billAmount, disabled: true }],
      // totalAmount: [{ value: meter.totalAmount, disabled: true }], // [meter.totalAmount]
      utilityMasterId: [meter.utilityMasterId],
      otherCharges: [meter.otherCharges],
      utilityServiceCharges: [meter.utilityServiceCharges],
      totalServiceCharge: [meter.totalServiceCharge]

    });

    this.gas_array.push(row);
    if (this.gas_array.length > 0) {
      this.isGasOpen = true;
    }
    console.log(this.gas_array)

    this.lengthOfgas = `Gas ( ${this.gas_array.length})`


  }
  /*   getformControlKey(group: any,): { key: string, value: string }[] {
      
      return Object.keys(group.controls).map(key => ({ key, value: group.controls[key] }));
    } */
  add_electricity_Row(meter: any, index: number): void {

    let row = this.formBuilder.group({
      MeterNo: [{ value: meter.deviceSRNo, disabled: true }],

      // unitRateEB: [{ value: meter.unitRateEB, disabled: true }],
      //  unitRateDG: [{ value: meter.unitRateDG, disabled: true }],

      unitRateEB: [{ value: this.currencyFormated(meter.unitRateEB), disabled: true }],
      unitRateDG: [{ value: this.currencyFormated(meter.unitRateDG), disabled: true }],
      type: [{ value: 'Electricity', disabled: true }],
      previousMeterReadingEB: [{ value: '', disabled: true }],
      currentMeterReadingEB: [{ value: '', disabled: true }],
      noOfUnitsConsumedEB: [{ value: '', disabled: true }],
      previousMeterReadingDG: [{ value: '', disabled: true }],
      currentMeterReadingDG: [{ value: '', disabled: true }],
      noOfUnitsConsumedDG: [{ value: '', disabled: true }],
      billAmount: [{ value: this.currencyFormated(meter.billAmount), disabled: true }],

      //billAmount: [{ value: meter.billAmount, disabled: true }],
      // totalAmount: [{ value: meter.totalAmount, disabled: true }],
      utilityMasterId: [meter.utilityMasterId],
      otherCharges: [meter.otherCharges],
      utilityServiceCharges: [meter.utilityServiceCharges],
      totalServiceCharge: [meter.totalServiceCharge]
    });

    this.electricity_array.push(row);
    console.log("eletcricity->", this.electricity_array.value)
    //console.log("eletcricity->", this.electricity_array.value)
    this.lengthOfelectricity = `Electricity ( ${this.electricity_array.length})`
    if (this.electricity_array.length > 0) {
      this.isEleOpen = true
    }
  }

  get gas_array(): FormArray {
    return this.billsForm.get('gas_array') as FormArray
  }

  get water_array(): FormArray {
    return this.billsForm.get('water_array') as FormArray
  }

  get electricity_array(): FormArray {
    return this.billsForm.get('electricity_array') as FormArray
  }

  ngOnDestroy() {
    this.getBillingInfo.unsubscribe(); // Or set it to [] if you prefer an empty array
  }
  ngOnInit(): void {

    const currentUserString = localStorage.getItem('currentUser');
    if (this.billsData.billType == 'adhoc') {
      /*  this.billsControls['SelectDatesForBillGaneration'].setValidators(Validators.required);
       this.billsControls['SelectDatesForBillGaneration'].updateValueAndValidity(); */

    }
    else {
      this.billsControls['selectBillForMonth'].setValidators(Validators.required);
      this.billsControls['selectBillForMonth'].updateValueAndValidity();
      /*   this.billsControls['SelectDatesForBillGaneration'].clearValidators();
        this.billsControls['SelectDatesForBillGaneration'].updateValueAndValidity(); */
    }
    this.currentUserData = JSON.parse(currentUserString ?? '');
    if (this.currentUserData.project !== null) {
      this.projectId = this.currentUserData.project.id;
      this.billgeneratetype = this.currentUserData.project.billGenerationType;

      this.store.dispatch(
        getResidentiallist({ projectId: this.projectId, billType: this.billsData.billType })
      );
    }
    this.billsForm.controls['LastMonthBillGeneratedDate'].disable();
    // this.billsForm.controls['billGeneratedDate'].disable();
    //  this.billsForm.controls['startdate'].disable();



    this.billsForm.controls['totalAmount'].disable();
    //  this.billsForm.controls['billStatus'].disable();
    // this.billsForm.controls['billedDays'].disable();

    if (this.billgeneratetype == "Combined") {
      this.ismultiDropDown = true;
      this.billsForm.controls['consumer'].disable();
      this.billsForm.controls['utilityType'].disable();
      this.billsForm.controls['MeterNumber'].disable();
    } else {
      this.ismultiDropDown = false;
      this.billsForm.controls['consumer'].enable();
      this.billsForm.controls['utilityType'].enable();
      this.billsForm.controls['MeterNumber'].enable();
    }

  }



  getcurentdateDisabled() {
    const today = new Date();
    const previousDay = new Date(today);
    previousDay.setDate(today.getDate() - 1); // Subtract 1 day

    //console.log('Previous Day:', previousDay.toISOString().split('T')[0]);

    return this.formatDate(previousDay);
  }
  getMinDate(): any {
    const today = new Date();
    // const minDate = new Date(today.getFullYear(), today.getMonth() - 6, 1); // First day of the month, 6 months ago
    //  return this.formatDate(minDate);

  }
  getAllConfigData(): void {
    let getresidentionBills$ = this.store.pipe(select(getresidentialResponse));

    this.sharedService.getBillsAllConfigData().subscribe((data) => {
      if (data.statusCode == 200 && data.message == 'Config data retrieved successfully.') {
        this.configData = data.data;
        this.utilityList = this.configData.utilities;
        //  this.billformonthList = this.configData.months;

        /*  let date = new Date();
         this.currentMonth = date.getMonth();
         this.currentYear = date.getFullYear();
 
         // Get the current date and check if the current day is greater than or equal to 20
         // Get the current date and check if the current day is greater than or equal to 10
         const today = new Date();
         const currentDay = today.getDate(); // Get the current day (1-31)
         const currentMonth = today.getMonth(); // Get the current month (0-11)
 
         let startIndex: number;
         let endIndex: number;
 
         // Determine the start index based on the current day
         // logic condtion: Bill Date gater then or equl to current date the show current Month
         if (currentDay >= this.projectbillDate) {
           // If today is after the 10th, show the current month + previous 5 months
           startIndex = (currentMonth - 5 + 12) % 12;  // Start from 5 months before the current month
           endIndex = currentMonth;  // End at the current month
         } else {
           // If today is before the 10th, show the previous 6 months, excluding the current month
           startIndex = (currentMonth - 6 + 12) % 12;  // Start from 6 months before the current month
           endIndex = (currentMonth - 1 + 12) % 12;  // End at the previous month
         }
 
         this.billformonthList = this.billformonthList.map((p: any, index: number) => {
           // Enable the months from startIndex to endIndex
           if (
             (startIndex <= endIndex && index >= startIndex && index <= endIndex) ||
             (startIndex > endIndex && (index >= startIndex || index <= endIndex))
           ) {
             return { ...p, disabled: false };  // Enable these months
           } else {
             return { ...p, disabled: true };  // Disable other months
           }
         });
 
         console.log(this.billformonthList); */


      }
    })
    getresidentionBills$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.residentialUnitList = res;

      }
    })

  }

  isMonthDisabled(month: any): boolean {
    return month.id <= this.currentMonth;
  }
  get billsControls() {
    return this.billsForm.controls;
  }

  saveBills(): void {

    let dateStr = "nov-2004";
    let year = dateStr.split('-')[1];
    console.log("year", year);
    console.log("year", year);
    this.submitted = true;
    console.log(this.billsForm.controls)
    console.log(this.billsForm.get('LastMonthBillGeneratedDate')?.value)
    console.log(this.billsForm.invalid)
    // console.log(this.billsForm.getRawValue())
    if (this.billsForm.invalid) { return }
    else {

      console.log(this.billsForm.invalid)
      let dateStr = this.billsForm.get('LastMonthBillGeneratedDate')?.value;
      // Split the string into day, month, and year
      let [day, month, year] = dateStr.split(' ');

      // Prepend '20' to the year to make it a full 4-digit year
      let fullYear = '20' + year;

      // Create a Date object
      let formattedDate = new Date(`${fullYear}-${month}-${day}T00:00:00`);

      // Format it to ISO string in the format YYYY-MM-DDTHH:MM:SS
      let isoFormattedDate = formattedDate.toISOString().split('T')[0] + 'T00:00:00';

      console.log(isoFormattedDate);  // Ou
      var fromdate = this.billsForm.get('LastMonthBillGeneratedDate')?.value;
      console.log(fromdate);

      var todate = this.billsForm.get('endDate')?.value;

      console.log(isoFormattedDate);
      let request: any = {
        ProjectId: this.projectId,
        residentialUnitId: this.billsForm.get('ResidentialUnit')?.value,
        consumerId: this.selectedConsumerId,
        ConsumerName: this.billsForm.get('consumer')?.value,
        lastBillGeneratedDate: this.convertDateforGeneratedDate(),
        billGenerateFromDate: this.billsData.billType == 'adhoc' ? isoFormattedDate : null,
        billGenerateToDate: this.billsData.billType == 'adhoc' ? todate : null,
        BillYear: this.selectedMonth?.year,
        billMonth: this.selectedMonth?.name.slice(0, 3).toUpperCase(),
        billAmount: this.updateBillsbyResidentilauit.billAmount,
        generalCharges: this.updateBillsbyResidentilauit.additionalCharges,
        // billYear: this.currentYear,
        billType: this.billsData.billType,
        isCombinedBill: this.billgeneratetype == "Combined" ? true : false,
        totalAmount: this.billsForm.get('totalAmount')?.value,
        OtherCharges: this.generalCharges,

      }



      console.log("api request--->", request)
      // console.log("api request--->", this.selectedMonth)

      let electricity: any[] = [];
      let gas: any[] = [];
      let water: any[] = [];
      if (this.electricity_array.length > 0) {
        this.updateBillsbyResidentilauit.electricity.forEach((item: any) => {
          electricity.push({
            macAddress: item.macAddress,
            deviceSRNo: item.deviceSRNo,
            unitRateEB: item.unitRateEB,
            unitRateDG: item.unitRateDG,
            type: 'Electricity',
            utilityMasterId: item.utilityMasterId,
            electricityPreviousMeterReadingEB: item.electricityPreviousMeterReadingEB,
            electricityCurrentMeterReadingEB: item.electricityCurrentMeterReadingEB,
            noofElectricityUnitsConsumedEB: item.noofElectricityUnitsConsumedEB,
            electricityPreviousMeterReadingDG: item.noofElectricityUnitsConsumedEB,
            electricityCurrentMeterReadingDG: item.electricityCurrentMeterReadingDG,
            noofElectricityUnitsConsumedDG: item.noofElectricityUnitsConsumedDG,
            billAmount: item.billAmount == null ? 0 : item.billAmount,
            totalAmount: item.totalAmount == null ? 0 : item.totalAmount,
            previousReadingDate: item.previousReadingDate,
            currentReadingDate: item.currentReadingDate,
            utilityServiceCharges: item.utilityServiceCharges,

          })

        })
      }


      if (this.gas_array.length > 0) {
        //console.log(this.gas_array)
        this.updateBillsbyResidentilauit.gas.forEach((item: any) => {
          console.log(item)
          gas.push({
            unitRate: item.unitRate,
            type: 'Gas',
            deviceSRNo: item.deviceSRNo,
            macAddress: item.macAddress,
            previousMeterReading: item.previousMeterReading,
            currentMeterReading: item.currentMeterReading,
            noofUnitsConsumed: item.noofUnitsConsumed,
            utilityMasterId: item.utilityMasterId,
            billAmount: item.billAmount == null ? 0 : item.billAmount,
            totalAmount: item.totalAmount == null ? 0 : item.totalAmount,
            previousReadingDate: item.previousReadingDate,
            currentReadingDate: item.currentReadingDate,
            utilityServiceCharges: item.utilityServiceCharges,

          })

        })
      }

      if (this.water_array.length > 0) {
        this.updateBillsbyResidentilauit.water
          .forEach((item: any) => {
            water.push({
              unitRate: item.unitRate,
              type: 'Water',
              deviceSRNo: item.deviceSRNo,
              utilityMasterId: item.utilityMasterId,
              macAddress: item.macAddress,
              previousMeterReading: item.previousMeterReading,
              currentMeterReading: item.currentMeterReading,
              noofUnitsConsumed: item.noofUnitsConsumed,
              billAmount: item.billAmount == null ? 0 : item.billAmount,
              totalAmount: item.totalAmount == null ? 0 : item.totalAmount,
              previousReadingDate: item.previousReadingDate,
              currentReadingDate: item.currentReadingDate,
              utilityServiceCharges: item.utilityServiceCharges,
            })


          })
      } console.log("api request--->", request)
      request.gas = gas,
        request.water = water,
        request.electricity = electricity,
        this.store.dispatch(createBills({ bilss: request }));

    }

  }

  preventManualInput(event: KeyboardEvent): void {
    // Prevent all keys except Tab and Arrow keys, which are essential for navigating
    const allowedKeys = ['Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault(); // Prevent input
    }
  }
  onDateRangeSelected(event: any): void {
    console.log('Selected date:', event);
    this.selectedDate = event;
    console.log(this.selectedDate)

    // Handle the selected date here
  }



  convertDate() {
    let dateStr = this.billsForm.get('endDate')?.value;
    console.log(dateStr)
    // Split the string into day, month, and year
    let [day, month, year] = dateStr.split(' ');

    // Prepend '20' to the year to make it a full 4-digit year
    let fullYear = '20' + year;

    // Create a Date object
    let formattedDate = new Date(`${fullYear}-${month}-${day}T00:00:00`);

    // Format it to ISO string in the format YYYY-MM-DDTHH:MM:SS
    let isoFormattedDate = formattedDate.toISOString().split('T')[0] + 'T00:00:00';
    return isoFormattedDate
    console.log(isoFormattedDate);
  }
  convertDateforGeneratedDate() {
    let dateStr = this.billsForm.get('LastMonthBillGeneratedDate')?.value;
    console.log(dateStr)
    // Split the string into day, month, and year
    let [day, month, year] = dateStr.split(' ');

    // Prepend '20' to the year to make it a full 4-digit year
    let fullYear = '20' + year;

    // Create a Date object
    let formattedDate = new Date(`${fullYear}-${month}-${day}T00:00:00`);

    // Format it to ISO string in the format YYYY-MM-DDTHH:MM:SS
    let isoFormattedDate = formattedDate.toISOString().split('T')[0] + 'T00:00:00';
    return isoFormattedDate
    console.log(isoFormattedDate);
  }

  selectedMonth: any;
  Selectdates(event: any) {
    this.selectedDate = event;
    console.log("utilityTypeList-->", this.utilityTypeList)
    if (!isNaN(this.selectedDate?.getTime())) {
      this.billsControls['selectBillForMonth'].clearValidators();
      this.billsControls['selectBillForMonth'].updateValueAndValidity();
      var filterdata: any[] = []
      if (this.selectedresidentilaUnitId) {
        if (this.billgeneratetype == 'Individual') {
          filterdata = this.utilityMetersList.filter((item: any) => item.umdId == this.billsForm.get('MeterNumber')?.value);

          if (this.billsForm.get('MeterNumber')?.value == '' || this.billsForm.get('utilityType')?.value == '') {
            let message = this.billsForm.get('utilityType')?.value == 0 ? 'Select utlity ' : 'Select Meter Number';
            this.sharedService.showMessageDialog(message, '', SweetAlertIcon.ERROR);
            return;
          }
        } else {
          if (this.billsForm.get('utilityType')?.value.length == 0 || this.billsForm.get('MeterNumber')?.value.length == 0) {
            let message = this.billsForm.get('utilityType')?.value.length == 0 ? 'Select utlity ' : 'Select Meter Number';
            this.sharedService.showMessageDialog(message, '', SweetAlertIcon.ERROR);
            return;
          }
        }
        let MacAddress = this.billgeneratetype.toLowerCase() == 'individual' ? filterdata[0].macAddress : '';
        let request = {
          residentialUnitId: this.selectedresidentilaUnitId,
          projectId: this.projectId,
          macAddress: MacAddress,
          fromDate: this.LastMonthBillGeneratedDate, // Previous date from Api(Previous bill generated date)
          // toDate: input.value,
          toDate: this.selectedDate,
          isCombined: this.billgeneratetype == "Combined" ? true : false
        }
        this.store.dispatch(getBillReadingsByMonthAdhocList({ adhocBills: request }));
        this.bindBillinfoDetailsForAdhoc();
      } else {
        this.sharedService.showMessageDialog('Select Residential Unit', '', SweetAlertIcon.ERROR);
      }
    } else {
      // console.log("dsdsk")
    }


  }


  monthChange(event: any) {
    console.log("evemt-->", event)
    // console.log(this.selectedresidentilaUnitId)
    if (event !== undefined) {
      this.store.dispatch(resetBillReadingsInfoState());
      this.water_array.clear();
      this.gas_array.clear();
      this.electricity_array.clear();
      if (this.selectedresidentilaUnitId) {

        let MacAddress: any
        console.log("MeterNumber", this.billsForm.get('MeterNumber')?.value);
        console.log("MeterNumber", this.billsForm.get('utilityType')?.value);

        if (this.billgeneratetype == 'Individual') {
          MacAddress = this.utilityMetersList[0].macAddress;
          if (this.billsForm.get('MeterNumber')?.value == '' || this.billsForm.get('utilityType')?.value == '') {
            this.billsForm.controls['selectBillForMonth'].setValue('');
            let message = this.billsForm.get('utilityType')?.value == '' ? 'Select utlity ' : 'Select Meter Number';
            this.sharedService.showMessageDialog(message, '', SweetAlertIcon.ERROR);
            return;
          }
        } else {
          MacAddress = ''
          if (this.billsForm.get('utilityType')?.value.length == 0 || this.billsForm.get('MeterNumber')?.value.length == 0) {
            let message = this.billsForm.get('utilityType')?.value.length == 0 ? 'Select utlity ' : 'Select Meter Number';
            this.sharedService.showMessageDialog(message, '', SweetAlertIcon.ERROR);
            return;
          }
        }
        this.selectedMonth = event;
        let IsCombined = this.billgeneratetype == "Combined" ? true : false;
        this.store.dispatch(
          getBillReadingsByMonthList({ id: this.selectedresidentilaUnitId, projectId: this.projectId, BillMonth: this.selectedMonth?.name, MacAddress: MacAddress, IsCombined: IsCombined })
        );

        this.bindBillinfoDetailsForManual();

      } else {

        this.sharedService.showMessageDialog('Select Residential Unit', '', SweetAlertIcon.ERROR);
      }
    }

  }
  LastMonthBillGeneratedDate: any
  monthErrorMesg: string = '';
  async residentialUnitChange(event: any) {
    console.log(event)
    this.loaderService.setLoading(true)
    this.store.dispatch(resetBillReadingsInfoState()); // Optionally reset state
    this.selectedresidentilaUnitId = event?.residentialUnitId;
    console.log(this.selectedresidentilaUnitId)
    this.selectedUtilityType = [];


    if (event == undefined) {
      await this.resetCalculations(undefined);
      this.billsForm.controls['consumer'].setValue('');
      this.utilityTypeList = [];
      this.selectedUtilityType = [];
      this.loaderService.setLoading(false);
      this.isdisabled = true
      return
    }


    if (event?.consumerName === '' || event?.utilityData.length === 0 || event?.utilityMeters.length === 0) {
      console.log(this.billgeneratetype);

      let errorMessage = '';

      // Check if utilityData is empty
      if (event?.utilityData.length === 0) {

        this.billsForm.controls['utilityType'].setValue(null);
        errorMessage = `${event?.residentialUnit}: The utility was not found.`;
      }

      // Check if utilityMeters is empty
      if (event?.utilityMeters.length === 0) {
        this.billsForm.controls['MeterNumber'].setValue(null);
        errorMessage = `${event?.residentialUnit}: The meter was not found.`;
      }

      // Check if consumerName is empty
      if (event?.consumerName === '') {
        this.billsForm.controls['consumer'].setValue(null);
        errorMessage = 'Consumer Name was not found.';
      }

      // Show the error message only if it's set
      if (errorMessage) {
        this.sharedService.showMessageDialog('', errorMessage, SweetAlertIcon.ERROR);
      }



      /*  if (message) {
         this.sharedService.showMessageDialog('', message, SweetAlertIcon.ERROR);
       } */

      this.utilityMetersList = [];
      this.selectedMeterNos = [];
      this.utilityTypeList = [];
      this.selectedUtilityType = [];
      this.billsForm.controls['LastMonthBillGeneratedDate'].setValue(null);

      this.loaderService.setLoading(false)
    } else {
      if (this.selectedresidentilaUnitId) {
        await this.resetCalculations(undefined)
      }

      this.billformonthList = event.months.length > 0 ? event.months : [];
      this.monthErrorMesg = this.billformonthList.length > 0 ? '' : 'There are no invalid bills for the selected residential unit'

      this.billsForm.controls['consumer'].setValue(event.consumerName);
      this.billsForm.controls['ResidentialUnit'].setValue(event?.residentialUnitId);
      this.selectedConsumerId = event.consumerId;
      this.selectedResidentialunit = event;
      this.utilityTypeList = event.utilityData;
      console.log(this.utilityTypeList)
      this.utilityMetersList = event.utilityMeters;
      console.log(this.billgeneratetype)
      if (this.billgeneratetype == "Combined") {

        console.log(this.utilityMetersList)
        let date = this.formatDate(this.utilityMetersList[0].previousBillDate);
        let dt = this.convertDateFormat(date);

        this.billsForm.controls['LastMonthBillGeneratedDate'].setValue(dt);
        this.LastMonthBillGeneratedDate = this.utilityMetersList[0].previousBillDate
        this.minDate = new Date(date);
        this.minDate.setDate(this.minDate.getDate() + 1);

        /*  this.utilityTypeList.forEach((item: any) => {
           utlityArray.push(item.utilityId)
         })
         this.utilityMetersList.forEach((item: any) => {
           meter.push(item.umdId)
         }) */
        console.log(this.utilityTypeList)
        //  this.selectedMeterNos = meter;
        this.billsForm.patchValue({
          utilityType: this.utilityTypeList?.map(
            (item: any) => item.utilityId
          ),
          MeterNumber: this.utilityMetersList?.map(
            (item: any) => item.umdId
          )

        })
        //this.selectedUtilityType = utlityArray;
        this.loaderService.setLoading(false)

      } else {
        console.log(this.ismultiDropDown)
        console.log(this.utilityMetersList)
        let date = this.formatDate(this.utilityMetersList[0].previousBillDate);
        let dt = this.convertDateFormat(date);
        this.billsForm.controls['LastMonthBillGeneratedDate'].setValue(dt);
        this.minDate = new Date(date);
        this.LastMonthBillGeneratedDate = this.utilityMetersList[0].previousBillDate
        this.minDate.setDate(this.minDate.getDate() + 1);
        let utlityArray: any;
        let meter: any;

        this.utilityTypeList.forEach((item: any) => {
          utlityArray = {
            utilityId: item.utilityId,
            utilityName: item.utilityName
          }
        })
        this.utilityMetersList.forEach((item: any) => {
          meter = {
            umdId: item.umdId,
            macAddress: item.macAddress,
          }
        })
        this.selectedMeterNos_ind = meter.umdId;
        this.selectedUtilityType_ind = utlityArray.utilityId;
        this.billsForm.patchValue(
          {
            utilityType: utlityArray.utilityId,
            MeterNumber: meter?.umdId,
          })


        console.log("MeterNumber", this.billsForm.get('MeterNumber')?.value);
        console.log("MeterNumber", this.billsForm.get('MeterNumber')?.value);
        this.loaderService.setLoading(false)
      }
    }


  }
  convertDateFormat(date: string): string {
    const parts = date.split('-');
    return `${parts[2]} ${parts[1]} ${parts[0].slice(2)}`;  // returns 'dd MM yy'
  }

  isDisabled(date: Date): boolean {
    // Check if the selected date is greater than today
    if (date > this.today) {
      console.log('Selected date is greater than today, enabling...');
      return false;  // Enable the date
    }

    // If the selected date is the same as today, disable all dates
    if (this.isSameDate(date, this.today)) {
      console.log('Selected date is today, disabling all dates...');
      return true;  // Disable all dates
    }

    // Otherwise, allow normal behavior (disable nothing)
    return false;
  }
  isSameDate(date1: Date, date2: Date): boolean {
    return date1.getUTCFullYear() === date2.getUTCFullYear() &&
      date1.getUTCMonth() === date2.getUTCMonth() &&
      date1.getUTCDate() === date2.getUTCDate();
  }

  resetCalculations(event: any) {
    this.selectedMeterNos = [];
    this.selectedUtilityType_ind = '';
    this.selectedMeterNos_ind = '';
    this.billsForm.controls['selectBillForMonth'].setValue('');
    this.billsForm.controls['totalAmount'].setValue('');
    //  this.billsForm.controls['billStatus'].setValue('');
    if (this.billsData.billType == 'adhoc') {
      // this.billsForm.controls['startdate'].setValue('');
      this.billsForm.controls['endDate'].setValue('');
    }

    this.billsForm.controls['utilityType'].setValue('');
    this.billsForm.controls['MeterNumber'].setValue('');

    this.billsForm.controls['LastMonthBillGeneratedDate'].setValue('');
    //  this.billsForm.controls['billGeneratedDate'].setValue('');
    // this.billsForm.controls['SelectDatesForBillGaneration'].setValue('');
    //  this.billsForm.controls['billedDays'].setValue('');
    this.gas_array.clear()
    this.water_array.clear()
    this.electricity_array.clear();
    this.otherCharge = []

  }
  utilityChange(event: any) {
    console.log(event)
    if (event == undefined) {
      this.utilityMetersList = [];
    }
    this.store.dispatch(resetBillReadingsInfoState())
    this.resetCalculations(undefined) /// to reste form values
    let meter: any[] = [];

    this.selectedResidentialunit?.utilityMeters.forEach((item: any) => {
      if (item.utilityId == event.utilityId) {
        meter.push(item);
      }
    })
    this.utilityMetersList = meter;
  }
  meterChange(event: any) {
    console.log(event)
    if (event == undefined) {
      this.resetCalculations(undefined)
    } else {
      //this.billsForm.controls['SelectDatesForBillGaneration'].setValue('');
      this.store.dispatch(resetBillReadingsInfoState())
    }
  }

  aggregateAmounts = (data: any) => {

    const result = data.reduce((acc: any, current: any) => {
      if (!acc[current.name]) {
        acc[current.name] = { name: current.name, amount: current.amount };
      } else {
        acc[current.name].amount += current.amount;
      }
      return acc;
    }, {});

    return Object.values(result);


  }

  ele_otherCgarges: any[] = [];
  water_otherCgarges: any[] = [];
  gas_otherCgarges: any[] = [];

  generalCharges: any[] = []
  updateBillsbyResidentilauit: any = Object.assign({});

  isInvalidBill: boolean = false;
  content: string = ''
  dailog() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        allowOutsideClick: false,
        // title: 'Session out',
        text: this.content,
        icon: 'warning',
        confirmButtonText: 'Ok',
        /// cancelButtonText: 'No, Cancel',
        showCancelButton: false,
        // timer: 20000, // close automatically after timeout
        //timerProgressBar: true,
      })
      .then((result) => {
        console.log(result, 'result');
        if (result.value) {
          this.router.navigate(['/bills']);
        }
      });
  }

  updateBillStatus(res: any) {
    this.loaderService.setLoading(true)
    // console.log(res)
    this.isdisabled = false;
    this.isInvalidBill = res.isInvalidBill;
    this.water_array.clear();
    this.gas_array.clear();
    this.electricity_array.clear();
    this.updateBillsbyResidentilauit = {};
    console.log(res)
    this.updateBillsbyResidentilauit = res;



    console.log(this.updateBillsbyResidentilauit)
    this.otherCharge = this.updateBillsbyResidentilauit?.commonCharges.length > 0 ? this.updateBillsbyResidentilauit?.commonCharges : [];
    this.generalCharges = this.updateBillsbyResidentilauit?.commonCharges.length > 0 ? this.aggregateAmounts(this.updateBillsbyResidentilauit?.commonCharges) : [];

    console.log(this.updateBillsbyResidentilauit)
    this.billsForm.controls['totalAmount'].setValue(this.updateBillsbyResidentilauit.totalAmount);

    //console.log(res)
    this.updateBillsbyResidentilauit?.electricity.forEach((item: any, index: number) => {
      this.add_electricity_Row(item, index);
      this.ele_otherCgarges = item.otherCharges;
      //console.log(this.ele_otherCgarges)


    })

    this.updateBillsbyResidentilauit?.gas.forEach((item: any, index: number) => {

      this.add_gas_Row(item, index);
      this.gas_otherCgarges = item.otherCharges;

    })
    this.updateBillsbyResidentilauit?.water.forEach((item: any, index: number) => {
      this.add_water_Row(item, index);
      this.water_otherCgarges = item.otherCharges;

    })
    this.updateBillsbyResidentilauit?.gas.forEach((item: any, index: number) => {
      console.log(this.currencyFormated(item.unitRate),)
      let formarray = this.billsForm.controls['gas_array'] as FormArray
      formarray.at(index).patchValue({
        //unitRateGas: item.unitRate,
        unitRateGas: this.currencyFormated(item.unitRate),
        macAddress: item.deviceSRNo,
        previousMeterReadingGas: item.previousMeterReading,
        currentMeterReadingGas: item.currentMeterReading,
        noOfUnitsConsumedGas: item.noofUnitsConsumed,
      })
    })
    this.updateBillsbyResidentilauit?.water.forEach((item: any, index: number) => {
      let formarray = this.billsForm.controls['water_array'] as FormArray
      formarray.at(index).patchValue({
        // unitRateWater: item.unitRate,
        macAddress: item.deviceSRNo,
        previousMeterReadingWater: item.previousMeterReading,
        currentMeterReadinWater: item.currentMeterReading,
        noOfUnitsConsumedWater: item.noofUnitsConsumed,
      })
    })
    this.updateBillsbyResidentilauit?.electricity.forEach((item: any, index: number) => {
      let formarray = this.billsForm.controls['electricity_array'] as FormArray
      formarray.at(index).patchValue({
        // unitRateEB: item.unitRateEB,
        // unitRateDG: item.unitRateDG,
        previousMeterReadingEB: item.electricityPreviousMeterReadingEB,
        currentMeterReadingEB: item.electricityCurrentMeterReadingEB,
        noOfUnitsConsumedEB: item.noofElectricityUnitsConsumedEB,
        previousMeterReadingDG: item.electricityPreviousMeterReadingDG,
        currentMeterReadingDG: item.electricityCurrentMeterReadingDG,
        noOfUnitsConsumedDG: item.noofElectricityUnitsConsumedDG,

      })
    })
    //console.log(this.billsForm.getRawValue())
    //  this.loaderService.setLoading(false);
  }

  currencyFormated(item: any) {
    return item != undefined
      ? this.currencyPipe.transform(item, 'INR', 'symbol', '1.2-2') // Using INR for currency symbol
      : '₹0.00';
  }

  bindBillinfoDetailsForAdhoc() {
    this.store.dispatch(resetbillsInfoState());
    this.store.pipe(select(getBillReadingsByMonthAdhocResponse)).subscribe(
      (res: any) => {
        if (res) {
          // console.log(res)
          this.isAlert = res.IsAlert;
          if (this.isAlert) {
            this.content = res.message;
            this.isdisabled = true;
            this.dailog();
          } else {
            this.updateBillStatus(res);
          }

          /* if (res && res.billStatus !== '') {
            if (res.billStatus == 'paid') {
              this.content = res.message;
              this.isdisabled = true;
              this.dailog();
            } else {
              if (res.isInvalidBill == true) {
                this.updateBillStatus(res)
              } else {
                this.isdisabled = true
                this.content = res.message;
                this.dailog();
              }
            }
          } else {
            if (res.isInvalidBill == false) {
              this.updateBillStatus(res);
            }

          } */
        }

      });
  }
  isAlert: boolean = false;
  private getBillingInfo: Subscription = new Subscription();
  bindBillinfoDetailsForManual() {
    this.store.dispatch(resetbillsInfoState());
    this.getBillingInfo = this.store
      .pipe(select(getBillReadingsByMonthResponse)).subscribe((res: any) => {
        if (res) {
          // console.log(res)
          this.isAlert = res.isAlert; //for checking it is json responce or alert data 
          if (this.isAlert) {
            this.content = res.message;
            this.isdisabled = true;
            this.dailog();
          } else {
            this.loaderService.setLoading(true)
            this.updateBillStatus(res);
          }

          /*    if (res && res.billStatus !== '') {
               if (res.billStatus === 'paid') {
                 this.content = res.message;
                 this.isdisabled = true;
                 this.dailog();
   
               } else {
                 if (res.isInvalidBill == true) {
                   this.updateBillStatus(res);
                 } else {
                   this.isdisabled = true
                   this.content = res.message;
                   this.dailog();
                 }
               }
             } else {
               console.log("dfshasa")
               if (res.isInvalidBill == false) {
                 this.updateBillStatus(res);
               }
             } */
        }

      })




  }

  formatDate(isoString: any) {
    const date = new Date(isoString);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    //return `${day}-${month}-${year}`;
    return `${year}-${month}-${day}`;


  }

}
