<div class="container-fluid">
    <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>

    <section *ngIf="billList && billList.length>0">
        <div class="row">
            <div class="col-lg-12">
                <accordion [closeOthers]="false" class="accordion custom-accordian-bill" [isAnimated]="true">

                    <div *ngFor="let bills of billList; let i = index">
                        <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item5>
                            <div accordion-heading class="d-flex flex-grow-1">
                                <div class="flex-grow-1 custom-bg-body pt-2 ">
                                    <div class="d-flex flex-grow-1 align-items-center ms-3">
                                        <div class="flex-grow-1">
                                            <img class="utility-img" src="../../../../assets/images/brands/group.png">
                                            <span class="text-primary font-size-16">Invoice Details ({{bills?.utility}})
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mx-2 mt-3 text-accordian">

                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label"> Current Invoice Date</label>
                                                <p>{{bills?.currentInvoiceDate!=null?
                                                    (bills?.currentInvoiceDate | date:'d MMMM yyyy')
                                                    :'-' }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Previous Invoice Date</label>
                                                <p>{{bills.previousInvoiceDate!=null?
                                                    (bills.previousInvoiceDate | date: 'dd MMM yyyy')
                                                    :'-' }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Total Amount</label>
                                                <p>
                                                    {{bills.totalAmount!=undefined?(bills.totalAmount
                                                    |currency:'INR':true):'0'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <!-- <label class="form-label" (click)="onCheckboxClick($event)">Download
                                                    Invoice</label> -->

                                                <button type="button" class="btn btn-primary"
                                                    (click)="DownloadInvoice($event)">
                                                    <i class="bx bx-download font-size-16 align-middle me-2"></i>
                                                    Download
                                                    Invoice </button>
                                                <!-- <p (click)="onCheckboxClick($event)"><i class="fa fa-download"
                                                        aria-hidden="true"></i>
                                                </p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center">
                                    <i class="mdi font-size-24"
                                        [ngClass]="!item5?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-center">
                                        <img src="../../../../assets/images/brands/utility.png" class="me-2 w-3">
                                        <p class="text-decoration-underline card-title font-size-16">Consumer
                                            Details</p>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/user.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Consumer Name</label>
                                                        <p>{{bills?.consumerName?bills.consumerName:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/apartment.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Flat No.</label>
                                                        <p>{{bills?.residentialUnit?bills.residentialUnit:'-'}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/bill.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Invoice No.</label>
                                                        <p>{{bills?.invoiceNo?bills.invoiceNo:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>

                            <!-- gas -->
                            <section *ngIf="bills?.gas.length>0">
                                <div *ngFor="let gas of bills?.gas">
                                    <div class="row" *ngIf="bills?.gas.length>0">
                                        <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                            <img src="../../../../assets/images/brands/gas.png">
                                            <span class="font-weight-bold ms-3 font-size-15">Gas Details : (Meter No :{{
                                                gas.deviceSRNo}})</span>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.previousReadingDate!=null?
                                                            (gas?.previousReadingDate
                                                            | date: 'dd MMM yyyy hh:mm a')
                                                            :'-' }}
                                                        </p>


                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.currentReadingDate!=null?
                                                            (gas?.currentReadingDate
                                                            | date: 'dd MMM yyyy hh:mm a')
                                                            :'-' }}
                                                        </p>


                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.previousMeterReading!=undefined?gas.previousMeterReading
                                                            +' m³' :'-'}}

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.currentMeterReading!=undefined?gas.currentMeterReading
                                                            +' m³':'-'}}

                                                        </p>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2 invoicecard_gas">
                                                <div class=" pt-2 ">

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Units Consumed</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{gas?.noofUnitsConsumed
                                                                !=undefined?gas.noofUnitsConsumed+'
                                                                m³':'-'}}
                                                            </p>

                                                        </div>

                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{gas?.unitRate!=undefined?(gas.unitRate
                                                                |currency:'INR':true):'0'}}
                                                            </p>

                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Bill Amount</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{gas?.invoiceAmount!=undefined?(gas.invoiceAmount
                                                                |currency:'INR':true):'0'}}
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="row" *ngIf="gas.utilityServiceCharges.length>0">
                                            <table class="table custom-border-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Service Name</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">GST (%)</th>
                                                        <th scope="col">GST Amount</th>
                                                        <th scope="col">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let charge of gas.utilityServiceCharges">
                                                        <td>{{charge.name}}</td>
                                                        <td>{{charge.amount |currency:'INR':true}}</td>
                                                        <td>{{charge.gst !=''? charge.gst:'-' }}</td>
                                                        <td>{{charge.gstAmt |currency:'INR':true}}</td>
                                                        <td>{{charge.total |currency:'INR':true}}</td>

                                                    </tr>
                                                    <tr>
                                                        <th scope="row"></th>
                                                        <td colspan="1"></td>
                                                        <td></td>
                                                        <td class="font-weight-bolder">Total Amount</td>
                                                        <td class="font-weight-bolder">
                                                            {{gas.totalServiceCharge!=undefined?(gas.totalServiceCharge
                                                            |currency:'INR':true):'0'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>


                                        <div class="col-md-12 mt-2">
                                            <div class="text-center totalBill ">
                                                <p class="mb-0 font-size-10 font-weight-bold "> Total Bill Amount :
                                                    {{gas.totalAmount!=undefined?(gas.totalAmount
                                                    |currency:'INR':true):'0'}} </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>


                            <!-- water -->
                            <section *ngIf="bills?.water.length>0">
                                <div *ngFor="let water of bills?.water">

                                    <div class="row">
                                        <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                            <img src="../../../../assets/images/brands/water.png">
                                            <span class="font-weight-bold ms-3 font-size-15">Water Details : (Meter No
                                                :{{
                                                water?.deviceSRNo}})</span>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end">{{water?.previousReadingDate!=null?
                                                            (water?.previousReadingDate
                                                            | date: 'dd MMM yyyy hh:mm a')
                                                            :'-' }}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.currentReadingDate!=null?
                                                            (water?.currentReadingDate
                                                            | date: 'dd MMM yyyy hh:mm a')
                                                            :'-' }}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.previousMeterReading?water.previousMeterReading
                                                            +'
                                                            L':'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.currentMeterReading?water.currentMeterReading
                                                            +'
                                                            L':'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2 invoicecard_Water">


                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Units Consumed</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.noofUnitsConsumed !=
                                                            undefined?water.noofUnitsConsumed+'
                                                            L':'-'}}</p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Rate</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.unitRate!=undefined?(water.unitRate
                                                            |currency:'INR':true):'0'}}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Bill Amount</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.invoiceAmount!=undefined?(water.invoiceAmount
                                                            |currency:'INR':true):'0'}}
                                                        </p>

                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                        <div class="row" *ngIf="water.utilityServiceCharges.length>0">
                                            <table class="table custom-border-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Service Name</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">GST (%)</th>
                                                        <th scope="col">GST Amount</th>
                                                        <th scope="col">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let charge of water.utilityServiceCharges">
                                                        <td>{{charge.name}}</td>
                                                        <td>{{charge.amount | currency:'INR':true}}</td>
                                                        <td>{{charge.gst !=''?charge.gst:'-' }}</td>
                                                        <td>{{charge.gstAmt | currency:'INR':true}}</td>
                                                        <td>{{charge.total | currency:'INR':true}}</td>

                                                    </tr>
                                                    <tr>
                                                        <th scope="row"></th>
                                                        <td colspan="1"></td>
                                                        <td></td>
                                                        <td class="font-weight-bolder">Total Amount</td>
                                                        <td class="font-weight-bolder">
                                                            {{water.totalServiceCharge!=undefined?(water.totalServiceCharge
                                                            |currency:'INR':true):'0'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>


                                        <div class="col-md-12 mt-2">
                                            <div class="text-center totalBill ">
                                                <p class="mb-0 font-size-10 font-weight-bold ">Total Bill Amount :
                                                    {{water?.totalAmount!=undefined?(water.totalAmount
                                                    |currency:'INR':true):'0'}} </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>



                            <!-- electricity -->
                            <section *ngIf="bills?.electricity?.length>0">
                                <div *ngFor="let ele of bills?.electricity">
                                    <div class="row">
                                        <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                            <img src="../../../../assets/images/brands/electricity.png">
                                            <span class="font-weight-bold ms-3 font-size-15">Electricity Details :
                                                (Meter No :
                                                {{ele?.deviceSRNo}})</span>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2 ">

                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading EB
                                                        </label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{ele?.electricityPreviousMeterReadingEB!=undefined?ele.electricityPreviousMeterReadingEB
                                                            +' kWh':'-'}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading
                                                            DG</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{ele?.electricityPreviousMeterReadingDG
                                                            !=undefined?ele.electricityPreviousMeterReadingDG +'
                                                            kWh':'-'}}
                                                        </p>
                                                    </div>
                                                </div>


                                                <div class="d-flex  align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading
                                                            EB</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{ele?.electricityCurrentMeterReadingEB!=undefined?ele.electricityCurrentMeterReadingEB
                                                            +' kWh':'-'}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex  align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading DG
                                                        </label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{ele?.electricityCurrentMeterReadingDG!=undefined?ele.electricityCurrentMeterReadingDG
                                                            +' kWh':'-'}}
                                                        </p>
                                                    </div>
                                                </div>



                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Reading Date
                                                        </label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{ele?.previousReadingDate!=null?
                                                            (ele?.previousReadingDate | date: 'dd MMM yyyy hh:mm a')
                                                            :'-' }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Reading Date </label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{ele?.currentReadingDate!=null?
                                                            (ele?.currentReadingDate
                                                            | date: 'dd MMM yyyy hh:mm a')
                                                            :'-' }}
                                                        </p>
                                                    </div>
                                                </div>




                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2 invoicecard_ele ">

                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">No. Of Units Consumed
                                                                EB</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.noofElectricityUnitsConsumedEB!=undefined?ele.noofElectricityUnitsConsumedEB
                                                                + ' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label ">No. Of Units Consumed DG
                                                            </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.noofElectricityUnitsConsumedDG!=undefined?ele.noofElectricityUnitsConsumedDG
                                                                + ' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate EB </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.unitRateEB!=undefined?(ele.unitRateEB
                                                                |currency:'INR':true):'0'}}</p>
                                                            <!--  <p class="float-end">{{ele?.unitRateEB?ele.unitRateEB:'-'}}</p> -->
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">

                                                            <p class="float-end mb-0">
                                                                {{ele?.unitRateDG!=undefined?(ele.unitRateDG
                                                                |currency:'INR':true):'0'}}</p>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Bill Amount </label>
                                                        </div>
                                                        <div class="flex-grow-1">

                                                            <p class="float-end mb-0">
                                                                {{ele?.invoiceAmount!=undefined?(ele.invoiceAmount
                                                                |currency:'INR':true):'0'}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label"></label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>


                                    </div>
                                    <div class="row">
                                        <!-- <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Previous Reading Date
                                                            </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.previousReadingDate!=null?
                                                                (ele?.previousReadingDate | date:'d MMMM yyyy')
                                                                :'-' }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Current Reading Date </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.currentReadingDate!=null?
                                                                (ele?.currentReadingDate
                                                                | date:'d MMMM yyyy HH:mm:ss')

                                                                :'-' }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!--  <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Bill Amount </label>
                                                    </div>
                                                    <div class="flex-grow-1">

                                                        <p class="float-end mb-0">
                                                            {{ele?.invoiceAmount!=undefined?(ele.invoiceAmount
                                                            |currency:'INR':true):'0'}}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div> -->


                                    </div>

                                    <div class="row" *ngIf="ele.utilityServiceCharges.length>0">
                                        <table class="table custom-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of ele.utilityServiceCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td>{{charge.amount | currency:'INR':true}}</td>
                                                    <td>{{charge.gst !=''?charge.gst:'-' }}</td>
                                                    <td>{{charge.gstAmt | currency:'INR':true}}</td>
                                                    <td>{{charge.total | currency:'INR':true}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder">Total Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{ele.totalServiceCharge!=undefined?(ele.totalServiceCharge
                                                        |currency:'INR':true):'0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                    <div class="col-md-12 mt-2">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold "> Total Bill Amount :
                                                {{ele?.totalAmount!=undefined?(ele.totalAmount
                                                |currency:'INR':true):'0'}} </p>
                                        </div>
                                    </div>


                                </div>
                            </section>

                            <!-- Trisources -->
                            <section *ngIf="bills?.TriSource?.length>0">
                                <div *ngFor="let tri of bills?.TriSource">
                                    <div class="row">
                                        <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                            <img src="../../../../assets/images/brands/electricity.png">
                                            <span class="font-weight-bold ms-3 font-size-15">TriSource Details :
                                                (Meter No :
                                                {{tri?.deviceSRNo}})</span>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2 ">

                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading EB
                                                        </label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{tri?.electricityPreviousMeterReadingEB!=undefined?tri.electricityPreviousMeterReadingEB
                                                            +' kWh':'-'}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading
                                                            DG</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{tri?.electricityPreviousMeterReadingDG
                                                            !=undefined?tri.electricityPreviousMeterReadingDG +'
                                                            kWh':'-'}}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading Solar
                                                        </label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{tri?.PreviousMeterReadingSolar
                                                            !=undefined?tri.PreviousMeterReadingSolar +'
                                                            kWh':'-'}}
                                                        </p>
                                                    </div>
                                                </div>


                                                <div class="d-flex  align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading
                                                            EB</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{tri?.electricityCurrentMeterReadingEB!=undefined?tri.electricityCurrentMeterReadingEB
                                                            +' kWh':'-'}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex  align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading DG
                                                        </label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{tri?.electricityCurrentMeterReadingDG!=undefined?tri.electricityCurrentMeterReadingDG
                                                            +' kWh':'-'}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex  align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading Solar
                                                        </label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{tri?.CurrentMeterReadingSolar!=undefined?tri.CurrentMeterReadingSolar
                                                            +' kWh':'-'}}
                                                        </p>
                                                    </div>
                                                </div>



                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Reading Date
                                                        </label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{tri?.previousReadingDate!=null?
                                                            (tri?.previousReadingDate | date:'d MMMM yyyy HH:mm:ss a')
                                                            :'-' }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Reading Date </label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{tri?.currentReadingDate!=null?
                                                            (tri?.currentReadingDate
                                                            | date:'d MMMM yyyy HH:mm:ss a')

                                                            :'-' }}
                                                        </p>
                                                    </div>
                                                </div>




                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2 invoicecard_ele ">

                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">No. Of Units Consumed
                                                                EB</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{tri?.noofElectricityUnitsConsumedEB!=undefined?tri.noofElectricityUnitsConsumedEB
                                                                + ' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label ">No. Of Units Consumed DG
                                                            </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{tri?.noofElectricityUnitsConsumedDG!=undefined?tri.noofElectricityUnitsConsumedDG
                                                                + ' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label ">No. Of Units Consumed Solar
                                                            </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{tri?.NoofElectricityUnitsConsumedSolar!=undefined?tri.NoofElectricityUnitsConsumedSolar
                                                                + ' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate EB </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{tri?.unitRateEB!=undefined?(tri.unitRateEB
                                                                |currency:'INR':true):'0'}}</p>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">

                                                            <p class="float-end mb-0">
                                                                {{tri?.unitRateDG!=undefined?(tri.unitRateDG
                                                                |currency:'INR':true):'0'}}</p>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate Solar </label>
                                                        </div>
                                                        <div class="flex-grow-1">

                                                            <p class="float-end mb-0">
                                                                {{tri?.UnitRateSolar!=undefined?(tri.UnitRateSolar
                                                                |currency:'INR':true):'0'}}</p>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Bill Amount </label>
                                                        </div>
                                                        <div class="flex-grow-1">

                                                            <p class="float-end mb-0">
                                                                {{tri?.invoiceAmount!=undefined?(tri.invoiceAmount
                                                                |currency:'INR':true):'0'}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label"></label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>


                                    </div>
                                    <div class="row">

                                    </div>

                                    <div class="row" *ngIf="tri.utilityServiceCharges.length>0">
                                        <table class="table custom-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of tri.utilityServiceCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td>{{charge.amount | currency:'INR':true}}</td>
                                                    <td>{{charge.gst !=''?charge.gst:'-' }}</td>
                                                    <td>{{charge.gstAmt | currency:'INR':true}}</td>
                                                    <td>{{charge.total | currency:'INR':true}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder">Total Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{tri.totalServiceCharge!=undefined?(tri.totalServiceCharge
                                                        |currency:'INR':true):'0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="text-center totalBill">
                                                <p class="mb-0 font-size-10 font-weight-bold">Amount Added To Wallet :
                                                    {{ tri?.sendmoney !== undefined ? (tri.sendmoney | currency: 'INR' :
                                                    'symbol' : '1.2-2') : '₹0.00' }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="text-center totalBill">
                                                <p class="mb-0 font-size-10 font-weight-bold">Bill Amount :
                                                    {{ tri?.totalAmount !== undefined ? (tri.totalAmount | currency:
                                                    'INR' : 'symbol' : '1.2-2') : '₹0.00' }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                    <!-- 
                                    <div class="col-md-12 mt-2">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold "> Total Bill Amount :
                                                {{tri?.totalAmount!=undefined?(tri.totalAmount
                                                |currency:'INR':true):'0'}} </p>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-2">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold "> Amount Added to Wallet:
                                                {{tri?.sendmoney!=undefined?(tri.sendmoney
                                                |currency:'INR':true):'0'}} </p>
                                        </div>
                                    </div> -->


                                </div>
                            </section>



                            <!-- other Charges -->
                            <div class="row" *ngIf="bills.generalCharges.length>0">
                                <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                    <img src="../../../../assets/images/brands/rupee.png">
                                    <span class="font-weight-bold ms-3 font-size-15">Other Charges </span>
                                </div>
                            </div>
                            <div>
                                <div class="row" *ngIf="bills.generalCharges.length>0">
                                    <table class="table custom-border-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Service Name</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">GST (%)</th>
                                                <th scope="col">GST Amount</th>
                                                <th scope="col">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let charge of bills.generalCharges">
                                                <td>{{charge.name}}</td>
                                                <td>{{charge.amount | currency:'INR':true}}</td>
                                                <td>{{charge.gst !=''?charge.gst :'-'}}</td>
                                                <td>{{charge.gstAmt |currency:'INR':true}}</td>
                                                <td>{{charge.total | currency:'INR':true}}</td>

                                            </tr>
                                            <tr>
                                                <th scope="row"></th>
                                                <td colspan="1"></td>
                                                <td></td>
                                                <td class="font-weight-bolder">Total Amount</td>
                                                <td class="font-weight-bolder">
                                                    {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                    |currency:'INR':true):'0'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>


                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                |currency:'INR':true):'0'}} </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 ">
                                    <div class="d-flex justify-content-around penaltyBill">
                                        <!--  <div class="text-center">
                                            <p class="font-size-16 font-weight-bolder">Bill Amount</p>
                                            <p class="font-size-18 font-weight-bold">
                                                {{totalAmountwithoutpenalty!=undefined?(totalAmountwithoutpenalty
                                                |currency:'INR':true):'0'}}</p>
                                        </div> -->
                                        <!-- <div class="text-center">
                                            <p class="font-size-16 font-weight-bolder">Penalty Amount</p>
                                            <p class="font-size-18 font-weight-bold">
                                                {{bills?.totalPenalty!=undefined?(bills?.totalPenalty
                                                |currency:'INR':true):'0'}} </p>
                                        </div> -->
                                        <div class="text-center mt-2">
                                            <p class="font-size-16 font-weight-bolder">Total Bill Amount</p>
                                            <!--  <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                            {{bills?(bills.totalGeneralCharge+bills.billAmount+bills?.totalPenalty
                                            |currency:'INR':true):'0'}} </p> -->
                                            <p class="font-size-18 font-weight-bold">
                                                {{bills?.totalAmount!=undefined?(bills.totalAmount
                                                |currency:'INR':true):'0'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </accordion-group>
                    </div>
                </accordion>
            </div>


        </div>
    </section>
    <div class="card-body">
        @if(billList && billList.length<1){ <div class="text-center">
            <!-- <img src="assets/images/logo-dark.png" alt="" height="20" class="auth-logo-dark mx-auto">
                        <img src="assets/images/logo-light.png" alt="" height="20" class="auth-logo-light mx-auto"> -->
            <!-- <img src="../../../assets/images/nodata.jpg" alt="" height="250"> -->
            <h4 class="mt-5">No Data Found</h4>
    </div>
    }