import { Validations } from './../../../shared/constants/validations';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { process, State } from '@progress/kendo-data-query';
import { LoaderService } from '../../../core/services/loader.service';
import { SharedService } from '../../../shared/shared.service';
import { formatDate } from '@angular/common';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';

import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import {
  getConsumersMeterExportResponse,
  getConsumersMeterOnOffExportResponse,
  getmeterControlsResponse,
  getMeterOnOffControlsResponse,
} from '../../../store/customer/consumer-selector';
import {
  getConsumerMeteOnOffExport,
  getConsumerMeterExport,
  getmeterControls,
  getMeterOnOffControls,
  resetBillmeterControlsState,
  resetBillonOffControlsState,
  updateMeerStatus,
} from '../../../store/customer/consumer.action';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { MeterService } from '../../../core/services/meter.services';
import { HttpErrorResponse } from '@angular/common/http';
import { updateMeterStatus } from '../../../store/meters/meters.actions';
import { updateMeterStatusResponse } from '../../../store/meters/meters-selector';
import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { NotificationService } from '../../../shared/notification.service';
import { Helper } from '../../../core/helpers/helper';
import _ from 'lodash';
@Component({
  selector: 'app-rp-meter-controlls',
  templateUrl: './rp-meter-controlls.component.html',
  styleUrl: './rp-meter-controlls.component.scss',

})
export class RpMeterControllsComponent {
  @ViewChild('cdkStepper') stepper: CdkStepper | undefined;
  public selectableSettings!: SelectableSettings;
  public checkboxOnly = true;
  public drag = false;
  datePickerConfig: Partial<BsDatepickerConfig>;
  public mode: SelectableMode = 'multiple';
  public filterMode: FilterableSettings = 'row';
  breadCrumbItems: Array<{}> | undefined;
  ismeteronOff: boolean = false;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  meterControlsList: any[] = [];
  meterOnOffHistorysList: any[] = [];

  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  projectId: any;
  consumerId: any;
  currentUser: any;
  public pageSizes = table_pageSize.pageSizes;
  public meterControlsPageSize = table_pageSize.pageSizes;
  public meterOnOffHistoryPageSize = table_pageSize.pageSizes;
  //public pageSizes = true;
  public previousNext = true;
  isexcel: boolean = false;
  isPdf: boolean = false;
  public meterControlsState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  meterOnOfffromDate: any = [];
  public meterOnOffControlsState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  @ViewChild(TooltipDirective) public tooltipDirsupplier:
    | TooltipDirective
    | undefined;
  //@ViewChild('excelexport', { static: true }) excelExport!: ExcelExportComponent;
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  public sort: SortDescriptor[] = [];
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: any = '';
  meteronOffsearchedValue: any = '';
  meterOnOffqueryString: string = '';
  meterOnOfforderByQuery: string = '';


  public pageSize = 10;
  public meteronOffpagesize = 10
  public skip = 0;
  ServiceError = Validations.ServiceError;
  totalUserCount: number = 0;
  currentPage: number = 1;


  meterOnOfftotalUserCount: number = 0;
  meterOnOffcurrentPage: number = 1;

  public MeterControlsgridData: GridDataResult = { data: [], total: 0 };
  public MeterOnOffHostotrydData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  private meterOnOffUpdateSubject: Subscription;
  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private store: Store,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService,
    private meterService: MeterService,
    private notificationService: NotificationService
  ) {
    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
      }
    );
    this.breadCrumbItems = [{ label: 'Meter Controls' }];

    this.meterOnOffUpdateSubject = this.notificationService.meterOnOffUpdate$.subscribe(data => {
      this.updateMeterConrollPage(data);
    });

    this.getMeterControlsStoreInfo();
  }
  isMetermenu: boolean = false;
  ngOnInit(): void {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project.id;
    this.isMetermenu = this.currentUser?.project.meterMenu;

    this.meteronOffsearchedValue = history.state.info ?? '';
    // if (this.meteronOffsearchedValue !== '' && this.stepper) {
    //   this.stepper.selectedIndex = 1;  // Convert string to number
    // }else if(this.stepper){
    //   this.stepper.selectedIndex = 0; 
    // }

    console.log(this.isMetermenu)
    this.getmeteronOffHistory()


    this.store.dispatch(
      getmeterControls({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );

  }
  public MeterOnOffValueChange(ev: string): void {

    if (ev.length == 0) {
      this.meterOnOffPageChange({ skip: 0, take: this.meteronOffpagesize })
      this.getmeteronOffHistory()
    }
  }

  onSearchonoffHistory(): void {
    if (this.meteronOffsearchedValue.length > 0) {
      this.getmeteronOffHistory();
    }
  }

  public meterValueChange(ev: string): void {
    if (ev.length == 0) {
      this.meterPageChange({ skip: 0, take: this.pageSize })
      this.store.dispatch(
        getmeterControls({
          pageIndex: this.currentPage,
          pageSize: this.pageSize,
          searchTerm: this.searchedValue,
          filter: this.queryString,
          orderBy: this.orderByQuery,
          ProjectId: this.projectId,
        })
      );
    }
  }

  onSearchMeterControll(): void {
    if (this.searchedValue.length > 0) {
      this.store.dispatch(
        getmeterControls({
          pageIndex: this.currentPage,
          pageSize: this.pageSize,
          searchTerm: this.searchedValue,
          filter: this.queryString,
          orderBy: this.orderByQuery,
          ProjectId: this.projectId,
        })
      );
    }
  }


  updateMeterConrollPage(data: any) {
    this.meteronOffsearchedValue = data;
    if (this.meteronOffsearchedValue !== '' && this.stepper) {
      this.stepper.selectedIndex = 1;  // Convert string to number
    }
    this.getmeteronOffHistory();
    // Trigger any additional logic needed to refresh the page
  }

  ngAfterViewInit(): void {
    if (this.meteronOffsearchedValue !== '' && this.stepper) {
      this.stepper.selectedIndex = 1;  // Convert string to number
    } else if (this.stepper) {
      this.stepper.selectedIndex = 0;
    }
  }
  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }
  getmeteronOffHistory() {
    this.store.dispatch(
      getMeterOnOffControls({
        pageIndex: this.meterOnOffcurrentPage,
        pageSize: this.meteronOffpagesize,
        searchTerm: this.meteronOffsearchedValue,
        filter: this.meterOnOffqueryString,
        orderBy: this.meterOnOfforderByQuery,
        FromDate:
          this.meterOnOfffromDate.length > 0 ? Helper.formatDate(this.meterOnOfffromDate[0]) : '',
        ToDate:
          this.meterOnOfffromDate.length > 0 ? Helper.formatDate(this.meterOnOfffromDate[1]) : '',
      })
    );
  }

  clearDate() {
    this.meterOnOfffromDate = [];
    //this.meterOnOffqueryString = '';
    this.store.dispatch(
      getMeterOnOffControls({
        pageIndex: this.meterOnOffcurrentPage,
        pageSize: this.meteronOffpagesize,
        searchTerm: this.meteronOffsearchedValue,
        filter: this.meterOnOffqueryString,
        orderBy: this.meterOnOfforderByQuery,
        FromDate:
          this.meterOnOfffromDate.length > 0 ? Helper.formatDate(this.meterOnOfffromDate[0]) : '',
        ToDate:
          this.meterOnOfffromDate.length > 0 ? Helper.formatDate(this.meterOnOfffromDate[1]) : '',
      })
    );
    // Clear the selected date
    // this.metreOnoFffilterBasedOnDate(0)
  }
  checkRelayStatus(dataItem: any) {
    this.sharedService.setMeterViewDetails(
      JSON.stringify(dataItem)
    );
    let request = {
      MacAddress: dataItem?.macAddress,
      IsConsumer: true,
      ProjectId: this.projectId

    };
    this.loaderService.setLoading(true)
    // this.store.dispatch(checkMeterStatus({ request: request }));
    this.meterService.checkMeterStatus(request).subscribe({
      next: (response) => {
        // Handle the response and show a success alert
        setTimeout(() => {
          this.store.dispatch(
            getmeterControls({
              pageIndex: this.currentPage,
              pageSize: this.pageSize,
              searchTerm: this.searchedValue,
              filter: this.queryString,
              orderBy: this.orderByQuery,
              ProjectId: this.projectId,
            })
          );
        }, 30000)
      },
      error: (error: HttpErrorResponse) => {
        // Handle error and display error alert
        this.sharedService.showMessageDialog(
          error.message,
          this.ServiceError.Error,
          SweetAlertIcon.ERROR
        );
        console.error('Error occurred:', error);
      }
    });
    // }
  }


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  private exportSubscription: Subscription = new Subscription();
  private metereOnOffexportSubscription: Subscription = new Subscription();


  exportData: any[] = [];
  meteronOffexportData: any[] = [];
  isMeter: boolean = false;
  isMeterOnOffDisabled: boolean = false;
  getMeterControlsStoreInfo() {
    this.store.dispatch(resetBillmeterControlsState());
    this.store.dispatch(resetBillonOffControlsState());


    let meterOnOffHostoryresponse$ = this.store.pipe(
      select(getMeterOnOffControlsResponse)
    );

    let updateMeterStatusResponse$ = this.store.pipe(
      select(updateMeterStatusResponse)
    );
    updateMeterStatusResponse$.subscribe((res: any) => {
      if (res) {
        this.store.dispatch(
          getmeterControls({
            pageIndex: this.currentPage,
            pageSize: this.pageSize,
            searchTerm: this.searchedValue,
            filter: this.queryString,
            orderBy: this.orderByQuery,
            ProjectId: this.projectId,
          })
        );
      }
      console.log('updateMeterStatusResponse', res);
    });
    let getMeterControlsData$ = this.store.pipe(
      select(getmeterControlsResponse)
    );
    this.exportSubscription = this.store
      .pipe(select(getConsumersMeterExportResponse))
      .subscribe((res: any) => {
        if (res) {
          console.log(res);
          this.exportData = [];
          if (res?.items.length > 0) {
            this.exportData = [...res?.items];
            const transformedData = this.exportData?.map((item: any) => ({
              'Device Sr.No': item?.meterSrNo,
              'Mac Address': item?.macAddress,
              'Utility': item?.utility,
              'Meter On/Off': item?.valveOrRelayStatus,
            }));
            this.PdfexportService.downloadExcel(transformedData, 'Meters');
          } else {
            this.sharedService.showMessageDialog(
              'No data to display',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }

      });
    this.metereOnOffexportSubscription = this.store
      .pipe(select(getConsumersMeterOnOffExportResponse))
      .subscribe((res: any) => {
        this.meteronOffexportData = [];
        if (res) {
          if (res?.items?.length > 0) {
            this.meteronOffexportData = [...res?.items];
            const transformedData = this.meteronOffexportData?.map(
              (item: any) => ({
                'Device Sr.No': item?.meterSrNo,
                'Flat No': item?.residentialUnit,
                'MacAddress': item?.macAddress,
                'Utility': item?.utilityType,
                'Meter OnOff Status': item?.onOffStatus,
                'Date': this.sharedService.dateFormateChnage(item?.onOffDate)
                // formatDate(item?.onOffDate, 'dd MMM yyyy hh:mm a', 'en-US'),
              })
            );
            this.PdfexportService.downloadExcel(transformedData, 'MeterOnOff');
          } else {
            this.sharedService.showMessageDialog(
              'No data to display',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }

      });
    meterOnOffHostoryresponse$.subscribe((res: any) => {
      console.log(res)
      //if (res.items.length > 0) {
      // console.log(res.items)

      this.meterOnOffHistorysList = [...res?.items];
      this.meteronOffpagesize = res?.pageSize;
      this.meterOnOfftotalUserCount = res?.totalCount;
      this.meterOnOffloadGridTable();
      // }
    })


    getMeterControlsData$.subscribe((res: any) => {
      if (res) {
        console.log('res-->', res);
        this.meterControlsList = [...res?.items];


        // console.log('tmeterControlsList', this.meterControlsList);
        // console.log('res', res);
        this.pageSize = res?.pageSize;
        this.totalUserCount = res?.totalCount;
        let selectedMeter = this.sharedService.getMeterViewDetails() ? JSON.parse(this.sharedService.getMeterViewDetails()) : null;
        // console.log(selectedMeter);

        if (selectedMeter) {
          // need to implement check meter status response
          selectedMeter = this.meterControlsList.find((item: any) => item?.id == selectedMeter.id);
          console.log(selectedMeter);
          let message = selectedMeter.utility + ' Meter Status is ' + (selectedMeter.valveOrRelayStatus ? 'On' : 'Off');
          this.sharedService.showMessageDialog(
            message,
            '',
            SweetAlertIcon.INFO
          );

          this.sharedService.setMeterViewDetails(null);
        }

        this.loadGridTable();
      }
    });
  }


  getenable(dataItem: any) {
    if (this.isMeterOnOffDisabled) {
      return false; // to enable
    }
    return true; //to disable
  }
  ngOnDestroy() {
    if (this.meterOnOffUpdateSubject) {
      this.meterOnOffUpdateSubject.unsubscribe();
    }
    this.sharedService.setMeterViewDetails(null);
    this.exportData = [];
    this.meteronOffexportData = [];
    this.exportSubscription.unsubscribe();
    this.metereOnOffexportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }
  formatDate(isoString: any) {
    const date = new Date(isoString);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }
  getDate(dataItem: any) {
    if (dataItem.meterStatus == 'On Request Sent') {
      return this.formatDate(dataItem.offRequestDate);
      // return this.datepipe.transform(dataItem.offRequestDate, 'yyyy-MM-dd');
      //return dataItem.offRequestDate
    } else {
      //  return this.datepipe.transform(dataItem.onRequestDate, 'yyyy-MM-dd');
      return this.formatDate(dataItem.onRequestDate);
      // return dataItem.onRequestDate
    }
  }


  meterOnOffloadGridTable() {
    const state: any = {
      take: this.meteronOffpagesize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.meterOnOffHistorysList, state);
    this.MeterOnOffHostotrydData = {
      data: this.meterOnOffHistorysList,
      total: this.meterOnOfftotalUserCount,
    };

    this.MeterOnOffHostotrydData.data = this.MeterOnOffHostotrydData.data.map((item: any) => {
      return {
        ...item,
        date: item.onOffDate != null ? this.sharedService.dateFormateChnage(item.onOffDate) : '-'
      }
    })


    //this.loaderService.setLoading(false);
  }



  loadGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.meterControlsList, state);
    this.MeterControlsgridData = {
      data: this.meterControlsList,
      total: this.totalUserCount,
    };
    this.MeterControlsgridData.data = this.MeterControlsgridData?.data.map((items) => {
      return {
        ...items,
        meterStatus: items?.utility !== 'Water' ? items?.valveOrRelayStatus ? "ON" : 'OFF' : ''
      };
      // console.log(new Date(project.currentDate));
    })
    //this.loaderService.setLoading(false);
  }

  public onFilter(value: Event): void {
    // const inputValue = value;
    // this.searchedValue = value;
    this.meterPageChange({ skip: 0, take: this.pageSize })
    this.store.dispatch(
      getmeterControls({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
  }

  public onFilterOnOff(value: Event): void {
    this.loaderService.setLoading(true);
    //console.log(value)
    // const inputValue = value;
    // this.searchedValue = value;
    this.meterOnOffPageChange({ skip: 0, take: this.meteronOffpagesize })
    this.getmeteronOffHistory()
  }
  metreOnoFffilterBasedOnDate(flag: number) {
    if (flag == 0) {
      this.meterOnOfffromDate = [];
    }


    this.store.dispatch(
      getMeterOnOffControls({
        pageIndex: this.meterOnOffcurrentPage,
        pageSize: this.meteronOffpagesize,
        searchTerm: this.meteronOffsearchedValue,
        filter: this.meterOnOffqueryString,
        orderBy: this.meterOnOfforderByQuery,
        FromDate:
          this.meterOnOfffromDate.length > 0 ? Helper.formatDate(this.meterOnOfffromDate[0]) : '',
        ToDate:
          this.meterOnOfffromDate.length > 0 ? Helper.formatDate(this.meterOnOfffromDate[1]) : '',
      })
    );

    /* this.store.dispatch(
      getAdminmeteronOffHistory({
        pageIndex: this.meterOnOffcurrentPage,
        pageSize: this.meteronOffpagesize,
        searchTerm: this.meteronOffsearchedValue,
        filter: this.meterOnOffqueryString,
        orderBy: this.meterOnOfforderByQuery,
        MeterId: this.meterViewDetails?.id,
        FromDate:
          this.meterOnOfffromDate.length > 0 ? Helper.formatDate(this.meterOnOfffromDate[0]) : '',
        ToDate:
          this.meterOnOfffromDate.length > 0 ? Helper.formatDate(this.meterOnOfffromDate[1]) : '',
      })
    ); */
  }

  ActivationChange(event: any, dataItem: any): void {
    //console.log(event);
    //console.log(dataItem);
    this.meterControlsList = this.meterControlsList.map((x: any) => {
      console.log(x, dataItem.id);
      if (x.id === dataItem.id) {
        /* if (dataItem.offRequest == 'No' && dataItem.onRequest == 'Yes') {
          return {
            ...x,
            meterStatus: event ? 'On Request Sent' : 'Turned Off', // Update meterStatus conditionally
            isMeterOnOff: true,

          };
        } else if (dataItem.offRequest == 'No' && dataItem.onRequest == 'No') {
          return {
            ...x,
            meterStatus: event ? 'On Request Sent' : 'Turned Off', // Update meterStatus conditionally
            isMeterOnOff: true,
          };
        } */

        return {
          ...x,
          meterStatus: event ? 'On Request Sent' : 'Turned Off', // Update meterStatus conditionally
          isMeterOnOff: true,
        };
      }
      return x;
    });

    this.loadGridTable();

    let request = {
      meterId: dataItem?.id,
      status: event ? 'On' : 'Off',
      macAddress: dataItem.macAddress,
      utilityName: dataItem.utility
    };
    this.store.dispatch(updateMeterStatus({ request: request }));
    // this.store.dispatch(
    //   updateMeerStatus({ meterId: dataItem.id, meterStatus: event })
    // );
  }
  toggleMeterStatus(event: any, dataItem: any) {
    let request = {
      meterId: dataItem?.id,
      status: !event ? 'On' : 'Off',
      macAddress: dataItem.macAddress,
      utilityName: dataItem.utility,
      IsConsumer: true,
      ProjectId: this.projectId
    };
    this.store.dispatch(updateMeterStatus({ request: request }));
  }

  public onMetertFilterChange(filter: CompositeFilterDescriptor): void {
    this.meterControlsState.filter = filter;
    //console.log(this.meterControlsState);
    const queryString = this.sharedService.createQuery(this.meterControlsState);
    this.queryString = this.sharedService.createQuery(this.meterControlsState);
    this.store.dispatch(
      getmeterControls({
        pageIndex: 1,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
    console.log(queryString);
  }





  public customFilterState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  customDateFiltersQueryEq: any[] = [];
  customDateFiltersQueryNEQ: any[] = [];
  async onOffMetertFilterChange(filter: CompositeFilterDescriptor) {
    this.meterOnOffControlsState.filter = filter;
    // console.log(this.meterOnOffControlsState);

    this.customFilterState = {
      skip: 0,
      take: 10,
      filter: {
        logic: 'and',
        filters: [],
      },
    };
    this.customFilterState = _.cloneDeep(this.meterOnOffControlsState);
    var filterOperators = await this.sharedService.togetFilterOperators(this.meterOnOffControlsState);
    console.log("temp-------------->", filterOperators)
    const iscustomdates = filterOperators?.filter((filter: any) => {
      return filter.value instanceof Date;
    });

    let isEq: any;
    let isNeq: any;
    this.customDateFiltersQueryEq = [];
    this.customDateFiltersQueryNEQ = [];

    if (iscustomdates && iscustomdates.length > 0) {
      isEq = iscustomdates.filter((filter: any) => (filter.operator === "eq"));
      isNeq = iscustomdates.filter((filter: any) => (filter.operator === "neq"))

      if (isEq.length > 0) {
        this.customDateFiltersQueryEq = this.sharedService.customizeFilterQueryEQ(isEq);
        console.log("gasQueryString", this.customDateFiltersQueryEq)
      } if (isNeq?.length > 0) {
        isNeq.forEach((item: any) => {
          this.customDateFiltersQueryNEQ = this.sharedService.customizeFilterQueryNEQ(isNeq);
          console.log("gasQueryString", this.customDateFiltersQueryNEQ)
        })

      }

    } else {
      isNeq = undefined;
      isEq = undefined;
      this.customDateFiltersQueryEq = [];
      this.customDateFiltersQueryNEQ = [];
    }
    if (this.customDateFiltersQueryEq.length > 0 || this.customDateFiltersQueryNEQ.length > 0) {
      let customFilterQueryString = await this.sharedService.newmodifyFilterJson(this.customFilterState, this.customDateFiltersQueryEq, this.customDateFiltersQueryNEQ);
      this.meterOnOffqueryString = this.sharedService.dateFiltercreateQuery(customFilterQueryString);
    } else {
      this.meterOnOffqueryString = this.sharedService.createQuery(this.customFilterState);
    }

    const meterOnOffqueryString = this.sharedService.createQuery(this.meterOnOffControlsState);
    // this.meterOnOffqueryString = this.sharedService.createQuery(this.meterOnOffControlsState);
    this.meterOnOffcurrentPage = 1
    this.getmeteronOffHistory()

    console.log(meterOnOffqueryString);
  }
  meterOnOffopenCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document?.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }

  onOffSortChange(sort: SortDescriptor[]): void {
    this.meterOnOffControlsState.sort = sort;
    console.log(this.meterOnOffControlsState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.meterOnOffControlsState.sort
    );

    this.meterOnOfforderByQuery = orderByQuery;

    this.getmeteronOffHistory()
  }
  onSortChange(sort: SortDescriptor[]): void {
    this.meterControlsState.sort = sort;
    console.log(this.meterControlsState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.meterControlsState.sort
    );

    this.orderByQuery = orderByQuery;

    this.store.dispatch(
      getmeterControls({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
  }

  protected meterOnOffPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.selectedRowdataOnOff = [];
    this.skip = skip;
    this.meteronOffpagesize = take;
    this.meterOnOffcurrentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getmeteronOffHistory()

  }
  protected meterPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.selectedRowdata = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.store.dispatch(
      getmeterControls({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
    this.loadGridTable();
  }

  protected consumptionStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    // this.loadGridTable();
  }

  protected OnoffStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    // meterOnOffloadGridTable
  }

  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  selectedRowdataOnOff: any = [];
  deselectedRowdataOnOff: any = [];
  onSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdata.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdata);
      });

      if (this.deselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdata = filteredArray;
      }
    }
  }
  onOffSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdataOnOff.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdataOnOff);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdataOnOff.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdataOnOff);
      });

      if (this.deselectedRowdataOnOff.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdataOnOff.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdataOnOff.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdataOnOff = filteredArray;
      }
    }
  }
  exportAllMeter() {

    this.store.dispatch(
      getConsumerMeteOnOffExport({ criteria: 'All' })
    );
  }
  public onMeterExport(type: string) {
    /*  if (this.selectedRowdataOnOff.length == 0) {
       this.store.dispatch(getConsumerMeterExport({ ProjectId: this.projectId, criteria: 'All', }));
     } */
    if (type === 'Excel') {
      if (this.MeterOnOffHostotrydData.data.length > 0) {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      }
      else {
        this.sharedService.showMessageDialog(
          'No data to display',
          '',
          SweetAlertIcon.ERROR
        );
      }
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }
  exportAll() {
    this.store.dispatch(
      getConsumerMeterExport({ ProjectId: this.projectId, criteria: 'All' })
    );
  }
  public onExport(type: string) {
    /* if (this.selectedRowdata.length == 0) {
      this.store.dispatch(getConsumerMeterExport({ ProjectId: this.projectId, criteria: 'All', }));
    } */
    if (type === 'Excel') {
      if (this.MeterControlsgridData.data.length > 0) {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      } else {
        this.sharedService.showMessageDialog(
          'No data to display',
          '',
          SweetAlertIcon.ERROR
        );
      }
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }

  exportExcel() {

    this.isPdf = false;
    /*  this.isexcel = true;
     this.store.dispatch(getConsumerMeterExport({ ProjectId: this.projectId, criteria: 'All', })); */
  }



  preventManualInput(event: KeyboardEvent): void {
    // Prevent all keys except Tab and Arrow keys, which are essential for navigating
    const allowedKeys = ['Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault(); // Prevent input
    }
  }









}


