import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AxisLabelClickEvent,

  LegendItemHoverEvent,
  PlotAreaClickEvent,
  PlotAreaHoverEvent,
  SeriesClickEvent,
  SeriesHoverEvent,
} from '@progress/kendo-angular-charts';
import { MeterService } from '../../core/services/meter.services'
import { LegendItemClickEvent, CategoryAxis, Legend, Series, SeriesLabelsContentArgs, LineStyle } from "@progress/kendo-angular-charts";
import { DecimalPipe, formatDate } from '@angular/common';
import { SharedService } from '../../shared/shared.service';
import { BillingType, Roles } from '../../shared/constants/enum';
import { Store, select } from '@ngrx/store';
import { getConumerConnectionsData, getConumerPostpaidConsumptionDashboard, getConumerPostpaidDashboard } from '../../store/dashboard/dashboard.actions';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { getConumerConnectionsDataResponse, getConumerPostpaidConsumptionResponse, getConumerPostpaidResponse } from '../../store/dashboard/dashboard-selector';
import { colorsList, monthsArray, utilitiesArray } from '../../shared/constants/templatesNames';
import { error } from 'console';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { stack } from '@progress/kendo-drawing';
import { updateMeterStatus } from '../../store/meters/meters.actions';
import { updateMeterStatusResponse } from '../../store/meters/meters-selector';
import { DashboardService } from '../../core/services/dashboard.service';
import { LoaderService } from '../../core/services/loader.service';
import {
  ChartComponent,
  ApexChart,
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexStroke
} from "ng-apexcharts";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: any;
  toolbar: any;
};
interface XAxis {
  min: number; // Timestamp in milliseconds
  max: number; // Timestamp in milliseconds
}

interface UpdateOptionsData {
  [key: string]: {
    xaxis: XAxis;
  };
}
@Component({
  selector: 'app-postpaid-consumer-dashboard',
  templateUrl: './postpaid-consumer-dashboard.component.html',
  styleUrl: './postpaid-consumer-dashboard.component.scss'
})
export class PostpaidConsumerDashboardComponent {
  @ViewChild("chart", { static: false }) chart: ChartComponent | undefined = undefined;

  public chartOptions!: Partial<ChartOptions>; // 

  ismonthlyConsumption: boolean = true;
  monthlyConsumptionList: any[] = []
  monthlyConsumptionchartData: any[] = [];

  public seriesVisible = true;
  public residentialUnitChart: any;
  public values: any = ["All Projects"];
  dateRange: boolean = false;
  public style: LineStyle = "normal";
  public line: LineStyle = "smooth";

  utilities = utilitiesArray;
  private consumernDashboardData$: Subscription = new Subscription();
  private consumernConsumptionDashboardData$: Subscription = new Subscription();
  private getConumerConnectionsDataResponse$: Subscription = new Subscription();
  private updateMeterStatusResponse$: Subscription = new Subscription();

  consuptionSelectedUtility: any;
  selectedFlatno: any
  months = monthsArray;
  selectedRange: any;
  projectUtlityList: any[] = [];
  currentUserData: any;
  projectId: any;
  consumerId: any;
  billingType: any;
  datePickerConfig: Partial<BsDatepickerConfig>;
  isWalletEnabled: boolean = false;
  consumergraphdata: any;
  consumerMonthlyConsumptionData: any;
  consumptioncategories: any[] = [];
  last5Recharges: any[] = [];
  last5bills: any[] = [];
  last5deductionsPayments: any[] = [];
  lastPaidBill: any;
  lastPaidBillDate: any;
  consumerUtilityConnectionData: any;
  waterConnections: any = [];
  electricityConnections: any[] = [];
  gasConnections: any[] = [];
  consuptionCategories: any = []
  consumptionUtlity: any[] = [];

  waterConsumptionData: any[] = [];
  gasConsumptionData: any[] = [];
  electricityConsumptionData: any[] = [];
  Water: any[] = [];
  Gas: any = [];
  Electricity: any = [];
  watercategories: any[] = [];
  gascategories: any[] = [];
  electricitycategories: any[] = [];
  consumptionData: any[] = [];
  public selectedDateRange: { startDate: string; endDate: string } | null =
    null;

  colors = colorsList
  residentialUnits: any[] = []
  constructor(private router: Router,
    private store: Store, private loaderService: LoaderService, private sharedService: SharedService, private dashboardService: DashboardService, private meterervice: MeterService) {
    const today = new Date();
    const sixMonthsAgo = new Date();
    // sixMonthsAgo.setMonth(today.getMonth() - 6);
    const twoMonthsago = new Date();
    twoMonthsago.setMonth(today.getMonth() - 2);
    const currentUserString = localStorage.getItem('currentUser')
    this.currentUserData = JSON.parse(currentUserString ?? '');
    this.billingType = this.currentUserData?.project?.billingType;
    this.consumerId = this.currentUserData?.currentUserRoleDetails?.userId;
    this.projectId = this.currentUserData?.project?.id;
    this.isWalletEnabled = this.currentUserData?.project?.isWalletEnabled;
    this.projectUtlityList = this.currentUserData?.projectUtilities;
    this.residentialUnits = this.currentUserData?.residentialUnits;
    //console.log(this.residentialUnits)
    this.selectedFlatno = this.residentialUnits.length > 0 ? this.residentialUnits[0].residentialUnitId : []
    // console.log(this.isWalletEnabled)
    //console.log(this.projectId)

    this.getStoreInfo();

    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
        minDate: twoMonthsago, // Set minimum date to 6 months ago
        maxDate: today
      }
    );
  }
  currentUser: any;
  isMetermenu: boolean = false;
  selectedUtilityName: any;

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project.id;
    this.isMetermenu = this.currentUser?.project.meterMenu;
    this.consuptionSelectedUtility = this.projectUtlityList[0].id;
    this.selectedUtilityName = this.projectUtlityList[0];
    this.selectedRange = 'Current Month';

    this.getFaltNo();
    this.getonsumerdashboardData()

    // this.store.dispatch(getConumerPostpaidDashboard({ consumerdata: request }));


    // this.store.dispatch(getConumerConnectionsData({ consumerdata: request }));
  }

  refreshBashboard() {
    this.consuptionSelectedUtility = this.projectUtlityList[0].id;
    this.selectedUtilityName = this.projectUtlityList[0];
    this.selectedRange = 'Current Month';
    this.getFaltNo();
    this.getonsumerdashboardData();
    this.selectionChange('Current Month');
  }



  meterConnectionLoding: boolean = true
  getFaltNo() {
    this.meterConnectionLoding = true
    //  console.log(this.selectedFlatno);
    this.loaderService.setLoading(true)
    let request = {
      "projectId": this.projectId,
      "consumerId": this.consumerId,
      "residentialUinitID": this.selectedFlatno

    }
    this.dashboardService.getconsumerConnectionsData(request).subscribe({
      next: (value) => {
        this.electricityConnections = [];
        this.consumerUtilityConnectionData = value.data;
        if (this.consumerUtilityConnectionData.length > 0) {

          const updatedUtilities = this.moveElectricityToFront(value.data);
          //  console.log(updatedUtilities)
          // console.log(this.consumerUtilityConnectionData[0].utilityMasterName)
          this.onUtilityClick(updatedUtilities[0].utilityMasterName);
          console.log(this.electricityConnections)
          if (this.electricityConnections.length > 0) {
            this.meterConnectionLoding = false
          }
        } else {
          this.electricityConnections = [];
          this.electricityConnections = [];
          this.meterConnectionLoding = false
        }
        // this.consumerUtilityConnectionData = this.moveElectricityToFront(response.data);

      }, error: (err) => {
        this.meterConnectionLoding = false
        this.loaderService.setLoading(false);
      },
    })
  }

  getonsumerdashboardData() {
    let request = {
      "projectId": this.projectId,
      "consumerId": this.consumerId,
    }
    // this.store.pipe(select(getConumerPostpaidResponse)).subscribe({
    this.dashboardService.getconsumerpostpaidDashboard(request).subscribe({
      next: (response) => {
        // console.log(response)
        if (response) {
          this.consumergraphdata = response?.data;
          this.lastPaidBill = response.data.lastPaidBill;
          this.totalUnpaidBillsAmount = response.data.totalUnpaidBillsAmount
          this.dueDate = this.consumergraphdata.dueDate
          ///  console.log(this.lastPaidBill)
          this.lastPaidBillDate = this.lastPaidBill != null ? this.formatDate1(this.lastPaidBill?.createdOn.trim()) : '';
          setTimeout(() => {
            this.last5recharges(response.data.last5PaidRecharges);
            this.lest5billingcycles(response.data.last5PaidBills);
            this.last5Deductions(response.data.last5Deductions);
          }, 500);

        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.error('Error fetching consumer dashboard data', err);
      }
    });
  }

  getIcon(consumptionPercentage: number): string {
    return consumptionPercentage < 0
      ? '<i class="fas fa-arrow-down text-success "></i>'
      : '<i class="fas fa-arrow-up text-danger"></i>';
  }

  getAbsoluteConsumption(consumption: number): number {
    return Math.abs(consumption);
  }
  initChart(): void {
    const startDate = this.selectedDateRange?.startDate ? new Date(this.selectedDateRange.startDate) : null;
    const dateDiff = startDate && this.selectedDateRange?.endDate ?
      new Date(this.selectedDateRange.endDate).getTime() - startDate.getTime() : 0;
    // console.log(dateDiff)
    // console.log(startDate)
    // console.log(this.selectedDateRange?.endDate)
    this.yaxisUnits = this.selectedUtilityName.name == "Gas" ? 'Units (m³)' : this.selectedUtilityName.name == "Electricity" ? 'Units (kWh)' : 'Units (Liters)';

    // Choose the date format based on the date difference (2 months in milliseconds)
    const dateFormat = dateDiff > 2 * 24 * 60 * 60 * 1000 ? 'dd MMM' : 'dd MMM HH:mm';
    console.log(dateFormat)
    this.chartOptions = {
      series: [
        {
          name: "", // Optional: Set a name for the series if needed
          data: this.convertedData, // Ensure this is valid data
        },
      ],
      chart: {
        type: "area",
        height: 250,
        // redrawOnParentResize: true,
        toolbar: {
          show: false, // Hide the toolbar/menu options
        },
        zoom: {
          enabled: false, // Disable zoom
        },
        events: {
          mounted: (chart: any) => {
            chart.windowResizeHandler();
          },
          dataPointMouseEnter: (event, chartContext, config) => {
            chartContext.chart.showTooltip(config.dataPointIndex);

          },
          click: (event, chartContext, config) => {
            const dataPointIndex = config.dataPointIndex; // Get the data point index from config
            if (dataPointIndex !== undefined) {
              const selectedSeries = config.seriesIndex;
              // const value = this.chartOptions.series[selectedSeries].data[dataPointIndex];
              // const dataPointData = this.consuptiongraphdata[dataPointIndex]; // Get the actual data for the clicked point
              //this.handleDataPointClick(selectedSeries, dataPointIndex);
            }
          },
        },
      },

      dataLabels: {
        enabled: false, // Disable data labels
      },
      markers: {
        size: 0, // Hide markers
      },
      xaxis: {
        type: "datetime",
        /*  min: startDate ? startDate.getTime() : undefined, // Set to undefined if startDate is not valid
         tickAmount: 3, */
        min: startDate ? startDate.getTime() : undefined, // Set the min to startDate
        max: this.selectedDateRange?.endDate ? new Date(this.selectedDateRange.endDate).getTime() : undefined, // Set the max to endDate
        tickAmount: 3,
        title: {
          text: "Dates", // Label for the x-axis
        },
        labels: {
          format: dateFormat, // Let ApexCharts handle formatting
        }
      },
      yaxis: {
        title: {
          text: `${this.yaxisUnits}`, // Label for the y-axis
        },
        /*  labels: {
           formatter: function (value) {
             return Math.round(value).toString(); // Remove decimals from y-axis labels
           },
         }, */
        // min: 0, // Set a minimum value for the Y-Axis (you can adjust based on your data)
        //  tickAmount: 5,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy", // Display date format in tooltip
        },
        y: {
          formatter: function (value) {
            return `${value}`; // Display units in the tooltip
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    };

  }



  handleDataPointClick(seriesIndex: number, dataPointIndex: number): void {
    // console.log('Clicked Series Index:', seriesIndex);
    //onsole.log('Clicked Data Point Index:', dataPointIndex);

    // Check if series and data exist
    if (
      this.chartOptions.series &&
      this.chartOptions.series[seriesIndex] &&
      this.chartOptions.series[seriesIndex].data &&
      this.chartOptions.series[seriesIndex].data[dataPointIndex] !== undefined
    ) {
      // Get the selected data point
      const selectedData = this.chartOptions.series[seriesIndex].data[dataPointIndex];

      console.log('Selected Data Point:', selectedData);
      let request = {
        value: selectedData,
        UtilityType: this.selectedUtilityName?.name,
        flag: true,
        type: 'Consumption'
        // date: this.selectedDateRange
      }

      this.router.navigate(['/reports'], {
        state: { consumptionItem: request },
      });


      // this.router.navigate(['/maintenance'], {
      //   state: { consumptionItem: request },
      // });
      // Ensure selectedData is valid

    } else {
      console.log('Data point not found in series.');
    }
  }

  moveElectricityToFront(utilities: any) {
    const electricityIndex = utilities.findIndex((utility: any) => utility.utilityMasterName === "Electricity");
    if (electricityIndex > -1) {
      const electricity = utilities.splice(electricityIndex, 1)[0]; // Remove it from its current position
      utilities.unshift(electricity); // Add it to the front
    }
    return utilities;
  }

  dueDate: any
  totalUnpaidBillsAmount: number = 0
  getStoreInfo() {
    this.selectionChange('Current Month');
    /* this.consumernDashboardData$ = this.store.pipe(select(getConumerPostpaidResponse)).subscribe({
      next: (response) => {
        
        if (response) {
          this.consumergraphdata = response?.data;
          this.lastPaidBill = response.data.lastPaidBill;
          this.totalUnpaidBillsAmount = response.data.totalUnpaidBillsAmount
          this.dueDate = this.consumergraphdata.dueDate
          ///  console.log(this.lastPaidBill)
          this.lastPaidBillDate = this.lastPaidBill != null ? this.formatDate1(this.lastPaidBill?.createdOn.trim()) : '';
          setTimeout(() => {
            this.last5recharges(response.data.last5PaidRecharges);
            this.lest5billingcycles(response.data.last5PaidBills);
            this.last5Deductions(response.data.last5Deductions);
          }, 500);

        }
      },
      error: (err) => {
        console.error('Error fetching consumer dashboard data', err);
      }
    }); */



    /*  this.updateMeterStatusResponse$ = this.store.pipe(select(updateMeterStatusResponse)).subscribe({
       next: (response) => {
         if (response) {
           let request = {
             "projectId": this.projectId,
             "consumerId": this.consumerId
           }
           this.store.dispatch(getConumerConnectionsData({ consumerdata: request }));
         }
 
       }
     }) */

  }
  utility: any
  onUtilityClick(utilityType: string) {
    this.utility = utilityType
    //this.electricityConnections = [];
    let electricityConnections: any = [];
    this.electricityConnections = []
    console.log(utilityType)
    var filteredData = this.consumerUtilityConnectionData.find((item: any) => item.utilityMasterName == utilityType);
    console.log(filteredData)
    if (filteredData) {
      filteredData.units.forEach((unit: any) => {

        const [startDateStr, endDateStr] = unit.dateRange.split(' - ');
        const startDate = this.formatDate1(startDateStr.trim());
        const endDate = this.formatDate1(endDateStr.trim());

        this.electricityConnections.push({ ...unit, startDateFormatted: startDate, endDateFormatted: endDate })

        //console.log(ItemfilteredData)
        // electricityConnections.push(ItemfilteredData);
        console.log(electricityConnections)
      });

      this.loaderService.setLoading(false)
    } else {
      electricityConnections = [];
    }
    if (this.electricityConnections.length > 0) {
      this.meterConnectionLoding = false
    }
    console.log(this.electricityConnections)
    return electricityConnections


  }


  formatDate1(isoString: any) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(isoString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    // Return formatted date with time
    return `${day} ${month} ${year} ${hours}:${minutes}`;
  }

  ngOnDestroy() {
    this.getConumerConnectionsDataResponse$.unsubscribe();
    this.consumernConsumptionDashboardData$.unsubscribe();
    this.consumernDashboardData$.unsubscribe();
    this.updateMeterStatusResponse$.unsubscribe();
    ; // Or set it to [] if you prefer an empty array
  }
  lest5billingcycles(last5BillingCycles: any) {
    this.last5bills = []

    this.last5bills = last5BillingCycles.map((item: any, index: number) => {
      const formattedDate = this.formatDate(item.createdOn);
      return {

        name: `Series ${index + 1}`,
        data: [
          { category: formattedDate, value: item.totalAmount, colorString: this.colors[index] }
        ]
      };
    });
    this.sortDynamicSeriesData(this.last5bills)
  }



  last5Deductions(last5Deduction: any) {
    this.last5deductionsPayments = []
    this.last5deductionsPayments = last5Deduction.map((item: any, index: number) => {
      const formattedDate = this.formatDate(item.createdOn);

      return {

        name: `Series ${index + 1}`,
        data: [
          { category: formattedDate, value: item.billAmount, colorString: this.colors[index] }
        ]
      };
    });

    this.sortDynamicSeriesData(this.last5deductionsPayments)
    console.log(this.last5deductionsPayments)
  }
  sortDynamicSeriesData(value: any) {
    //console.log('Input value:', value); // Debugging line

    if (!Array.isArray(value)) {
      throw new Error('Expected an array as input');
    }

    value.sort((a: { data: { category: string; }[]; }, b: { data: { category: string; }[]; }) => {
      const dateA = this.parseCategory(a.data[0].category);
      const dateB = this.parseCategory(b.data[0].category);
      return dateA.getTime() - dateB.getTime();
    });
  }

  parseCategory(category: string): Date {
    const [day, month] = category.split(' ');
    const monthIndex = new Date(Date.parse(month + " 1, 2021")).getMonth(); // Convert month name to month index
    const year = new Date().getFullYear(); // Use the current year for sorting
    return new Date(year, monthIndex, parseInt(day, 10));
  }

  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }
  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }

  clearDate() {
    this.filterProjectBillDates = null; // Clear the selected date
    // this.filterProjectBillDates = null; // Clear the selected date
    this.selectionChange('Current Month');
    this.selectedRange = 'Current Month'
  }
  filterProjectBillDates: any

  onRechargesClick(event: any): void {
    // this.router.navigate(['/rechargeHistory'])
    let request = {
      type: 'Recharge',
    }
    this.router.navigate(['/rechargeHistory'], {
      state: { Recharge: request },
    });
    //console.log('Clicked on series:', event.series);
    const seriesName = event.series.name;
    const seriesData = event.series.data;

    // console.log(`Clicked on series: ${seriesName}`, seriesData);

  }
  onDeductionsClick(type: any) {
    let request = {
      type: type,
      selectedIndex: 1
    }
    localStorage.setItem('selectedTabIndex', '1')

    this.sharedService.setMeterViewDetails(request)
    this.router.navigate(['/walletHistory'])
    localStorage.setItem('dashboard', JSON.stringify(request))
  }
  onBillsClick(type: any) {
    let request = {
      type: type,
    }
    this.router.navigate(['/postpaidBill'])
  }


  onWalletClick() {
    this.router.navigate(['/consumerWallet'])
  }


  last5recharges(last5PaidRecharges: any) {
    this.last5Recharges = []
    this.transformData(last5PaidRecharges);

  }

  transformData(last5PaidRecharges: any) {
    this.last5Recharges = last5PaidRecharges.map((item: any, index: number) => {
      const formattedDate = this.formatDate(item.createdOn);
      // console.log(item)
      return {

        name: `Series ${index + 1}`,
        data: [
          { category: formattedDate, value: item.rechargeAmount, colorString: this.colors[index] }
        ]
      };
    });
    //{ category: formattedDate, value: `₹ ${item.rechargeAmount}`, colorString: this.colors[index] }
    this.sortDynamicSeriesData(this.last5Recharges)
    // console.log(this.last5Recharges)
  }

  getColorForBar(seriesIndex: number, dataIndex: number): string {
    const series = this.last5Recharges[seriesIndex];
    return series.colors[dataIndex] || '#000'; // Fallback to black if color is not found
  }

  getColor(index: number): string {
    return this.last5Recharges[0].colors[index % this.last5Recharges[0].colors.length];
  }
  formatDate(dateString: any) {
    // console.log(dateString)
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {

      console.error(`Invalid Date: ${dateString}`);
      return 'Invalid Date'; // Return a placeholder for invalid dates
    }

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' }); // Get the abbreviated month name

    return `${day} ${month}`;
  }

  getMonthlyConsuptiondata() {
    // console.log(this.selectedDateRange)
    let consumerMonthlyConsumptionData = {
      startDate: this.selectedDateRange?.startDate,
      endDate: this.selectedDateRange?.endDate,
      consumerId: this.consumerId,
      residentialUinitID: this.selectedFlatno
    }

    this.consuptionSelectedUtility = this.projectUtlityList[0].id;
    this.selectedUtilityName = this.projectUtlityList[0]

    this.loaderService.setLoading(true)
    //   console.log(this.selectedRange)
    if (this.selectedRange == 'Last Three Months' || this.selectedRange == 'Last Six Months') {
      this.ismonthlyConsumption = false
      this.dashboardService.getCconsumerMonthlyConsumptionData(consumerMonthlyConsumptionData).subscribe(({
        next: (res) => {
          //  console.log(res)
          if (res.data.length > 0) {
            this.loaderService.setLoading(true)
            this.monthlyConsumptionList = res.data
            this.monthlyConsuptionChart();
          } else {
            this.monthlyConsumptionchartData = [];
            this.loaderService.setLoading(false)
          }

        }
      }))
    } else {
      this.ismonthlyConsumption = true;
      this.dashboardService.getconsumerpostpaidConsumptionDashboard(consumerMonthlyConsumptionData).subscribe({
        next: (value) => {
          //console.log(value)
          this.consumerMonthlyConsumptionData = value?.data
          if (this.consumerMonthlyConsumptionData.length > 0) {
            this.initChart();
            this.loadConsumptionChart(this.projectUtlityList[0].name);


            // this.consumptioChartDataPrepaid(this.projectUtlityList[0].name);
          } else {
            this.consumptionData = [];
            this.consumptioncategories = [];
            this.loaderService.setLoading(false)
          }
        },
      },)
    }


  }

  monthlyConsuptionChart() {
    this.monthlyConsumptionchartData = []
    const utilityType = this.monthlyConsumptionList.find((utility: any) => utility.utilityMasterName === this.selectedUtilityName.name);

    //console.log(utilityType)
    if (Object.keys(utilityType).length > 0) {
      // Iterate over the monthlyConsumptions array of the item
      utilityType.monthlyConsumptions.forEach((res: any, index: number) => {
        this.monthlyConsumptionchartData.push({
          category: res.monthName,
          value: res.totalConsumptionForUtility,
          colorString: this.colors[index]
        });
      });
    } else {
      this.monthlyConsumptionchartData = [];
      this.loaderService.setLoading(false)
    }
    if (this.monthlyConsumptionchartData.length > 0) {
      this.loaderService.setLoading(false)
    }
    console.log("Final data", this.monthlyConsumptionchartData)
  }


  public labelContent(e: SeriesLabelsContentArgs): string {
    return e.value;
  }
  sumOfTotalConsumption: number = 0;
  convertedData: any[] = [];
  yaxisUnits: string = 'Units';
  loadConsumptionChart(utilityType: string) {

    // console.log(this.consumerMonthlyConsumptionData);
    this.consumptionData = [];
    this.sumOfTotalConsumption = 0
    const utilityData = this.consumerMonthlyConsumptionData.find((utility: any) => utility.utilityMasterName === utilityType);
    console.log(utilityData)
    if (utilityData) {


      const consumptionData = utilityData.totalConsumptionByDay;
      this.sumOfTotalConsumption = utilityData.sumOfTotalConsumption

      for (const date in consumptionData) {
        if (consumptionData.hasOwnProperty(date)) {
          const value = consumptionData[date];
          this.consumptionData.push({ category: date, value: value });
          // console.log(`category: "${date}", value: ${value}`);
        }
      }

      const convertedData = this.consumptionData.map(item => [
        new Date(item.category).getTime(),
        item.value
      ]);
      if (this.chartOptions.series && this.chartOptions.series[0].data) {
        this.chartOptions.series[0].data = convertedData;
        this.convertedData = convertedData;

        /* this.convertedData.forEach((item: any) => {
          this.sumOfTotalConsumption += item[1]
        }) */
        this.initChart();
      }

      //this.updateOptions(this.selectedRange)
      this.chart?.updateOptions(this.chartOptions, false, true, true); // Refresh chart
      // console.log("loader stop")
      this.loaderService.setLoading(false)
    } else {
      this.loaderService.setLoading(false)
      //  console.log(`Utility type "${utilityType}" is not recognized.`);
    }
  }



  public updateOptions(option: keyof UpdateOptionsData): void {
    // console.log(option)
    // console.log(this.selectedDateRange)
    const optionData = this.updateOptionsData[option as keyof typeof this.updateOptionsData];
    // console.log(optionData)
    if (optionData) {
      this.chart?.updateOptions(optionData, false, true, true);
    } else {
      console.log(`Option for '${option}' is undefined.`);
      // Handle undefined option case
    }
  }



  public categoryAxis = {
    max: new Date(2000, 1, 0),
    maxDivisions: 6,
  };
  onDateRangeSelected12(dateRange: (Date | undefined)[] | undefined): void {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0];
      const endDate = dateRange[1];
      this.selectedDateRange = {
        startDate: formatDate(
          startDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          'en-US'
        ),
        endDate: formatDate(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US'),
      };

      this.getMonthlyConsuptiondata();
    }
  }

  onDateRangeSelected(dateRange: (Date | undefined)[] | undefined): void {
    // this.flatwiseadminDashboardData$.unsubscribe();
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      console.log(dateRange)
      // const startDate = dateRange[0];
      // const endDate = dateRange[1];


      // Set start date to 00:00:00.000Z
      const startDate = new Date(dateRange[0]);
      startDate.setHours(0, 0, 0, 0);  // Setting the time to 00:00:00.000Z

      // Set end date to 23:59:59.999Z
      const endDate = new Date(dateRange[1]);
      // endDate.setUTCHours(23, 59, 59, 999);
      endDate.setHours(23, 59, 59, 999);  // Setting the time to 23:59:59.999Z
      this.selectedDateRange = {
        startDate: formatDate(
          startDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          'en-US'
        ),
        endDate: formatDate(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US'),
      };
      console.log(this.selectedDateRange)
      // this.topconsumptionData = [];
      // this.topConsumptionElectricity = [];

      this.getMonthlyConsuptiondata();
    }
  }




  public onLegendItemClick(e: LegendItemClickEvent): void {
    /* Do not hide the series on legend click */
    e.preventDefault();

    /* Hide the series manually */
    this.seriesVisible = !this.seriesVisible;
  }

  getDays(t: number) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t: number) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t: number) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t: number) {
    return Math.floor((t / 1000) % 60);
  }
  consuprtionUtilitiesChange(value: any) {
    console.log(value);
    this.consuptionSelectedUtility = value.id;
    this.selectedUtilityName = value
    this.yaxisUnits = this.selectedUtilityName == "Gas" ? 'Units m³' : this.selectedUtilityName == "Electricity" ? 'Units kWh' : 'Units L';

    this.loadConsumptionChart(value.name);

    this.monthlyConsuptionChart()
    //this.monthlyconsumptionGraph(value.name);
  }

  public updateOptionsData = {
    "Current Month": {
      xaxis: {
        /*  min: new Date("28 Jan 2013").getTime(),
         max: new Date("27 Feb 2013").getTime(), */
        min: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
        max: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime(),
      }
    },
    "Last Month": {
      xaxis: {
        min: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).getTime(),
        max: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime()
      }
    },
    "Last Three Months": {
      xaxis: {
        min: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1).getTime(),
        max: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime(),
      }
    },
    "Last Six Months": {
      xaxis: {
        min: new Date(new Date().getFullYear(), new Date().getMonth() - 5, 1).getTime(),
        max: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime(),
      }
    },
  };

  flatNoChange(event: any) {
    console.log(event)
    console.log(this.selectedRange)
    this.selectedFlatno = event.residentialUnitId;
    this.getFaltNo();
    this.selectionChange(this.selectedRange)
  }


  selectionChange(event: any) {
    // console.log(event);
    //  this.topConsumptionWatercategories = [];
    this.selectedRange = event
    // this.topConsumptionElectricitycategories = [];
    // this.topConsumptionGascategories = [];
    const selectedOption = event;
    this.dateRange = false;
    const now = new Date();
    let startDate: Date;
    let endDate: Date = now;


    switch (selectedOption) {
      case 'Current Month':
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = now; // Current date of the current month

        break;
      case 'Last Month':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1); // First day of last month
        endDate = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of last month
        break;
      case 'Last Three Months':
        startDate = new Date(now.getFullYear(), now.getMonth() - 3, 1);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of the previous month
        break;
      case 'Last Six Months':
        startDate = new Date(now.getFullYear(), now.getMonth() - 6, 1);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of the previous month
        break;
      case 'Custom Range':
        this.dateRange = true;

        // Implement custom range logic here
        return;
      default:
        startDate = now;
        endDate = now;
    }
    this.selectedDateRange = {
      startDate: formatDate(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US',),
      endDate: formatDate(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US',),
    };
    // console.log(this.selectedDateRange);

    this.getMonthlyConsuptiondata();

  }




  toggleMeterStatus(event: any, dataItem: any) {
    // console.log(dataItem)
    // console.log(event)
    let request = {
      meterId: dataItem?.meterId,
      status: !event ? 'On' : 'Off',
      macAddress: dataItem.macAddress,
      utilityName: dataItem.utility,
      IsConsumer: true,
      ProjectId: this.projectId == undefined ? dataItem.projectId : this.projectId
    };


    /*  let request = {
       meterId: dataItem?.id,
       status: dataItem.meterStatus == true ? 'On' : 'Off',
       macAddress: dataItem.macAddress,
       utilityName: dataItem.utility,
   
     }; */

    // this.updateMeterStatusResponse$ = this.store.pipe(select(updateMeterStatusResponse)).subscribe({

    this.meterervice.updateMeterStatus(request).subscribe({
      next: (response) => {
        if (response) {
          let request = {
            "projectId": this.projectId,
            "consumerId": this.consumerId
          }
          this.getFaltNo();
          //  this.store.dispatch(getConumerConnectionsData({ consumerdata: request }));
        }

      }
    })

    //  this.store.dispatch(updateMeterStatus({ request: request }));
  }

}

