<div class="container-fluid">

    <!--  <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-0 font-size-18">Welcome to Ripple Dashboard!</h4>
            </div>
        </div>
    </div> -->
    <div class="row mb-15">
        <div class="col-lg-10">
            <h4 class="mb-0 font-size-18">Welcome To Ripple Dashboard!</h4>

        </div>
        <!-- <div class="col-lg-2">
            <button class="btn btn-primary fr" (click)="refreshDashboard()" kendoTooltip
                title="Clicking the 'Refresh' button will update the dashboard with the most recent information"
                [position]="'left'">
                <span><i class="fas fa-redo "></i> </span> &nbsp;&nbsp;Refresh
            </button>
        </div> -->
    </div>

    <div class="d-flex justify-content-end mb-2">
        <div class="col-xl-3" style="width: 23.5%;">
            <!-- <ng-select class="ng-select-shadow" [items]="balanceOptions" [(ngModel)]="selectedBalance"  [multiple]="false" [deselectOnClick]="false" bindValue="value" bindLabel="label"
                class="ml-2">
            </ng-select> -->

            <ng-select [items]="balanceOptions" [(ngModel)]="selectedBalance" [clearable]="false" bindValue="value"
                bindLabel="label" [multiple]="false"></ng-select>
        </div>
    </div>

    <div class="row">


        <div class="col-lg-3">
            <div class="position-relative d-flex justify-content-center">
                <img src="../../../assets/images/brands/rupeemore.png" class="dashboard-image">
            </div>
            <div class="card mini-stats-wid mini-stats-wid-dashboard mini-stats-wid-border" [style.height.px]="150">
                <div class="px-3 py-2">
                    <div class="card-details d-flex justify-content-center align-items-center">
                        <div>
                            <p class="mb-0 mb-2 font-size-18">Wallet Balance</p>
                            <h4 class="font-weight-bold-dashboard text-truncate mb-0">

                                <!-- Dynamically display balance based on selected balance type -->
                                {{ selectedBalance === 'total'
                                ? (superAdminDashboardData?.walletData?.totalProjectWalletBalance | currency:
                                'INR':'symbol':'1.2-2')
                                : selectedBalance === 'postpaid'
                                ? (superAdminDashboardData?.walletData?.postpaidProjectBalance | currency:
                                'INR':'symbol':'1.2-2')
                                : (superAdminDashboardData?.walletData?.prepaidProjectBalance | currency:
                                'INR':'symbol':'1.2-2')
                                }}
                            </h4>
                        </div>

                    </div>

                    <div class="d-flex justify-content-end p-1">
                        <span class="text-danger font-size-12">*Across all projects</span>
                    </div>
                </div>
            </div>
        </div>




        <div class="col-lg-3">
            <div class="position-relative d-flex justify-content-center">
                <img src="../../../assets/images/brands/apartmentNew.png" class="dashboard-image">
            </div>
            <div class="card blog-stats-wid mini-stats-wid-dashboard mini-stats-wid-border" [style.height.px]="150">
                <div class=" px-3 py-2">
                    <div class="card-details text-center">
                        <div>
                            <p class="mb-0 mb-2 font-size-18">Total Projects</p>
                            <h4 class="font-weight-bold-dashboard mb-4">
                                {{ selectedBalance === 'total' ? superAdminDashboardData?.projectData?.totalCount :
                                (selectedBalance === 'postpaid' ?
                                superAdminDashboardData?.projectData?.totalPostpaidCount
                                : superAdminDashboardData?.projectData?.totalPrepaidCount) }}
                            </h4>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="position-relative d-flex justify-content-center">
                <img src="../../../assets/images/brands/auction.png" class="dashboard-image">
            </div>
            <div class="card blog-stats-wid mini-stats-wid-dashboard mini-stats-wid-border" [style.height.px]="150">
                <div class=" px-3 py-2">
                    <div class="card-details text-center">
                        <p class="mb-0 mb-2 font-size-18">Active Projects</p>
                        <h4 class="font-weight-bold-dashboard  mb-4">
                            {{ selectedBalance === 'total' ?superAdminDashboardData?.projectData?.activeCount :
                            (selectedBalance === 'postpaid' ?
                            superAdminDashboardData?.projectData?.postPaidProjectActiveCount :
                            superAdminDashboardData?.projectData?.prepaidProjectActiveCount) }}
                        </h4>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="position-relative d-flex justify-content-center">
                <img src="../../../assets/images/brands/residence.png" class="dashboard-image">
            </div>
            <div class="card blog-stats-wid mini-stats-wid-border mini-stats-wid-dashboard" [style.height.px]="150">
                <div class="px-3 py-2">
                    <div class="card-details text-center">
                        <p class="mb-0 mb-2 font-size-18">Inactive Projects</p>
                        <h4 class="font-weight-bold-dashboard mb-4">
                            {{ selectedBalance === 'total' ? superAdminDashboardData?.projectData?.inactiveCount :
                            (selectedBalance === 'postpaid' ?
                            superAdminDashboardData?.projectData?.postPaidProjectInactiveCount :
                            superAdminDashboardData?.projectData?.prepaidProjectInActiveCount) }}
                        </h4>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end mb-2">
        <!-- <div class="col-xl-3">
            <ng-select [items]="projectsList" placeholder="Select Project" bindLabel="projectName"
                (change)="onChangeProject($event)" bindValue="projectId" id="billingType" [(ngModel)]="selectedProject"
                [multiple]="true" [deselectOnClick]="true"></ng-select>
        </div> -->
        <div class="col-xl-3" style="width: 23.5%;">
            <ng-select class="ng-select-shadow" [items]="projectsList" placeholder="All Projects" bindLabel="name"
                bindValue="id" [(ngModel)]="selectedProject" (change)="onChangeProject($event)" [multiple]="true"
                [deselectOnClick]="true">

                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <span class="ng-value-label">{{ item?.name }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>

                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                        <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                    </div>
                    <div class="ng-value" *ngIf="items.length > 2">
                        <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                    </div>
                </ng-template>

            </ng-select>
        </div>





    </div>

    <div class="row">
        <!-- <div class="col-xl-3">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-3">Communication Status</h4>
                    <div *ngIf="commStatusTotalCount>0">
                        <p class="text-primary font-size-14">Total Meters : {{commStatusTotalCount}}</p>
                        <kendo-chart [legend]="legend" (legendItemClick)="onLegendItemClick($event)"
                            style="height: 250px;">
                            <kendo-chart-series>
                                <kendo-chart-series-item *ngFor="let series of communicationStatusGraphData"
                                    [data]="series.data" [color]="series.color" [visible]="seriesVisible" [spacing]="1"
                                    [labels]="{ visible: false, content: labelContent }">
                                </kendo-chart-series-item>
                            </kendo-chart-series>
                            <kendo-chart-tooltip format="">
                                <ng-template kendoChartTooltipTemplate let-tooltip="tooltip">
                                    <div>
                                        <strong>{{ tooltip.series.name }}</strong>
                                        Month: {{ tooltip.category }}<br />
                                        Value: ₹{{ tooltip.value }}
                                    </div>
                                </ng-template>
                            </kendo-chart-tooltip>
                        </kendo-chart>

                        <div class="">
                            <div *ngFor="let series of communicationStatusGraphData">
                                <span class=" "><i class="bx bxs-circle" [ngStyle]="{'color': series.color}"></i><span
                                        class="font-size-12 font-weight-bolder"> {{series?.name}} </span></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="commStatusTotalCount==0" class="text-center superadminGraph">
                        <p>No data available</p>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="col-xl-3">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-3">Communication Status</h4>

                    <!-- Wrap the text and dropdown in a flex container -->
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <p class="text-primary font-size-14 mb-0">Total Meters: {{commStatusTotalCount}}</p>
                        <!-- <select [(ngModel)]="selectedUtilityCommunication" (ngModelChange)="communicationStatusData()">
                            <option [value]="'All'">All</option>
                            <option *ngFor="let utility of configData?.utilities; trackBy: trackByUtilityId"
                                [value]="utility.id">
                                {{ utility.name }}
                            </option>
                        </select> -->

                        <ng-select [items]="configData?.utilities" [clearable]="false"
                            [(ngModel)]="selectedUtilityCommunication" placeholder="Select Utility"
                            (change)="communicationStatusData($event)" id="billingType" [multiple]=" false"
                            [deselectOnClick]="false" [clearable]="false" bindValue="id" bindLabel="name"
                            style="width: 100px;"></ng-select>


                    </div>
                    <div *ngIf="commStatusTotalCount > 0">

                        <kendo-chart [style.height.px]="chartHeight">
                            <kendo-chart-series>
                                <kendo-chart-value-axis>
                                    <kendo-chart-value-axis-item [title]="{ text: '' }">
                                        <!-- Y-axis title set to 'Units' -->
                                    </kendo-chart-value-axis-item>
                                </kendo-chart-value-axis>

                                <kendo-chart-series-item [data]="communicationStatusGraphData" field="value"
                                    categoryField="category" [colorField]="'colorString'"
                                    [labels]="{ visible: true, content: labelContent }" [visible]="seriesVisible"
                                    [tooltip]="{ visible: true }" [spacing]="1">
                                </kendo-chart-series-item>
                            </kendo-chart-series>

                            <kendo-chart-category-axis>
                                <kendo-chart-category-axis-item [labels]="{ visible: false }">
                                    <!-- This hides the bottom labels -->
                                </kendo-chart-category-axis-item>
                            </kendo-chart-category-axis>

                            <kendo-chart-legend position="bottom"></kendo-chart-legend>
                        </kendo-chart>
                        <div>
                            <div *ngFor="let series of communicationStatusGraphData">
                                <span class=""><i class="bx bxs-circle" [ngStyle]="{'color': series.colorString}"></i>
                                    <span class="font-size-12 font-weight-bolder"> {{series?.category}} </span>
                                    <span class="fa fa-info-circle cursor-pointer" kendoTooltip
                                        [attr.title]="series.comment" [position]="'right'"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="communicationStatusGraphDataLoding"
                        class="d-flex align-items-center justify-content-center text-center superadminGraph">
                        <p>Loading...</p>
                    </div>
                    <div *ngIf="communicationStatusGraphNoData"
                        class="d-flex align-items-center justify-content-center text-center meterConnection">
                        <p>No data available</p>
                    </div>

                </div>
            </div>
        </div>



        <!-- 
          <kendo-chart>
            <kendo-chart-title text="Communication Status"></kendo-chart-title>
            <kendo-chart-category-axis>
              <kendo-chart-category-axis-item [categories]="['Total Meters']">
              </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            
            <kendo-chart-series>
              <kendo-chart-series-item
                *ngFor="let item of communicationStatusGraphData"
                [data]="item.data"
                [name]="item.name"
                [color]="item.color"
                [stack]="{ group: item.stack }"
                type="column"> 
              </kendo-chart-series-item>
            </kendo-chart-series>
          
            <kendo-chart-legend position="bottom" orientation="horizontal"></kendo-chart-legend>
          </kendo-chart> -->

        <div class="col-xl-3">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-3">Gateway Information</h4>
                    <div *ngIf="gatewayStatusTotalCount>0">
                        <p class="text-primary font-size-14">Total Gateways : {{gatewayStatusTotalCount}}</p>
                        <kendo-chart style="height: 250px;">
                            <kendo-chart-series>
                                <kendo-chart-series-item type="pie" [data]="GatewayInfoGraphData">
                                    <kendo-chart-series-item-labels [content]="labelContent" [visible]="false"
                                        position="center" background="transparent">
                                    </kendo-chart-series-item-labels>
                                </kendo-chart-series-item>
                            </kendo-chart-series>

                            <kendo-chart-tooltip>
                                <ng-template kendoChartTooltipTemplate let-point="point">
                                    {{ point.category }}: {{ point.value }} ({{ point.percentage }}%)
                                </ng-template>
                            </kendo-chart-tooltip>
                        </kendo-chart>

                        <div class="">
                            <div *ngFor="let series of GatewayInfoGraphData">
                                <span class=""><i class="bx bxs-circle" [ngStyle]="{'color': series.color}"></i><span
                                        class="font-size-12 font-weight-bolder ms-1"> {{series?.name}}
                                    </span></span>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="gatewayStatusLoading"
                        class="d-flex align-items-center justify-content-center text-center superadminGraph">
                        <p>Loading......</p>
                    </div>
                    <div *ngIf="gatewayStatusNoDate"
                        class=" d-flex align-items-center justify-content-center text-center superadminGraph">
                        <p>No data available</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-3">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-3">Residential Units </h4>
                    <div *ngIf="totalResidentialUnits>0">
                        <p class="text-primary font-size-14">Total Units : {{totalResidentialUnits}}</p>

                        <kendo-chart style="height: 250px;">
                            <kendo-chart-series>
                                <kendo-chart-series-item type="donut" [data]="residentialUnitsData" field="value"
                                    categoryField="category">
                                    <kendo-chart-series-item-labels [visible]="false" position="center"
                                        [content]="labelContent" background="transparent">
                                    </kendo-chart-series-item-labels>
                                </kendo-chart-series-item>
                            </kendo-chart-series>

                            <!-- Optional Tooltip Configuration -->
                            <kendo-chart-tooltip>
                                <ng-template kendoChartTooltipTemplate let-point="point">
                                    {{ point.category }}: {{ point.value }} ({{ point.percentage }}%)
                                </ng-template>
                            </kendo-chart-tooltip>
                        </kendo-chart>

                        <div class="">
                            <div *ngFor="let series of residentialUnitsData">
                                <span class=""><i class="bx bxs-circle" [ngStyle]="{'color': series.color}"></i><span
                                        class="font-size-12 font-weight-bolder ms-1"> {{series?.name}}
                                    </span></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="ResidentialUnitDataLoading"
                        class="text-center superadminGraph d-flex align-items-center justify-content-center">
                        <p>Loading...</p>
                    </div>
                    <div *ngIf="residenatilaNodata"
                        class="text-center superadminGraph d-flex align-items-center justify-content-center">
                        <p>No data available</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-3">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-3">Consumers</h4>
                    <div *ngIf="totalConsumers>0">
                        <p class="text-primary font-size-14">Total Consumers : {{totalConsumers}}</p>


                        <kendo-chart style="height: 250px;">
                            <kendo-chart-series>
                                <kendo-chart-series-item type="donut" [startAngle]="180" [data]="consumerData"
                                    field="value" categoryField="category">
                                    <!-- Labels displayed inside the donut chart -->
                                    <kendo-chart-series-item-labels [content]="labelContent" [visible]="false"
                                        position="center" background="transparent">
                                    </kendo-chart-series-item-labels>
                                </kendo-chart-series-item>
                            </kendo-chart-series>

                            <!-- Tooltip Configuration -->
                            <kendo-chart-tooltip>
                                <ng-template kendoChartTooltipTemplate let-point="point">
                                    {{ point.category }}: {{ point.value }} ({{ point.percentage }}%)
                                </ng-template>
                            </kendo-chart-tooltip>
                        </kendo-chart>

                        <div class="">
                            <div *ngFor="let series of consumerData">
                                <span class=""><i class="bx bxs-circle" [ngStyle]="{'color': series.color}"></i><span
                                        class="font-size-12 font-weight-bolder ms-1"> {{series?.name}}

                                    </span></span>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="ConsumersLoading"
                        class="text-center superadminGraph d-flex align-items-center justify-content-center">
                        <p>loadong...</p>
                    </div>
                    <div *ngIf="ConsumersNodata"
                        class="text-center superadminGraph d-flex align-items-center justify-content-center">
                        <p>No data available</p>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                    <!-- <h4 class="card-title mb-3">Revenue for last 6 months</h4> -->
                    <!-- <div class="col-xl-3" style="width: 23.5%;">
                        <ng-select [items]="projectsList" placeholder="All Projects" bindLabel="projectName"
                            bindValue="projectId" [(ngModel)]="selectedProject" (change)="onChangeProject($event)"
                            [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span class="ng-value-label">{{ item?.projectName }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                    <span class="ng-value-label" *ngIf="item?.projectName">{{ item?.projectName
                                        }}</span>
                                </div>
                                <div class="ng-value" *ngIf="items.length > 2">
                                    <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                                </div>
                            </ng-template>

                        </ng-select>
                    </div> -->
                    <!-- <p class="mb-0">Revenue Chart</p> -->
                    <div class="d-flex row justify-content-between align-items-center mb-3">
                        <div class="col-xl-5">
                            <h4 class="card-title mb-0">Revenue for last 6 months</h4>
                        </div>
                        <div class="d-flex justify-content-end col-xl-7">
                            <ng-select [items]="projectsList" placeholder="All Projects" bindLabel="name" bindValue="id"
                                [(ngModel)]="selectedRevenuWiseProject" (change)="revenuDataOnProject()"
                                [multiple]="true" [deselectOnClick]="true">

                                <!-- Custom label for selected items -->
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{ item?.name }}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                </ng-template>

                                <!-- Custom label for multiple selected items -->
                                <!-- <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                        <span class="ng-value-label" *ngIf="item?.name">{{ item[0]?.name }}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </div>
                                    <div class="ng-value" *ngIf="items.length > 1">
                                        <span class="ng-value-label">+ {{ items.length - 1 }} more</span>
                                    </div>
                                </ng-template>
 -->
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value">
                                        <!-- Show only the first selected item -->
                                        <span class="ng-value-label">{{ items[0]?.name }}</span>
                                        <span class="ng-value-icon right" (click)="clear(items[0])"
                                            aria-hidden="true">×</span>
                                    </div>

                                    <!-- Show "+ more" if more than 1 item is selected -->
                                    <div class="ng-value" *ngIf="items.length > 1">
                                        <span class="ng-value-label">+ {{ items.length - 1 }} more</span>
                                    </div>
                                </ng-template>
                                <!-- Custom dropdown options with clear icon for selected items -->
                                <ng-template ng-option-tmp let-item="item">
                                    <div class="ng-option-item">
                                        <span class="ng-option-label">{{ item?.name }}</span>

                                        <!-- Display clear icon if the item is selected -->
                                        <span class="ng-clear-icon" *ngIf="isSelected(item)" (click)="clearOption(item)"
                                            aria-hidden="true">×</span>
                                    </div>
                                </ng-template>

                            </ng-select>
                        </div>


                    </div>


                    <div *ngIf="totalRevenueAmount>0">
                        <h4 class="text-primary"> Total Revenue: {{totalRevenueAmount | currency:
                            'INR':'symbol':'1.2-2'}}</h4>
                        <kendo-chart style="height: 250px;">
                            <kendo-chart-series>
                                <kendo-chart-series-item [data]="revenueData" field="value" categoryField="category"
                                    [labels]="{ visible: true, content: labelContent }" [colorField]="'colorString'"
                                    [visible]="seriesVisible" [spacing]="1"
                                    [labels]="{ visible: true, content: labelContent }" [tooltip]="{ visible: true }">
                                </kendo-chart-series-item>
                            </kendo-chart-series>
                            <kendo-chart-legend position="bottom"></kendo-chart-legend>

                            <kendo-chart-tooltip format="₹{0}">
                                <ng-template kendoChartTooltipTemplate let-tooltip="tooltip">
                                    <div>
                                        <strong>{{ tooltip.series.name }}</strong>
                                        Month: {{ tooltip.category }}
                                        Value: ₹{{ tooltip.value | number:'1.2-2' }}
                                    </div>
                                </ng-template>
                            </kendo-chart-tooltip>
                        </kendo-chart>
                    </div>
                    <div *ngIf="totalRevenueAmount==0"
                        class="text-center Revenuecard d-flex align-items-center justify-content-center">
                        <p>No data available</p>
                    </div>

                </div>
            </div>
        </div>


        <div class="col-xl-6">
            <div class="card">
                <div class="card-body" style="height: 375px;">
                    <div class="d-flex row justify-content-between align-items-center mb-3">
                        <div class="col-xl-4">
                            <h4 class="card-title mb-0">Top 5 projects</h4>
                        </div>
                        <div class="col-xl-8 d-flex justify-content-end">
                            <!-- <div class="col-xl-6 me-2 input-container" *ngIf="dateRange">
                                <input *ngIf="dateRange" type="text" class="form-control date" placeholder="Select Date"
                                    name="daterange" autocomplete="off" bsDaterangepicker [bsConfig]="datePickerConfig"
                                    (bsValueChange)="onDateRangeSelected($event)" />

                            </div> -->


                            <div class="d-flex justify-content-between w-200">
                                <div class="position-relative flex-grow-1 me-2" *ngIf="dateRange" style="width: 222px;">
                                    <input type="text" class="form-control date"
                                        [value]="filterProjectBillDates ? (formatDateRange(filterProjectBillDates)) : ''"
                                        placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                                        [bsConfig]="datePickerConfig" [(bsValue)]="filterProjectBillDates"
                                        (bsValueChange)="onDateRangeSelected($event)" />
                                    <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
                                    <span class="bx bx-x inputIcon clear-icon" *ngIf="filterProjectBillDates"
                                        (click)="clearDate()"></span>
                                </div>

                                <div class="ng-select-container ms-auto">
                                    <ng-select [items]="months" [clearable]="false" [deselectOnClick]="false"
                                        placeholder="Select Month" (change)="selectionChange($event)" id="billingType"
                                        [(ngModel)]="selectedRange" [multiple]="false"></ng-select>
                                </div>
                            </div>
                            <!-- <div class="position-relative col-xl-12 me-2 input-container" *ngIf="dateRange"> 
                                <input *ngIf="dateRange" type="text" class="form-control date" placeholder="Select Date" [(ngModel)]="filterProjectBillDates"
                                    name="daterange" autocomplete="off" bsDaterangepicker [bsConfig]="datePickerConfig"
                                    (bsValueChange)="onDateRangeSelected($event)" />
                                <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
                                <span class="bx bx-x inputIcon clear-icon" *ngIf="filterProjectBillDates" (click)="clearDate()"></span>
                            </div> -->

                            <!-- [deselectOnClick]="false" -->
                        </div>
                        <!-- <div class="d-flex row justify-content-between align-items-center mb-3" *ngIf="dateRange">
                            <div class="col-xl-12">

                                <div class="position-relative">
                                    <input type="text" class="form-control date"
                                        [value]="filterProjectBillDates ? (formatDateRange(filterProjectBillDates)) : ''"
                                        placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                                        [bsConfig]="datePickerConfig" [(bsValue)]="filterProjectBillDates"  (bsValueChange)="onDateRangeSelected($event)" />
                                    <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
                                    <span class="bx bx-x inputIcon clear-icon" *ngIf="filterProjectBillDates"
                                        (click)="clearDate()"></span>
                                </div>

                                
                              <div class="position-relative calenderinsuperadmindash">
                                <input type="text" class="form-control date" placeholder="Select Date" 
                                       [(ngModel)]="filterProjectBillDates" name="daterange" autocomplete="off" 
                                       bsDaterangepicker [bsConfig]="datePickerConfig"
                                       (bsValueChange)="onDateRangeSelected($event)" />
                                <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
                                <span class="bx bx-x inputIcon clear-icon" *ngIf="filterProjectBillDates" 
                                      (click)="clearDate()"></span>
                              </div>
                          </div>
                    </div> -->

                        <div class="table-responsive1">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div class="d-flex align-items-center">
                                                <img src="../../../assets/images/brands/apartment.png"
                                                    class="project-image">
                                                Project Name
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div class="d-flex align-items-center">
                                                <img src="../../../assets/images/brands/rupees.png"
                                                    class="project-image">
                                                Revenue
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div class="d-flex align-items-center">
                                                <img src="../../../assets/images/brands/rupees.png"
                                                    class="project-image">
                                                Consumers Revenue
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div class="d-flex align-items-center">
                                                <img src="../../../assets/images/brands/usernew.png"
                                                    class="project-image">
                                                Admin Amount
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div class="d-flex align-items-center">
                                                <img src="../../../assets/images/brands/usernew.png"
                                                    class="project-image">
                                                Users Count
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let project of superAdminDashboardRevenueDataBasedonDate; let i = index">
                                        <td>{{ project.projectName }}</td>
                                        <td class="font-weight-bolder">
                                            {{ project.totalRechargeAmount | currency: 'INR' }}
                                        </td>
                                        <td class="font-weight-bolder">
                                            {{ project.consumerRechargeAmount | currency: 'INR' }}
                                        </td>
                                        <td class="font-weight-bolder">
                                            {{ project.projectAdminRechargeAmount | currency: 'INR' }}
                                        </td>
                                        <td>{{ project.userCounts }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>