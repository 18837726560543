import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpUtilitiesComponent } from './rp-utilities/rp-utilities.component';
import { RpUnitRateHistoryComponent } from './rp-unit_rate_history/rp-unit_rate_history.component';
import { RpHistotyUnitRateComponent } from './rp-histoty-unit-rate/rp-histoty-unit-rate.component';

const routes: Routes = [
  { path: 'utilities', component: RpUtilitiesComponent },
  { path: 'unitratehistory', component: RpUnitRateHistoryComponent },
  { path: 'RPunitratehistory', component: RpHistotyUnitRateComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UtilitiesRoutingModule { }
