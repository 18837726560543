<app-rp-loader></app-rp-loader>
<div class="modal-content custom-modal">
  <!-- Modal Header -->
  <div class="modal-header bg-primary text-white">
    <h5 class="modal-title">Support</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closePopUp()"></button>
  </div>

  <!-- Modal Body -->
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <!-- Society Information -->
        <div class="col-md-6">
          <div class="card mb-3">
            <div class="card-header bg-info text-white fw-bold">Society Information</div>
            <div class="card-body bg-primary text-white">
              
              <!-- Phone -->
              <p class="d-flex align-items-center" *ngIf="userData?.projectContact; else noContact" style="cursor: pointer;" (click)="openPhone(userData?.projectContact)">
                <i class="mdi mdi-phone mdi-24px me-2"></i> {{ userData?.projectContact }}
              </p>
              <ng-template #noContact>
                <p class="d-flex align-items-center text-white">
                  <i class="mdi mdi-phone mdi-24px me-2"></i> Not Available
                </p>
              </ng-template>
        
              <!-- WhatsApp -->
              <p class="d-flex align-items-center" *ngIf="userData?.projectContact; else noWhatsApp" style="cursor: pointer;" (click)="openWhatsApp(userData?.projectContact)">
                <i class="mdi mdi-whatsapp mdi-24px me-2"></i> {{ userData?.projectContact }}
              </p>
              <ng-template #noWhatsApp>
                <p class="d-flex align-items-center text-white">
                  <i class="mdi mdi-whatsapp mdi-24px me-2"></i> Not Available
                </p>
              </ng-template>
        
              <!-- Email -->
              <p class="d-flex align-items-center" *ngIf="userData?.projectEmail; else noEmail" style="cursor: pointer;" (click)="openEmail(userData?.projectEmail)">
                <i class="mdi mdi-email mdi-24px me-2"></i> {{ userData?.projectEmail }}
              </p>
              <ng-template #noEmail>
                <p class="d-flex align-items-center text-white">
                  <i class="mdi mdi-email mdi-24px me-2"></i> Not Available
                </p>
              </ng-template>
        
            </div>
          </div>
        </div>
        
        

        <!-- Ripple Contact Information -->
        <div class="col-md-6">
          <div class="card mb-3">
            <div class="card-header bg-secondary text-white fw-bold">Ripple Contact Information</div>
            <div class="card-body bg-dark text-white">
              <p class="d-flex align-items-center" style="cursor: pointer;" (click)="openPhone('9866667906')">
                <i class="mdi mdi-phone mdi-24px me-2"></i> 9866667906
              </p>
              <p class="d-flex align-items-center" style="cursor: pointer;" (click)="openWhatsApp('9866667906')">
                <i class="mdi mdi-whatsapp mdi-24px me-2"></i> 9866667906
              </p>
              <p class="d-flex align-items-center" style="cursor: pointer;" (click)="openEmail('support@ripplemetering.com')">
                <i class="mdi mdi-email mdi-24px me-2"></i> support&#64;ripplemetering.com
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Privacy Policy & Terms -->
      <div class="row text-center mt-4">
        <!-- Privacy Policy -->
        <div class="col-md-6">
          <p class="d-flex align-items-center justify-content-center" style="cursor: pointer;" (click)="openInNewTab('https://ripplemetering.com/privacy-statement/')">
            <i class="mdi mdi-shield-lock mdi-24px me-2"></i> Privacy Policy
          </p>
        </div>

        <!-- Terms & Conditions -->
        <div class="col-md-6">
          <p class="d-flex align-items-center justify-content-center" style="cursor: pointer;" (click)="openInNewTab('https://ripplemetering.com/terms-of-service')">
            <i class="mdi mdi-file-document-outline mdi-24px me-2"></i> Terms & Conditions
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer"></div>
</div>
