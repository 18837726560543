<!-- <app-unit-rate-history></app-unit-rate-history> -->



<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="">
                    <ng-stepper #cdkStepper [linear]="true" class="wizard" (selectionChange)="onStepChange($event)">
                        <cdk-step [optional]="true" *ngFor="let utility of currentprojectUtilities;let i=index">
                            <ng-template cdkStepLabel>
                                <span class="number">{{i+1}}</span>
                                <span>{{utility.name}}</span>
                            </ng-template>
                            <app-unit-rate-history *ngIf="selectedUtility === utility.name"
                                [unitRateGridData]="gasDataList" [totalCount]="totalGasCount" [pageSize]="gasPageSize"
                                [selectedUtility]="selectedUtility" [gridColumns]="gridColumns"></app-unit-rate-history>
                        </cdk-step>
                    </ng-stepper>
                </div>
            </div>
        </div>
    </div>